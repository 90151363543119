"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.setUser = exports.logout = exports.isLoggedIn = exports.getUser = exports.getPreviewID = exports.deleteUser = void 0;
var _router = require("@reach/router");
var _getStorageKey = _interopRequireDefault(require("./getStorageKey"));
var _getParameter = _interopRequireDefault(require("./getParameter"));
const storageKey = (0, _getStorageKey.default)();
const isBrowser = typeof window !== "undefined";
const deleteUser = () => {
  if (isBrowser && storageKey) {
    localStorage.removeItem(storageKey);
  }
};
exports.deleteUser = deleteUser;
const setUser = user => {
  if (isBrowser && storageKey) {
    localStorage.setItem(storageKey, JSON.stringify(user));
  }
};
exports.setUser = setUser;
const getUser = () => {
  if (isBrowser && storageKey) {
    const user = localStorage.getItem(storageKey);
    return user ? JSON.parse(user) : {};
  }
  return {};
};
exports.getUser = getUser;
const isLoggedIn = () => {
  if (isBrowser) {
    const user = getUser();
    if (user) {
      return !!user.authToken;
    }
  }
  return false;
};
exports.isLoggedIn = isLoggedIn;
const getPreviewID = () => {
  if (isBrowser) {
    const preview = (0, _getParameter.default)('preview');
    if (preview) {
      return preview;
    }
  }
  return '';
};
exports.getPreviewID = getPreviewID;
const logout = onLogout => {
  if (!isBrowser) {
    return;
  }
  deleteUser();

  // Reload page
  typeof onLogout === 'function' ? onLogout() : typeof window !== 'undefined' && (0, _router.navigate)(window.location.pathname);
};
exports.logout = logout;