"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
Object.defineProperty(exports, "RootState", {
  enumerable: true,
  get: function get() {
    return _store.RootState;
  }
});
exports.siteActions = exports.previewActions = exports.postActions = exports.mediaActions = exports.languageActions = exports.cmsActions = exports.authActions = void 0;
Object.defineProperty(exports, "store", {
  enumerable: true,
  get: function get() {
    return _store.store;
  }
});
exports.uiActions = exports.termActions = void 0;
Object.defineProperty(exports, "useAppDispatch", {
  enumerable: true,
  get: function get() {
    return _store.useAppDispatch;
  }
});
Object.defineProperty(exports, "useAppSelector", {
  enumerable: true,
  get: function get() {
    return _store.useAppSelector;
  }
});
exports.userActions = void 0;
var _store = require("./store");
var _cmsSlice = require("./slices/cmsSlice");
var _uiSlice = require("./slices/uiSlice");
var authActions = _interopRequireWildcard(require("./actions/authActions"));
exports.authActions = authActions;
var siteActions = _interopRequireWildcard(require("./actions/siteActions"));
exports.siteActions = siteActions;
var postActions = _interopRequireWildcard(require("./actions/postActions"));
exports.postActions = postActions;
var termActions = _interopRequireWildcard(require("./actions/termActions"));
exports.termActions = termActions;
var mediaActions = _interopRequireWildcard(require("./actions/mediaActions"));
exports.mediaActions = mediaActions;
var previewActions = _interopRequireWildcard(require("./actions/previewActions"));
exports.previewActions = previewActions;
var userActions = _interopRequireWildcard(require("./actions/userActions"));
exports.userActions = userActions;
var languageActions = _interopRequireWildcard(require("./actions/languageActions"));
exports.languageActions = languageActions;
function _getRequireWildcardCache(nodeInterop) { if (typeof WeakMap !== "function") return null; var cacheBabelInterop = new WeakMap(); var cacheNodeInterop = new WeakMap(); return (_getRequireWildcardCache = function _getRequireWildcardCache(nodeInterop) { return nodeInterop ? cacheNodeInterop : cacheBabelInterop; })(nodeInterop); }
function _interopRequireWildcard(obj, nodeInterop) { if (!nodeInterop && obj && obj.__esModule) { return obj; } if (obj === null || typeof obj !== "object" && typeof obj !== "function") { return { default: obj }; } var cache = _getRequireWildcardCache(nodeInterop); if (cache && cache.has(obj)) { return cache.get(obj); } var newObj = {}; var hasPropertyDescriptor = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var key in obj) { if (key !== "default" && Object.prototype.hasOwnProperty.call(obj, key)) { var desc = hasPropertyDescriptor ? Object.getOwnPropertyDescriptor(obj, key) : null; if (desc && (desc.get || desc.set)) { Object.defineProperty(newObj, key, desc); } else { newObj[key] = obj[key]; } } } newObj.default = obj; if (cache) { cache.set(obj, newObj); } return newObj; }
const cmsActions = {
  setConfig: _cmsSlice.setConfig,
  setDeploymentImage: _cmsSlice.setDeploymentImage,
  setActiveLanguage: _cmsSlice.setActiveLanguage,
  addEditorSite: _cmsSlice.addEditorSite,
  updateEditorSite: _cmsSlice.updateEditorSite,
  addEditorPost: _cmsSlice.addEditorPost,
  updateEditorPost: _cmsSlice.updateEditorPost,
  clearEditor: _cmsSlice.clearEditor
};
exports.cmsActions = cmsActions;
const uiActions = {
  showDialog: _uiSlice.showDialog,
  hideDialog: _uiSlice.hideDialog,
  updateEditorSettings: _uiSlice.updateEditorSettings
};
exports.uiActions = uiActions;