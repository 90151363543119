import React from "react"
import { Box, Container, Stack, Typography, Link } from "@mui/material"
import { Button, Textarea } from "@parallel-domain/pd-theme"
import { Link as GatsbyLink } from "gatsby"
import parse from "html-react-parser"

const Single = props => {
  const {
    text,
    categories,
    description,
    lists,
    additional,
    salaryRange,
    applyUrl,
  } = props

  const formatCurrency = amount => {
    const currency = new Intl.NumberFormat("en-US", {
      style: "currency",
      currency: salaryRange.currency,
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    })

    return currency.format(amount)
  }

  return (
    <Container maxWidth="md" sx={{ py: 6 }}>
      <Stack spacing={4}>
        <Box>
          <Typography variant="h2" component="h1" gutterBottom>
            {parse(text)}
          </Typography>

          <Stack direction={{ xs: "column", md: "row" }} spacing={1}>
            <Typography variant="subtitle1">
              {parse(categories.location)}
            </Typography>

            <Typography
              variant="subtitle1"
              sx={{ display: { xs: "none", md: "block" } }}
            >
              |
            </Typography>

            <Typography variant="subtitle1">
              {parse(categories.team)}
            </Typography>

            {categories.commitment && (
              <>
                <Typography
                  variant="subtitle1"
                  sx={{ display: { xs: "none", md: "block" } }}
                >
                  |
                </Typography>

                <Typography variant="subtitle1">
                  {parse(categories.commitment)}
                </Typography>
              </>
            )}
          </Stack>
        </Box>

        <Textarea
          linkComponent={GatsbyLink}
          // Override inline font styles
          sx={{ "*": { fontSize: "1rem !important" } }}
        >
          {description}
        </Textarea>

        {salaryRange?.min && salaryRange?.max && (
          <Box>
            <Typography mb={3}>
              The base pay range for this role is between{" "}
              {formatCurrency(salaryRange.min)} and{" "}
              {formatCurrency(salaryRange.max)}, and your base pay will depend
              on your skills, qualifications and experience, and location.
            </Typography>
          </Box>
        )}

        {lists &&
          lists.map((o, i) => {
            return (
              <Box key={i}>
                <Typography variant="h3" gutterBottom>
                  {o.text}
                </Typography>
                <ul>
                  <Textarea linkComponent={GatsbyLink}>{o.content}</Textarea>
                </ul>
              </Box>
            )
          })}

        {additional && (
          <Textarea linkComponent={GatsbyLink}>
            {additional.replace(/<div><br><\/div>/g, "")}
          </Textarea>
        )}

        <Link
          href={applyUrl}
          target="_blank"
          rel="noopener noreferrer"
          underline="none"
        >
          <Button variant="outlined" color="primary">
            Apply Now
          </Button>
        </Link>
      </Stack>
    </Container>
  )
}

export default Single
