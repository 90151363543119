import headlineConfig from "../../../submodules/components/headline/config"
import videoConfig from "../../../submodules/components/video/config"
import flexWrapperConfig from "../../../submodules/components/flexWrapper/config"

const config = {
  id: "textvideo",
  label: "Text & Video",
  fields: [
    {
      id: "icon",
      type: "image",
      label: "Icon",
    },
    ...headlineConfig,
    {
      id: "text",
      type: "text",
      label: "Text",
    },
    ...videoConfig,
    ...flexWrapperConfig,
  ],
}

export default config
