import React from "react"
import { Box, Container } from "@mui/material"

// import app components
import { Image } from "../../../submodules/components/image"

const ImageCloud = props => {
  const { images } = props

  return (
    <Box sx={{ py: [3, 4, 5] }}>
      <Container maxWidth="lg">
        <Box
          sx={{
            display: { xs: "flex", md: "grid" },
            flexWrap: "wrap",
            gridTemplateColumns: { md: "1.2fr 0.4fr 1.4fr" },
            gridTemplateRows: {
              xs: "250px 250px 250px",
              md: "250px 200px 200px",
              xl: "350px 200px 200px",
            },
            gap: { xs: "30px 0", md: "30px" },
            gridTemplateAreas: {
              xs: `"el0"\n    "el1"\n    "el4"`,
              md: `"el0 el0 el1"\n      "el2 el4 el4"\n      "el3 el4 el4"`,
            },
            ".el1": {
              "> div": { width: { xs: "100%", md: "350px", xl: "400px" } },
            },
            ".el2": {
              justifyContent: "flex-end",
              "> div": { width: { xs: "100%", md: "300px" } },
            },
            ".el3": {
              justifyContent: "flex-end",
              "> div": { width: { xs: "100%", md: "200px" } },
            },
          }}
        >
          {images &&
            images.map((image, i: number) => {
              return (
                <Box
                  key={i}
                  className={`el${i}`}
                  sx={{
                    gridArea: `el${i}`,
                    display: { xs: i > 2 ? "none" : "flex", md: "flex" },
                  }}
                >
                  <Box
                    sx={{
                      position: "relative",
                      zIndex: 2,
                      height: "100%",
                      borderRadius: "20px",
                      overflow: "hidden",
                      "> span, .gatsby-image-wrapper": {
                        width: "100%",
                        height: "100%",
                      },
                    }}
                  >
                    {image && <Image {...image} />}
                  </Box>
                </Box>
              )
            })}
        </Box>
      </Container>
    </Box>
  )
}

export default ImageCloud
