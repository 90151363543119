const config = {
  id: "default",
  label: "Download",
  fields: [
    {
      id: "slug",
      type: "text",
      label: "Dataset",
    },
    {
      id: "email",
      type: "text",
      label: "Email",
    },
    {
      id: "firstname",
      type: "text",
      label: "First Name",
    },
    {
      id: "lastname",
      type: "text",
      label: "Last Name",
    },
    {
      id: "company",
      type: "text",
      label: "Company",
    },
    {
      id: "date",
      type: "date_picker",
      label: "Date",
    },
  ],
}

export default config
