"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const typography = {
    MuiTypography: {
        styleOverrides: {
            p: 0,
            gutterBottom: "20px",
        },
    },
};
exports.default = typography;
