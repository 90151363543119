"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = formatFields;
var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));
function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }
function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { (0, _defineProperty2.default)(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }
// Format the fields object passed in via props to a more readable format
// For this we're transforming the arrays for post types and templates to objects with id keys
function formatFields(fields) {
  var _fields$postTypes;
  const formattedFields = {
    postTypes: {},
    taxonomies: fields === null || fields === void 0 ? void 0 : fields.taxonomies,
    themeOptions: fields === null || fields === void 0 ? void 0 : fields.themeOptions
  };
  fields === null || fields === void 0 ? void 0 : (_fields$postTypes = fields.postTypes) === null || _fields$postTypes === void 0 ? void 0 : _fields$postTypes.map(o => {
    var _o$templates;
    const templates = {};
    o === null || o === void 0 ? void 0 : (_o$templates = o.templates) === null || _o$templates === void 0 ? void 0 : _o$templates.map(p => templates[p.id] = p);
    formattedFields.postTypes[o.id] = _objectSpread(_objectSpread({}, o), {}, {
      templates
    });
  });
  return formattedFields;
}