"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = getStorageKey;
function getStorageKey() {
  if (typeof window !== 'undefined') {
    const storageKey = "jam-cms-".concat(window.location.hostname);
    return storageKey;
  }
  return '';
}