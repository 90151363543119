exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-data-visualizer-tsx": () => import("./../../../src/pages/data-visualizer.tsx" /* webpackChunkName: "component---src-pages-data-visualizer-tsx" */),
  "component---src-pages-jam-cms-tsx": () => import("./../../../src/pages/jam-cms/[...].tsx" /* webpackChunkName: "component---src-pages-jam-cms-tsx" */),
  "component---src-templates-post-types-opendataset-archive-archive-tsx": () => import("./../../../src/templates/postTypes/opendataset/archive/archive.tsx" /* webpackChunkName: "component---src-templates-post-types-opendataset-archive-archive-tsx" */),
  "component---src-templates-post-types-opendataset-default-default-tsx": () => import("./../../../src/templates/postTypes/opendataset/default/default.tsx" /* webpackChunkName: "component---src-templates-post-types-opendataset-default-default-tsx" */),
  "component---src-templates-post-types-page-default-default-tsx": () => import("./../../../src/templates/postTypes/page/default/default.tsx" /* webpackChunkName: "component---src-templates-post-types-page-default-default-tsx" */),
  "component---src-templates-post-types-page-job-job-tsx": () => import("./../../../src/templates/postTypes/page/job/job.tsx" /* webpackChunkName: "component---src-templates-post-types-page-job-job-tsx" */),
  "component---src-templates-post-types-page-jobs-jobs-tsx": () => import("./../../../src/templates/postTypes/page/jobs/jobs.tsx" /* webpackChunkName: "component---src-templates-post-types-page-jobs-jobs-tsx" */),
  "component---src-templates-post-types-post-archive-archive-tsx": () => import("./../../../src/templates/postTypes/post/archive/archive.tsx" /* webpackChunkName: "component---src-templates-post-types-post-archive-archive-tsx" */),
  "component---src-templates-post-types-post-default-default-tsx": () => import("./../../../src/templates/postTypes/post/default/default.tsx" /* webpackChunkName: "component---src-templates-post-types-post-default-default-tsx" */)
}

