"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _react = require("react");
var _auth = require("../utils/auth");
var _redux = require("../redux");
// import components

const useAuth = () => {
  const {
    auth: {
      userLoaded
    }
  } = (0, _redux.useAppSelector)(state => state);
  const dispatch = (0, _redux.useAppDispatch)();
  const [timer, setTimer] = (0, _react.useState)(0);
  const user = (0, _auth.getUser)();
  (0, _react.useEffect)(() => {
    let intervalID = null;
    if (user !== null && user !== void 0 && user.authToken) {
      intervalID = setInterval(() => {
        setTimer(time => time + 1);
      }, 60000); // 60 seconds
    }

    // Clear timer
    return () => {
      intervalID && clearInterval(intervalID);
    };
  }, []);
  (0, _react.useEffect)(() => {
    (user === null || user === void 0 ? void 0 : user.authToken) && userLoaded && dispatch(_redux.authActions.refreshToken());
  }, [timer]);
  (0, _react.useEffect)(() => {
    (user === null || user === void 0 ? void 0 : user.authToken) && !userLoaded && dispatch(_redux.authActions.getAuthUser());
  }, []);
};
var _default = useAuth;
exports.default = _default;