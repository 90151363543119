"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.breakpoints = exports.bp = exports.SIDEBAR_WIDTH = exports.NAV_MOBILE_HEIGHT = exports.NAV_WIDTH = exports.HEADER_HEIGHT = void 0;
const styles_1 = require("@mui/material/styles");
exports.HEADER_HEIGHT = 64;
exports.NAV_WIDTH = 64;
exports.NAV_MOBILE_HEIGHT = 64;
exports.SIDEBAR_WIDTH = 300;
// Break points
exports.bp = {
    values: {
        xs: 0,
        sm: 600,
        md: 900,
        lg: 1200,
        xl: 1920,
    },
};
exports.breakpoints = (0, styles_1.createTheme)({ breakpoints: exports.bp }).breakpoints;
