import imageConfig from "../../../submodules/components/image/config"
import flexWrapperConfig from "../../../submodules/components/flexWrapper/config"

const config = {
  id: "imagecloud",
  label: "Image Cloud",
  fields: [
    {
      id: "images",
      type: "repeater",
      label: "Images",
      items: imageConfig[0].fields,
    },
    ...flexWrapperConfig,
  ],
}

export default config
