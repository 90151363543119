"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = generateSlug;
var _formatSlug = _interopRequireDefault(require("./formatSlug"));
var _getParentSlug = _interopRequireDefault(require("./getParentSlug"));
const generatePostSlug = _ref => {
  var _site$postTypes, _site$postTypes$postT, _site$postTypes$postT2, _site$languages, _site$languages2, _site$languages4;
  let {
    site,
    postTypeID,
    postID,
    leadingSlash,
    trailingSlash
  } = _ref;
  if (postID === (site === null || site === void 0 ? void 0 : site.frontPage)) {
    return leadingSlash ? '/' : '';
  }
  if (!(site !== null && site !== void 0 && (_site$postTypes = site.postTypes) !== null && _site$postTypes !== void 0 && (_site$postTypes$postT = _site$postTypes[postTypeID]) !== null && _site$postTypes$postT !== void 0 && (_site$postTypes$postT2 = _site$postTypes$postT.posts) !== null && _site$postTypes$postT2 !== void 0 && _site$postTypes$postT2[postID])) {
    return '';
  }
  const {
    postTypes: {
      [postTypeID]: {
        slug: postTypeSlug,
        posts,
        posts: {
          [postID]: {
            slug: postSlug,
            parentID,
            language,
            translations
          }
        }
      }
    }
  } = site;
  const parentSlug = (0, _getParentSlug.default)(posts, parentID);

  // Get language slug if applicable
  let languageSlug = '';
  if (language && site !== null && site !== void 0 && (_site$languages = site.languages) !== null && _site$languages !== void 0 && _site$languages.defaultLanguage && site !== null && site !== void 0 && (_site$languages2 = site.languages) !== null && _site$languages2 !== void 0 && _site$languages2.postTypes.find(s => s === postTypeID) && site.languages.defaultLanguage !== language) {
    var _site$languages3, _site$languages3$lang;
    languageSlug = site === null || site === void 0 ? void 0 : (_site$languages3 = site.languages) === null || _site$languages3 === void 0 ? void 0 : (_site$languages3$lang = _site$languages3.languages.find(o => o.slug === language)) === null || _site$languages3$lang === void 0 ? void 0 : _site$languages3$lang.slug;
  }

  // Check if the translation of the default language equals the front page id
  // If this is the case the post is a front page as well (i.e. "/en")
  if ((translations === null || translations === void 0 ? void 0 : translations[site === null || site === void 0 ? void 0 : (_site$languages4 = site.languages) === null || _site$languages4 === void 0 ? void 0 : _site$languages4.defaultLanguage]) === (site === null || site === void 0 ? void 0 : site.frontPage)) {
    return (0, _formatSlug.default)(languageSlug, leadingSlash, trailingSlash);
  }
  return (0, _formatSlug.default)("".concat(languageSlug, "/").concat(postTypeSlug, "/").concat(parentSlug, "/").concat(postSlug), leadingSlash, trailingSlash);
};
const generateTermSlug = _ref2 => {
  var _site$languages5, _site$languages6;
  let {
    site,
    taxonomyID,
    termID,
    leadingSlash,
    trailingSlash
  } = _ref2;
  const {
    taxonomies: {
      [taxonomyID]: {
        slug: taxonomySlug,
        terms,
        terms: {
          [termID]: {
            slug: termSlug,
            parentID,
            language
          }
        }
      }
    }
  } = site;
  const parentSlug = (0, _getParentSlug.default)(terms, parentID);

  // Get language slug if applicable
  let languageSlug = '';
  if (language && site !== null && site !== void 0 && (_site$languages5 = site.languages) !== null && _site$languages5 !== void 0 && _site$languages5.defaultLanguage && site !== null && site !== void 0 && (_site$languages6 = site.languages) !== null && _site$languages6 !== void 0 && _site$languages6.taxonomies.find(s => s === taxonomyID) && site.languages.defaultLanguage !== language) {
    var _site$languages7, _site$languages7$lang;
    languageSlug = site === null || site === void 0 ? void 0 : (_site$languages7 = site.languages) === null || _site$languages7 === void 0 ? void 0 : (_site$languages7$lang = _site$languages7.languages.find(o => o.slug === language)) === null || _site$languages7$lang === void 0 ? void 0 : _site$languages7$lang.slug;
  }
  return (0, _formatSlug.default)("".concat(languageSlug, "/").concat(taxonomySlug, "/").concat(parentSlug, "/").concat(termSlug), leadingSlash, trailingSlash);
};
function generateSlug(_ref3) {
  let {
    site,
    postTypeID,
    postID,
    taxonomyID,
    termID,
    leadingSlash = false,
    trailingSlash = false
  } = _ref3;
  if (postTypeID && postID) {
    return generatePostSlug({
      site,
      postTypeID,
      postID,
      leadingSlash,
      trailingSlash
    });
  } else if (taxonomyID && termID) {
    return generateTermSlug({
      site,
      taxonomyID,
      termID,
      leadingSlash,
      trailingSlash
    });
  }
  return '';
}