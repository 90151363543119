const config = {
  id: "socials",
  label: "Socials",
  type: "group",
  fields: [
    {
      id: "items",
      type: "repeater",
      label: "Items",
      items: [
        {
          id: "icon",
          type: "image",
          label: "Icon",
        },
        {
          id: "url",
          type: "text",
          label: "URL",
        },
      ],
    },
  ],
}

export default config
