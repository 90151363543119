"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.updateEditorSite = exports.updateEditorPost = exports.setDeploymentImage = exports.setConfig = exports.setActiveLanguage = exports.default = exports.cmsSlice = exports.clearEditor = exports.addEditorSite = exports.addEditorPost = void 0;
var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));
var _toolkit = require("@reduxjs/toolkit");
var _lodash = require("lodash");
var _immer = _interopRequireDefault(require("immer"));
var _router = require("@reach/router");
var siteActions = _interopRequireWildcard(require("../actions/siteActions"));
var postActions = _interopRequireWildcard(require("../actions/postActions"));
var termActions = _interopRequireWildcard(require("../actions/termActions"));
var previewActions = _interopRequireWildcard(require("../actions/previewActions"));
var languageActions = _interopRequireWildcard(require("../actions/languageActions"));
var userActions = _interopRequireWildcard(require("../actions/userActions"));
var mediaActions = _interopRequireWildcard(require("../actions/mediaActions"));
var _utils = require("../../utils");
function _getRequireWildcardCache(nodeInterop) { if (typeof WeakMap !== "function") return null; var cacheBabelInterop = new WeakMap(); var cacheNodeInterop = new WeakMap(); return (_getRequireWildcardCache = function _getRequireWildcardCache(nodeInterop) { return nodeInterop ? cacheNodeInterop : cacheBabelInterop; })(nodeInterop); }
function _interopRequireWildcard(obj, nodeInterop) { if (!nodeInterop && obj && obj.__esModule) { return obj; } if (obj === null || typeof obj !== "object" && typeof obj !== "function") { return { default: obj }; } var cache = _getRequireWildcardCache(nodeInterop); if (cache && cache.has(obj)) { return cache.get(obj); } var newObj = {}; var hasPropertyDescriptor = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var key in obj) { if (key !== "default" && Object.prototype.hasOwnProperty.call(obj, key)) { var desc = hasPropertyDescriptor ? Object.getOwnPropertyDescriptor(obj, key) : null; if (desc && (desc.get || desc.set)) { Object.defineProperty(newObj, key, desc); } else { newObj[key] = obj[key]; } } } newObj.default = obj; if (cache) { cache.set(obj, newObj); } return newObj; }
function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }
function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { (0, _defineProperty2.default)(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; } // import app components
const initialState = {
  loading: [],
  config: null,
  site: null,
  siteLoaded: false,
  siteLastFetch: 0,
  unpublishedChanges: null,
  activeLanguage: "",
  languages: [],
  deploymentImage: "",
  editor: {
    site: null,
    siteHasChanged: false,
    post: null,
    postHasChanged: false,
    changeIndex: 0
  },
  users: {
    items: [],
    page: 0
  },
  media: {
    items: [],
    page: 0,
    search: ""
  }
};
const cmsSlice = (0, _toolkit.createSlice)({
  name: "cms",
  initialState,
  reducers: {
    setConfig: (state, action) => {
      state.config = action.payload;
    },
    setDeploymentImage: (state, action) => {
      state.deploymentImage = action.payload;
    },
    setActiveLanguage: (state, action) => {
      state.activeLanguage = action.payload;
    },
    addEditorSite: (state, action) => {
      state.editor.site = action.payload;
      state.editor.siteHasChanged = false;
      state.editor.changeIndex = 0;
    },
    updateEditorSite: (state, action) => {
      state.editor.site = _objectSpread(_objectSpread({}, state.editor.site), action.payload);
      state.editor.siteHasChanged = true;
      state.editor.changeIndex += 1;
    },
    addEditorPost: (state, action) => {
      state.editor.post = action.payload;
      state.editor.postHasChanged = false;
      state.editor.changeIndex = 0;
    },
    updateEditorPost: (state, action) => {
      state.editor.post = _objectSpread(_objectSpread({}, state.editor.post), action.payload);
      state.editor.postHasChanged = true;
      state.editor.changeIndex += 1;
    },
    clearEditor: state => {
      state.editor = _objectSpread({}, initialState.editor);
    }
  },
  extraReducers: builder => {
    /******************************
     * Sites
     ******************************/
    builder.addCase(siteActions.getSite.fulfilled, (state, action) => {
      if (action.payload) {
        var _action$payload, _action$payload$langu;
        state.site = action.payload;
        state.siteLoaded = true;
        state.siteLastFetch = Date.now();

        // Set active language if applicable
        if (!state.activeLanguage && (_action$payload = action.payload) !== null && _action$payload !== void 0 && (_action$payload$langu = _action$payload.languages) !== null && _action$payload$langu !== void 0 && _action$payload$langu.defaultLanguage) {
          var _action$payload2, _action$payload2$lang;
          state.activeLanguage = ((_action$payload2 = action.payload) === null || _action$payload2 === void 0 ? void 0 : (_action$payload2$lang = _action$payload2.languages) === null || _action$payload2$lang === void 0 ? void 0 : _action$payload2$lang.defaultLanguage) || "all";
        }

        // Update editor site if nothing has changed
        if (!state.editor.siteHasChanged) {
          state.editor.site = action.payload;
        }
      }
    });
    builder.addCase(siteActions.updateSite.fulfilled, (state, action) => {
      if (action.payload) {
        state.site = action.payload;
        state.editor.site = action.payload;
        state.editor.siteHasChanged = false;
      }
    });
    builder.addCase(siteActions.deploySite.fulfilled, (state, action) => {
      if (action.payload) {
        var _state$site, _state$site$deploymen;
        state.site = action.payload;
        if (state !== null && state !== void 0 && (_state$site = state.site) !== null && _state$site !== void 0 && (_state$site$deploymen = _state$site.deployment) !== null && _state$site$deploymen !== void 0 && _state$site$deploymen.badgeImage) {
          state.deploymentImage = "".concat(state.site.deployment.badgeImage, "?v=").concat(Math.floor(Math.random() * Math.floor(100)));
        }
      }
    });
    builder.addCase(siteActions.syncFields.fulfilled, (state, action) => {
      if (action.payload) {
        state.site = action.payload;
      }
    });
    builder.addCase(siteActions.getUnpublishedChanges.fulfilled, (state, action) => {
      if (action.payload) {
        state.unpublishedChanges = action.payload;
      }
    });

    /******************************
     * Posts
     ******************************/
    builder.addCase(postActions.getPost.fulfilled, (state, action) => {
      if (action.payload) {
        if (action.payload.revisionID) {
          (0, _lodash.set)(state, "editor.post", action.payload);
          (0, _lodash.set)(state, "editor.postHasChanged", true);
        } else {
          (0, _lodash.set)(state, "site.postTypes.".concat(action.payload.postTypeID, ".posts.").concat(action.payload.id), action.payload);
          (0, _lodash.set)(state, "editor.post", action.payload);
        }
      }
    });
    builder.addCase(postActions.addPost.fulfilled, (state, action) => {
      if (action.payload) {
        (0, _lodash.set)(state, "site.postTypes.".concat(action.payload.postTypeID, ".posts.").concat(action.payload.id), action.payload);
        const slug = (0, _utils.generateSlug)({
          site: state.site,
          postTypeID: action.payload.postTypeID,
          postID: action.payload.id,
          leadingSlash: true
        });
        (0, _router.navigate)(slug);
      }
    });
    builder.addCase(postActions.updatePost.fulfilled, (state, action) => {
      if (action.payload) {
        (0, _lodash.set)(state, "site.postTypes.".concat(action.payload.postTypeID, ".posts.").concat(action.payload.id), action.payload);
        state.editor.post = action.payload;
        state.editor.postHasChanged = false;

        // In case the user only updates the post, the new deployment status isn't available (only for site updates), so we need to manually update the status.
        (0, _lodash.set)(state, "site.deployment.undeployedChanges", true);
      }
    });
    builder.addCase(postActions.deletePost.fulfilled, (state, action) => {
      if (action.payload) {
        var _state$site3;
        // Get the term that needs to be deleted
        const post = (0, _lodash.get)(state, "site.postTypes.".concat(action.payload.postTypeID, ".posts.").concat(action.payload.id));

        // In case the post has translations, we wanna loop trough them and remove the reference to the post that has been deleted
        if (post !== null && post !== void 0 && post.translations) {
          Object.keys(post.translations).map(language => {
            var _state$site2;
            // This is the post id of the translated post
            const postID = post.translations[language];
            state === null || state === void 0 ? true : (_state$site2 = state.site) === null || _state$site2 === void 0 ? true : delete _state$site2.postTypes[action.payload.postTypeID].posts[postID].translations[post.language];
          });
        }

        // Finally we can remove the actual post
        state === null || state === void 0 ? true : (_state$site3 = state.site) === null || _state$site3 === void 0 ? true : delete _state$site3.postTypes[action.payload.postTypeID].posts[action.payload.id];
      }
    });
    builder.addCase(postActions.duplicatePost.fulfilled, (state, action) => {
      if (action.payload) {
        (0, _lodash.set)(state, "site.postTypes.".concat(action.payload.postTypeID, ".posts.").concat(action.payload.id), action.payload);
      }
    });
    builder.addCase(postActions.emptyTrash.fulfilled, (state, action) => {
      if (action.payload) {
        action.payload.postIDs.map(id => {
          var _state$site4, _state$site6;
          // Get the post that needs to be deleted
          const post = state === null || state === void 0 ? void 0 : (_state$site4 = state.site) === null || _state$site4 === void 0 ? void 0 : _state$site4.postTypes[action.payload.postTypeID].posts[id];

          // In case the post has translations, we wanna loop trough them and remove the reference to the post that has been deleted
          if (post !== null && post !== void 0 && post.translations) {
            Object.keys(post.translations).map(language => {
              var _state$site5;
              // This is the post id of the translated post
              const postID = post.translations[language];
              state === null || state === void 0 ? true : (_state$site5 = state.site) === null || _state$site5 === void 0 ? true : delete _state$site5.postTypes[action.payload.postTypeID].posts[postID].translations[post.language];
            });
          }

          // Finally we can remove the actual post
          state === null || state === void 0 ? true : (_state$site6 = state.site) === null || _state$site6 === void 0 ? true : delete _state$site6.postTypes[action.payload.postTypeID].posts[id];
        });
      }
    });
    builder.addCase(postActions.reorderPosts.fulfilled, (state, action) => {
      if (action.payload) {
        action.payload.map(o => {
          (0, _lodash.set)(state, "site.postTypes.".concat(o.postTypeID, ".posts.").concat(o.id, ".order"), o.order);
        });
      }
    });
    builder.addCase(postActions.refreshPostLock.fulfilled, (state, action) => {
      var _action$payload3, _action$payload3$lock;
      // If the property locked is set in the result, it means someone took over the post and we need to disable editing for the current user
      if ((_action$payload3 = action.payload) !== null && _action$payload3 !== void 0 && (_action$payload3$lock = _action$payload3.locked) !== null && _action$payload3$lock !== void 0 && _action$payload3$lock.id) {
        (0, _lodash.set)(state, "site.postTypes.".concat(action.payload.postTypeID, ".posts.").concat(action.payload.id), action.payload);
        (0, _lodash.set)(state, "editor.site.postTypes.".concat(action.payload.postTypeID, ".posts.").concat(action.payload.id), action.payload);
      }
    });
    builder.addCase(postActions.takeOverPost.fulfilled, (state, action) => {
      // If the property locked is set in the result, it means someone took over the post and we need to disable editing for the current user
      if (action.payload) {
        (0, _lodash.set)(state, "site.postTypes.".concat(action.payload.postTypeID, ".posts.").concat(action.payload.id), action.payload);
        (0, _lodash.set)(state, "editor.site.postTypes.".concat(action.payload.postTypeID, ".posts.").concat(action.payload.id), action.payload);
      }
    });
    builder.addCase(postActions.translatePost.fulfilled, (state, action) => {
      if (action.payload) {
        (0, _lodash.set)(state, "site.postTypes.".concat(action.payload.postTypeID, ".posts.").concat(action.payload.id), action.payload);

        // Update translations of post
        Object.keys(action.payload.translations).map(k => {
          (0, _lodash.set)(state, "site.postTypes.".concat(action.payload.postTypeID, ".posts.").concat(action.payload.translations[k], ".translations.").concat(action.payload.language), action.payload.id);
        });

        // Add post to post type so we can then generate the slug and the route the newly created post
        const nextSite = (0, _immer.default)(state.site, draft => {
          return (0, _lodash.set)(draft, "postTypes.".concat(action.payload.postTypeID, ".posts.").concat(action.payload.id), action.payload);
        });
        const slug = (0, _utils.generateSlug)({
          site: nextSite,
          postTypeID: action.payload.postTypeID,
          postID: action.payload.id,
          leadingSlash: true
        });
        (0, _router.navigate)(slug);
      }
    });

    /******************************
     * Terms
     ******************************/
    builder.addCase(termActions.addTerm.fulfilled, (state, action) => {
      if (action.payload) {
        (0, _lodash.set)(state, "site.taxonomies.".concat(action.payload.taxonomyID, ".terms.").concat(action.payload.id), action.payload);
      }
    });
    builder.addCase(termActions.updateTerm.fulfilled, (state, action) => {
      if (action.payload) {
        (0, _lodash.set)(state, "site.taxonomies.".concat(action.payload.taxonomyID, ".terms.").concat(action.payload.id), action.payload);
      }
    });
    builder.addCase(termActions.deleteTerm.fulfilled, (state, action) => {
      if (action.payload) {
        var _state$site8;
        // Get the term that needs to be deleted
        const term = (0, _lodash.get)(state, "site.taxonomies.".concat(action.payload.taxonomyID, ".terms.").concat(action.payload.id));

        // In case the term has translations, we wanna loop trough them and remove the reference to the term that has been deleted
        if (term !== null && term !== void 0 && term.translations) {
          Object.keys(term.translations).map(language => {
            var _state$site7;
            // This is the term id of the translated term
            const termID = term.translations[language];
            state === null || state === void 0 ? true : (_state$site7 = state.site) === null || _state$site7 === void 0 ? true : delete _state$site7.taxonomies[action.payload.taxonomyID].terms[termID].translations[term.language];
          });
        }

        // Finally we can remove the actual term
        state === null || state === void 0 ? true : (_state$site8 = state.site) === null || _state$site8 === void 0 ? true : delete _state$site8.taxonomies[action.payload.taxonomyID].terms[action.payload.id];
      }
    });
    builder.addCase(termActions.translateTerm.fulfilled, (state, action) => {
      if (action.payload) {
        (0, _lodash.set)(state, "site.taxonomies.".concat(action.payload.taxonomyID, ".terms.").concat(action.payload.id), action.payload);

        // Update translations of term
        Object.keys(action.payload.translations).map(k => {
          (0, _lodash.set)(state, "site.taxonomies.".concat(action.payload.taxonomyID, ".terms.").concat(action.payload.translations[k], ".translations.").concat(action.payload.language), action.payload.id);
        });
      }
    });

    /******************************
     * Users
     ******************************/
    builder.addCase(userActions.addUser.fulfilled, (state, action) => {
      if (action.payload) {
        state.users.items = [action.payload, ...state.users.items];
      }
    });
    builder.addCase(userActions.updateUser.fulfilled, (state, action) => {
      if (action.payload) {
        state.users.items = state.users.items.map(o => o.id === action.payload.id ? action.payload : o);
      }
    });
    builder.addCase(userActions.deleteUser.fulfilled, (state, action) => {
      if (action.payload) {
        state.users.items = state.users.items.filter(o => o.id !== action.payload.id);
      }
    });
    builder.addCase(userActions.getUsers.fulfilled, (state, action) => {
      if (action.payload) {
        state.users.items = state.users.items.concat(action.payload.items);
        state.users.page = action.payload.page;
      }
    });

    /******************************
     * Media
     ******************************/
    builder.addCase(mediaActions.addMediaItem.fulfilled, (state, action) => {
      if (action.payload) {
        state.media.items = [action.payload, ...state.media.items];
      }
    });
    builder.addCase(mediaActions.updateMediaItem.fulfilled, (state, action) => {
      if (action.payload) {
        state.media.items = state.media.items.map(o => o.id === action.payload.id ? action.payload : o);
      }
    });
    builder.addCase(mediaActions.deleteMediaItem.fulfilled, (state, action) => {
      if (action.payload) {
        state.media.items = state.media.items.filter(o => o.id !== action.payload);
      }
    });
    builder.addCase(mediaActions.getMediaItems.fulfilled, (state, action) => {
      if (action.payload) {
        state.media.items = state.media.search === action.payload.search ? (0, _lodash.unionBy)(state.media.items, action.payload.items, "id") : action.payload.items;
        state.media.page = action.payload.page;
        state.media.search = action.payload.search;
      }
    });

    /******************************
     * Previews
     ******************************/
    builder.addCase(previewActions.getSitePreview.fulfilled, (state, action) => {
      if (action.payload) {
        state.site = action.payload;
        state.editor.site = action.payload;
        state.siteLoaded = true;
      }
    });
    builder.addCase(previewActions.getPostPreview.fulfilled, (state, action) => {
      if (action.payload) {
        state.editor.post = action.payload;
      }
    });

    /******************************
     * Languages
     ******************************/
    builder.addCase(languageActions.getLanguages.fulfilled, (state, action) => {
      if (action.payload) {
        state.languages = action.payload;
      }
    });
    builder.addCase(languageActions.addLanguage.fulfilled, (state, action) => {
      if (action.payload) {
        (0, _lodash.set)(state, "site.languages", action.payload);
      }
    });
    builder.addCase(languageActions.updateLanguage.fulfilled, (state, action) => {
      if (action.payload) {
        (0, _lodash.set)(state, "site.languages", action.payload);
      }
    });
    builder.addCase(languageActions.deleteLanguage.fulfilled, (state, action) => {
      var _state$site9, _state$site9$language;
      if (action.payload && (_state$site9 = state.site) !== null && _state$site9 !== void 0 && (_state$site9$language = _state$site9.languages) !== null && _state$site9$language !== void 0 && _state$site9$language.languages) {
        var _state$site10, _state$site10$languag;
        // Get language from state
        const language = state.site.languages.languages.find(o => o.id !== action.payload);

        // Remove language
        state.site.languages.languages = state.site.languages.languages.filter(o => o.id !== action.payload);

        // Reset language if active language gets deleted
        if (state.activeLanguage === (language === null || language === void 0 ? void 0 : language.slug)) {
          state.activeLanguage = "all";
        }

        // Reset default language in case user deletes it
        if (state !== null && state !== void 0 && (_state$site10 = state.site) !== null && _state$site10 !== void 0 && (_state$site10$languag = _state$site10.languages) !== null && _state$site10$languag !== void 0 && _state$site10$languag.defaultLanguage && state.site.languages.defaultLanguage === (language === null || language === void 0 ? void 0 : language.slug)) {
          state.site.languages.defaultLanguage = "";
        }
      }
    });
    builder.addCase(languageActions.updateLanguageSettings.fulfilled, (state, action) => {
      if (state.site && action.payload) {
        state.site.languages = _objectSpread(_objectSpread({}, state.site.languages), action.payload);
      }
    });
    builder.addCase(languageActions.translateMass.fulfilled, (state, action) => {
      if (state.site && action.payload) {
        action.payload.ids.map(id => {
          if (action.payload.type === "post") {
            (0, _lodash.set)(state, "site.postTypes.".concat(action.payload.postTypeID, ".posts.").concat(id, ".language"), action.payload.language);
          } else {
            (0, _lodash.set)(state, "site.taxonomies.".concat(action.payload.taxonomyID, ".terms.").concat(id, ".language"), action.payload.language);
          }
        });
      }
    });
  }
});
exports.cmsSlice = cmsSlice;
const {
  setConfig,
  setDeploymentImage,
  setActiveLanguage,
  addEditorSite,
  updateEditorSite,
  addEditorPost,
  updateEditorPost,
  clearEditor
} = cmsSlice.actions;
exports.clearEditor = clearEditor;
exports.updateEditorPost = updateEditorPost;
exports.addEditorPost = addEditorPost;
exports.updateEditorSite = updateEditorSite;
exports.addEditorSite = addEditorSite;
exports.setActiveLanguage = setActiveLanguage;
exports.setDeploymentImage = setDeploymentImage;
exports.setConfig = setConfig;
var _default = cmsSlice.reducer;
exports.default = _default;