import React, { useState } from "react"
import axios from "axios"
import { Alert, AlertTitle, Box, Stack, Grid } from "@mui/material"
import { Button, Input } from "@parallel-domain/pd-theme"
import { useForm, Controller } from "react-hook-form"

const ContactForm = props => {
  const { buttonText } = props

  const [status, setStatus] = useState<"success" | "error" | "">("")
  const [loading, setLoading] = useState(false)

  const { handleSubmit, control } = useForm({
    defaultValues: {
      firstName: "",
      lastName: "",
      email: "",
      company: "",
      phone: "",
      message: "",
    },
  })

  const handleSubmitForm = async formData => {
    const { firstName, lastName, email, company, phone, message } = formData

    const data = {
      input_1: firstName,
      input_3: lastName,
      input_4: email,
      input_5: phone,
      input_6: company,
      input_7: message,
      input_9: "Yes",
    }

    // https://docs.gravityforms.com/rest-api-v2/#post-forms-form-id-submissions
    const formId = 1
    const endpoint =
      process.env.GATSBY_BACKEND_WEBSITE +
      "/wp-json/gf/v2/forms/" +
      formId +
      "/submissions"

    try {
      setLoading(true)

      await axios.post(endpoint, data)

      setStatus("success")
    } catch (e) {
      setStatus("error")
    } finally {
      setLoading(false)
    }
  }

  return (
    <Box sx={{ display: "flex", alignItems: "center" }}>
      {status === "success" ? (
        <Alert severity="success" sx={{ width: "100%" }}>
          <AlertTitle>Thank you for your message</AlertTitle>
          Someone from our team will reach out soon.
        </Alert>
      ) : (
        <form
          method="post"
          noValidate
          onSubmit={e => {
            handleSubmit(handleSubmitForm)(e)
          }}
          style={{ marginBottom: 0 }}
        >
          <Stack spacing={3}>
            <Grid container spacing={1}>
              <Grid item xs={12} sm={6}>
                <Controller
                  name="firstName"
                  control={control}
                  rules={{
                    required: "Please enter your first name",
                  }}
                  render={({ field: { onChange }, fieldState: { error } }) => (
                    <Input
                      placeholder="First Name*"
                      onChange={onChange}
                      error={!!error}
                      helperText={error ? error.message : null}
                      fullWidth
                    />
                  )}
                />
              </Grid>

              <Grid item xs={12} sm={6}>
                <Controller
                  name="lastName"
                  control={control}
                  rules={{
                    required: "Please enter your last name",
                  }}
                  render={({ field: { onChange }, fieldState: { error } }) => (
                    <Input
                      placeholder="Last Name*"
                      onChange={onChange}
                      error={!!error}
                      helperText={error ? error.message : null}
                      fullWidth
                    />
                  )}
                />
              </Grid>

              <Grid item xs={12} sm={6}>
                <Controller
                  name="email"
                  control={control}
                  rules={{
                    required: "Please enter your email address",
                    pattern: {
                      value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                      message: "Please enter a valid email",
                    },
                  }}
                  render={({ field: { onChange }, fieldState: { error } }) => (
                    <Input
                      placeholder="Email address*"
                      onChange={onChange}
                      error={!!error}
                      helperText={error ? error.message : null}
                      type="email"
                      fullWidth
                    />
                  )}
                />
              </Grid>

              <Grid item xs={12} sm={6}>
                <Controller
                  name="phone"
                  control={control}
                  rules={{
                    required: "Please enter your phone number",
                  }}
                  render={({ field: { onChange }, fieldState: { error } }) => (
                    <Input
                      placeholder="Phone*"
                      onChange={onChange}
                      error={!!error}
                      helperText={error ? error.message : null}
                      fullWidth
                    />
                  )}
                />
              </Grid>

              <Grid item xs={12}>
                <Controller
                  name="company"
                  control={control}
                  rules={{
                    required: "Please enter a company name",
                  }}
                  render={({ field: { onChange }, fieldState: { error } }) => (
                    <Input
                      placeholder="Company*"
                      onChange={onChange}
                      error={!!error}
                      helperText={error ? error.message : null}
                      fullWidth
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12}>
                <Controller
                  name="message"
                  control={control}
                  rules={{
                    required: "Please enter a message",
                  }}
                  render={({ field: { onChange }, fieldState: { error } }) => (
                    <Input
                      placeholder="Message*"
                      onChange={onChange}
                      error={!!error}
                      helperText={error ? error.message : null}
                      fullWidth
                      rows={6}
                      multiline
                      sx={{ ".MuiOutlinedInput-root": { p: 0 } }}
                    />
                  )}
                />
              </Grid>
            </Grid>

            {status === "error" && (
              <Alert severity="error" sx={{ background: "#d32f2f" }}>
                An error occurred while sending the information. Please try
                again later.
              </Alert>
            )}

            <Button
              type="submit"
              variant="outlined"
              color="primary"
              loading={loading}
              fullWidth
            >
              {buttonText}
            </Button>
          </Stack>
        </form>
      )}
    </Box>
  )
}

export default ContactForm
