import bannerConfig from "../../../../features/modules/components/banner/config"
import cardsConfig from "../../../../features/modules/components/cards/config"
import cardsHorizontalConfig from "../../../../features/modules/components/cardsHorizontal/config"
import columnsConfig from "../../../../features/modules/components/columns/config"
import formConfig from "../../../../features/modules/components/form/config"
import heroConfig from "../../../../features/modules/components/hero/config"
import hexagonConfig from "../../../../features/modules/components/hexagon/config"
import iconTextCenteredConfig from "../../../../features/modules/components/iconTextCentered/config"
import imageCloudConfig from "../../../../features/modules/components/imageCloud/config"
import logosConfig from "../../../../features/modules/components/logos/config"
import stepsConfig from "../../../../features/modules/components/steps/config"
import teamMembersConfig from "../../../../features/modules/components/teamMembers/config"
import teaserConfig from "../../../../features/modules/components/teaser/config"
import testimonialsConfig from "../../../../features/modules/components/testimonials/config"
import testimonialsWithImagesConfig from "../../../../features/modules/components/testimonialsWithImages/config"
import textImageColumnsConfig from "../../../../features/modules/components/textImageColumns/config"
import textImageConfig from "../../../../features/modules/components/textImage/config"
import textImageWithOffsetConfig from "../../../../features/modules/components/textImageWithOffset/config"
import videoModalConfig from "../../../../features/modules/components/videoModal/config"
import wordCloudConfig from "../../../../features/modules/components/wordCloud/config"
import textConfig from "../../../../features/modules/components/text/config"
import videoConfig from "../../../../features/modules/components/video/config"
import textVideoConfig from "../../../../features/modules/components/textVideo/config"

const config = {
  id: "default",
  label: "Default Page",
  fields: [
    {
      id: "flex",
      label: "Blocks",
      type: "flexible_content",
      items: [
        bannerConfig,
        heroConfig,
        testimonialsConfig,
        textImageColumnsConfig,
        teaserConfig,
        textImageConfig,
        columnsConfig,
        formConfig,
        logosConfig,
        textImageWithOffsetConfig,
        iconTextCenteredConfig,
        videoConfig,
        textVideoConfig,
        videoModalConfig,
        imageCloudConfig,
        teamMembersConfig,
        cardsConfig,
        cardsHorizontalConfig,
        hexagonConfig,
        stepsConfig,
        testimonialsWithImagesConfig,
        wordCloudConfig,
        textConfig,
      ],
    },
  ],
}

export default config
