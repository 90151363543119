import React from "react"
import { Box } from "@mui/material"
import { GatsbyImage } from "jam-cms"
import { SxProps, Theme } from "@mui/material/styles"
import parse from "html-react-parser"

import type { ImageType } from "./types"

export type Props = {
  desktop: ImageType["desktop"]
  mobile: ImageType["mobile"]
  sx?: SxProps<Theme>
}

const Headline = (props: Props) => {
  const { desktop, mobile, sx = {} } = props

  return (
    <>
      {mobile && (
        <Box
          component="span"
          sx={{ display: { sm: desktop ? "none" : "block" }, ...sx }}
        >
          {mobile?.svg ? parse(mobile.svg) : <GatsbyImage image={mobile} />}
        </Box>
      )}

      {desktop && (
        <Box
          component="span"
          sx={{
            display: { xs: mobile ? "none" : "block", sm: "block" },
            ...sx,
          }}
        >
          {desktop?.svg ? parse(desktop.svg) : <GatsbyImage image={desktop} />}
        </Box>
      )}
    </>
  )
}

export default Headline
