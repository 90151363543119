const config = [
  {
    id: "title",
    type: "group",
    label: "Title",
    fields: [
      {
        id: "headline",
        type: "text",
        label: "Headline",
      },
      {
        id: "variant",
        type: "select",
        label: "Variant",
        defaultValue: "h3",
        options: [
          {
            name: "h1",
            value: "h1",
          },
          {
            name: "h2",
            value: "h2",
          },
          {
            name: "h3",
            value: "h3",
          },
          {
            name: "h4",
            value: "h4",
          },
          {
            name: "h5",
            value: "h5",
          },
          {
            name: "h6",
            value: "h6",
          },
          {
            name: "Subtitle 1",
            value: "subtitle1",
          },
          {
            name: "Subtitle 2",
            value: "subtitle2",
          },
        ],
      },
      {
        id: "color",
        type: "select",
        label: "Color",
        defaultValue: "textLight",
        options: [
          {
            name: "White 1",
            value: "textLight",
          },
          {
            name: "White 2",
            value: "textDark",
          },
          {
            name: "Orange 1",
            value: "orangeLight",
          },
          {
            name: "Orange 2",
            value: "orangeDark",
          },
          {
            name: "Blue 1",
            value: "blueLight",
          },
          {
            name: "Blue 2",
            value: "blueDark",
          },
        ],
      },
    ],
  },
]

export default config
