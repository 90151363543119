import React from "react"
import { Box } from "@mui/material"
import { colors } from "@parallel-domain/pd-theme"

import linework1 from "../../../../assets/images/linework-1.png"
import linework2 from "../../../../assets/images/linework-2.png"
import linework3 from "../../../../assets/images/linework-3.png"
import linework4 from "../../../../assets/images/linework-4.png"

const backgroundColorMap = {
  0: "transparent",
  1: colors.secondary[600],
  2: colors.secondary[700],
  3: colors.secondary[800],
  4: colors.secondary[900],
}

const backgroundPatternMap = {
  0: "",
  1: `url(${linework1}) left bottom no-repeat`,
  2: `url(${linework2}) left bottom no-repeat`,
  3: `url(${linework3}) left bottom no-repeat`,
  4: `url(${linework4}) left bottom no-repeat`,
}

type Props = {
  backgroundcolor?: number
  backgroundpattern?: number
  margintop?: number
  marginbottom?: number
  paddingtop?: number
  paddingbottom?: number
  pattern?: string
  children: React.ReactNode
}

const FlexWrapper = (props: Props) => {
  const {
    backgroundcolor = 0,
    backgroundpattern = 0,
    margintop = 0,
    marginbottom = 0,
    paddingtop = 0,
    paddingbottom = 0,
    children,
  } = props

  return (
    <Box
      sx={{
        position: "relative",
        width: "100%",
        mt: [margintop * 1, margintop * 3, margintop * 5],
        mb: [marginbottom * 1, marginbottom * 3, marginbottom * 5],
        pt: [paddingtop * 1, paddingtop * 3, paddingtop * 5],
        pb: [paddingbottom * 1, paddingbottom * 3, paddingbottom * 5],
        background: `${backgroundColorMap[backgroundcolor]} ${
          backgroundPatternMap[backgroundpattern] || ""
        }`,
        backgroundSize: "cover",
        backgroundPosition: "center",
      }}
    >
      {children}
    </Box>
  )
}

export default FlexWrapper
