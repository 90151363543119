import flexibleContentConfig from "../../../../features/submodules/components/flexibleContent/config"

const config = {
  id: "default",
  label: "Open Dataset",
  fields: [
    {
      id: "excerpt",
      type: "text",
      label: "Excerpt",
    },
    {
      id: "dataset",
      type: "group",
      label: "Dataset",
      fields: [
        {
          id: "artifact",
          type: "text",
          label: "Artifact UID",
        },
        {
          id: "scenes",
          type: "text",
          label: "Visible Scenes (optional)",
          instructions:
            "Comma-separated list of scene names. Leave empty to publish all scenes.",
        },
        {
          id: "instructions",
          type: "text",
          label: "Instructions (optional)",
        },
        {
          id: "downloads",
          type: "repeater",
          label: "Download URLs",
          items: [
            {
              id: "title",
              type: "text",
              label: "Title",
            },
            {
              id: "url",
              type: "text",
              label: "URL",
            },
            {
              id: "region",
              type: "text",
              label: "AWS Region (only for S3 links)",
            },
          ],
        },
        {
          id: "terms",
          type: "wysiwyg",
          label: "Terms",
        },
      ],
    },
    ...flexibleContentConfig,
  ],
}

export default config
