import flexWrapperConfig from "../../../submodules/components/flexWrapper/config"
import headlineConfig from "../../../submodules/components/headline/config"

const config = {
  id: "text",
  label: "Text",
  fields: [
    ...headlineConfig,
    {
      id: "text",
      type: "wysiwyg",
      label: "Text",
    },
    ...flexWrapperConfig,
  ],
}

export default config
