import buttonConfig from "../../features/submodules/components/button/config"

const config = {
  id: "header",
  label: "Header",
  type: "group",
  fields: [
    {
      id: "headermenu",
      type: "menu",
      label: "Menu",
      maxLevel: 2,
    },
    {
      id: "buttons",
      type: "repeater",
      label: "Button",
      items: buttonConfig,
    },
    {
      id: "headermenubreakpoint",
      type: "number",
      label: "Menu breakpoint",
    },
  ],
}

export default config
