const config = {
  id: "footer",
  label: "Footer",
  type: "group",
  fields: [
    {
      id: "footermenu",
      type: "menu",
      label: "Menu",
      maxLevel: 1,
    },
  ],
}

export default config
