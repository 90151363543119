import videoConfig from "../../../submodules/components/video/config"
import flexWrapperConfig from "../../../submodules/components/flexWrapper/config"

const config = {
  id: "video",
  label: "Video",
  fields: [...videoConfig, ...flexWrapperConfig],
}

export default config
