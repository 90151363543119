import React, { useEffect, useRef } from "react"
import { motion, useInView, useAnimation } from "framer-motion"
import { SxProps, Theme } from "@mui/material/styles"
import { Box } from "@mui/material"

type Props = {
  children: JSX.Element
  delay?: number
  width?: "fit-content" | "100%" | "auto"
  background?: string
  repeat?: boolean
  sx?: SxProps<Theme>
}

const Reveal = (props: Props) => {
  const {
    children,
    width = "fit-content",
    delay = 0.25,
    background = "transparent",
    sx = {},
  } = props

  const mainControls = useAnimation()
  const slideControls = useAnimation()

  const ref = useRef(null)
  const isInView = useInView(ref, { once: true })

  useEffect(() => {
    if (isInView) {
      slideControls.start("visible")
      mainControls.start("visible")
    } else {
      slideControls.start("hidden")
      mainControls.start("hidden")
    }
  }, [isInView, mainControls, slideControls])

  return (
    <Box
      ref={ref}
      sx={{ position: "relative", width, overflow: "hidden", ...sx }}
    >
      <motion.div
        variants={{
          hidden: {
            opacity: 0,
            y: 75,
          },
          visible: { opacity: 1, y: 0 },
        }}
        initial="hidden"
        animate={mainControls}
        transition={{
          duration: 0.5,
          delay,
        }}
      >
        {children}
      </motion.div>
      <motion.div
        variants={{
          hidden: { left: 0 },
          visible: { left: "100%" },
        }}
        initial="hidden"
        animate={slideControls}
        transition={{ duration: 0.5, ease: "easeIn" }}
        style={{
          position: "absolute",
          top: 4,
          bottom: 4,
          left: 0,
          right: 0,
          background,
          zIndex: 20,
        }}
      />
    </Box>
  )
}

export default Reveal
