import React from "react"
import { Box, Container } from "@mui/material"

// import app components
import { Video as VideoComponent } from "../../../submodules/components/video"

const Video = props => {
  const { video } = props

  return (
    <Box sx={{ py: [3, 4, 5] }}>
      <Container maxWidth="lg">
        {video && (
          <Box sx={{ borderRadius: "1rem", overflow: "hidden" }}>
            <VideoComponent {...video} />
          </Box>
        )}
      </Container>
    </Box>
  )
}

export default Video
