import React from "react"
import { Box, Typography, IconButton } from "@mui/material"
import Slider from "react-slick"
import { Textarea, colors } from "@parallel-domain/pd-theme"
import { ArrowRightCircled, ArrowLeftCircled } from "iconoir-react"

// import app components
import Reveal from "../../../../components/reveal"

const ArrowWrapper = ({ currentSlide, slideCount, children, ...rest }) => (
  <span {...rest}>{children}</span>
)

const settings = {
  dots: true,
  arrows: true,
  infinite: true,
  speed: 500,
  autoplay: true,
  autoplaySpeed: 10000,
  pauseOnHover: true,
  slidesToShow: 1,
  slidesToScroll: 1,
  nextArrow: (
    <ArrowWrapper>
      <IconButton>
        <ArrowRightCircled
          style={{ fontSize: "1.5rem", color: colors.primary[900] }}
        />
      </IconButton>
    </ArrowWrapper>
  ),
  prevArrow: (
    <ArrowWrapper>
      <IconButton>
        <ArrowLeftCircled
          style={{ fontSize: "1.5rem", color: colors.primary[900] }}
        />
      </IconButton>
    </ArrowWrapper>
  ),
  responsive: [
    {
      breakpoint: 600,
      settings: {
        arrows: false,
      },
    },
  ],
}

type Props = {
  items: { text: string; author: string; company: string }[]
}

const Testimonials = (props: Props) => {
  const { items } = props

  return (
    <Box sx={{ py: 5, overflow: "hidden" }}>
      {items && (
        <Box
          sx={{
            position: "relative",
            "*": { outline: "none" },
            ".slick-track": { display: "flex !important" },
            ".slick-list": { margin: "0 -10px" },
            ".slick-slide": {
              position: "relative",
              height: "inherit !important",
              borderRadius: "10px",
              margin: "0 10px",
            },
            ".slick-arrow": {
              position: "absolute",
              display: "flex !important",
              width: "50px",
              height: "50px",
              transition: "ease all 0.2s",
              zIndex: 10,
              opacity: 0,
            },
            "&:hover": {
              ".slick-arrow": { opacity: 0.6, "&:hover": { opacity: 1 } },
            },
            ".slick-prev": {
              left: "100px",
              top: "50%",
              transform: "translateY(-50%)",
            },
            ".slick-next": {
              right: "100px",
              top: "50%",
              transform: "translateY(-50%)",
            },
            ".slick-dots": { top: "calc(100% + 15px)" },
            ".slick-dots li": { margin: "0" },
            ".slick-dots li button:before": {
              color: colors.secondary[600],
              fontSize: "2rem",
            },
            ".slick-dots li.slick-active button:before": {
              color: colors.primary[900],
            },
          }}
        >
          <Slider {...settings}>
            {items.map((o, i) => {
              return (
                <Box
                  key={i}
                  sx={{
                    textAlign: "center",
                    px: 2,
                  }}
                >
                  <Box sx={{ maxWidth: "800px", width: "100%", mx: "auto" }}>
                    {o?.text && (
                      <Reveal delay={0.2}>
                        <Textarea p="h4">{o.text}</Textarea>
                      </Reveal>
                    )}

                    {o?.company && (
                      <Reveal delay={0.3} width="auto">
                        <Typography color="primary">{o.company}</Typography>
                      </Reveal>
                    )}

                    {o?.author && (
                      <Reveal delay={0.3} width="auto">
                        <Typography variant="subtitle2" color="text.secondary">
                          {o.author}
                        </Typography>
                      </Reveal>
                    )}
                  </Box>
                </Box>
              )
            })}
          </Slider>
        </Box>
      )}
    </Box>
  )
}

export default Testimonials
