"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ScrollDirection = void 0;
const react_1 = require("react");
var ScrollDirection;
(function (ScrollDirection) {
    ScrollDirection["up"] = "up";
    ScrollDirection["down"] = "down";
})(ScrollDirection = exports.ScrollDirection || (exports.ScrollDirection = {}));
const useScrollDirection = () => {
    const threshold = 0;
    const [scrollDir, setScrollDir] = (0, react_1.useState)(ScrollDirection.up);
    (0, react_1.useEffect)(() => {
        let previousScrollYPosition = window.scrollY;
        const scrolledMoreThanThreshold = (currentScrollYPosition) => Math.abs(currentScrollYPosition - previousScrollYPosition) > threshold;
        const isScrollingUp = (currentScrollYPosition) => currentScrollYPosition > previousScrollYPosition &&
            !(previousScrollYPosition > 0 && currentScrollYPosition === 0) &&
            !(currentScrollYPosition > 0 && previousScrollYPosition === 0);
        const updateScrollDirection = () => {
            const currentScrollYPosition = window.scrollY;
            if (scrolledMoreThanThreshold(currentScrollYPosition)) {
                const newScrollDirection = isScrollingUp(currentScrollYPosition) ? ScrollDirection.down : ScrollDirection.up;
                setScrollDir(newScrollDirection);
                previousScrollYPosition = currentScrollYPosition > 0 ? currentScrollYPosition : 0;
            }
        };
        const onScroll = () => window.requestAnimationFrame(updateScrollDirection);
        window.addEventListener("scroll", onScroll);
        return () => window.removeEventListener("scroll", onScroll);
    }, []);
    return scrollDir;
};
exports.default = useScrollDirection;
