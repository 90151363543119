const config = [
  {
    id: "wrapper",
    type: "group",
    label: "Wrapper",
    fields: [
      {
        id: "backgroundcolor",
        type: "select",
        label: "Background Color",
        defaultValue: "3",
        options: [
          {
            name: "Dark Blue 1",
            value: "1",
          },
          {
            name: "Dark Blue 2",
            value: "2",
          },
          {
            name: "Dark Blue 3",
            value: "3",
          },
          {
            name: "Dark Blue 4",
            value: "4",
          },
        ],
      },
      {
        id: "backgroundpattern",
        type: "select",
        label: "Background Pattern",
        defaultValue: "0",
        options: [
          {
            name: "None",
            value: "0",
          },
          {
            name: "Variant 1",
            value: "1",
          },
          {
            name: "Variant 2",
            value: "2",
          },
          {
            name: "Variant 3",
            value: "3",
          },
          {
            name: "Variant 4",
            value: "4",
          },
        ],
      },
      {
        id: "margintop",
        type: "select",
        label: "Margin Top",
        defaultValue: "0",
        options: [
          {
            name: "None",
            value: "0",
          },
          {
            name: "Small",
            value: "1",
          },
          {
            name: "Medium",
            value: "2",
          },
          {
            name: "Large",
            value: "3",
          },
          {
            name: "XLarge",
            value: "4",
          },
        ],
      },
      {
        id: "marginbottom",
        type: "select",
        label: "Margin Bottom",
        defaultValue: "0",
        options: [
          {
            name: "None",
            value: "0",
          },
          {
            name: "Small",
            value: "1",
          },
          {
            name: "Medium",
            value: "2",
          },
          {
            name: "Large",
            value: "3",
          },
          {
            name: "XLarge",
            value: "4",
          },
        ],
      },
      {
        id: "paddingtop",
        type: "select",
        label: "Padding Top",
        defaultValue: "2",
        options: [
          {
            name: "None",
            value: "0",
          },
          {
            name: "Small",
            value: "1",
          },
          {
            name: "Medium",
            value: "2",
          },
          {
            name: "Large",
            value: "3",
          },
          {
            name: "XLarge",
            value: "4",
          },
        ],
      },
      {
        id: "paddingbottom",
        type: "select",
        label: "Padding Bottom",
        defaultValue: "2",
        options: [
          {
            name: "None",
            value: "0",
          },
          {
            name: "Small",
            value: "1",
          },
          {
            name: "Medium",
            value: "2",
          },
          {
            name: "Large",
            value: "3",
          },
          {
            name: "XLarge",
            value: "4",
          },
        ],
      },
    ],
  },
]

export default config
