"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _react = require("react");
const useInterval = (callback, delay) => {
  const savedCallback = (0, _react.useRef)();

  // Remember the latest callback.
  (0, _react.useEffect)(() => {
    savedCallback.current = callback;
  }, [callback]);

  // Set up the interval.
  (0, _react.useEffect)(() => {
    function tick() {
      savedCallback.current();
    }
    if (delay !== null) {
      let id = setInterval(tick, delay);
      return () => clearInterval(id);
    }
  }, [delay]);
};
var _default = useInterval;
exports.default = _default;