import React from "react"
import {
  Box,
  PaginationItem,
  Pagination as MuiPagination,
  Link,
} from "@mui/material"
import { Link as GatsbyLink } from "gatsby"
import { colors } from "@parallel-domain/pd-theme"

const Pagination = props => {
  const { basePath, numberOfPages, numberOfPosts, page, postsPerPage } = props

  if (numberOfPages < 2) {
    return null
  }

  return (
    <Box sx={{ display: "flex", justifyContent: "center" }}>
      <MuiPagination
        count={numberOfPages}
        page={page}
        variant="outlined"
        size="large"
        renderItem={item => {
          return item.disabled ? (
            <PaginationItem {...item} />
          ) : (
            <Link
              component={GatsbyLink}
              to={item.page > 1 ? `${basePath}page/${item.page}` : basePath}
            >
              <PaginationItem {...item} />
            </Link>
          )
        }}
        sx={{
          ".Mui-selected": {
            color: colors.primary[900],
          },
        }}
      />
    </Box>
  )
}

export default Pagination
