import React, { useEffect, useState } from "react"
import loadable from "@loadable/component"
import axios from "axios"
import { Box, Alert } from "@mui/material"

// import app components

const QaTool = loadable(() => import("@parallel-domain/pd-qatool"))

const Preview = props => {
  const { pathname, instructions, onClose } = props

  const [dataset, setDataset] = useState(null)

  useEffect(() => {
    const loadDataset = async () => {
      try {
        const { data } = await axios.post(
          "/.netlify/functions/getOpenDataset",
          { pathname }
        )

        setDataset(data.data)
      } catch (error) {
        console.log(error)
      }
    }

    loadDataset()
  }, [pathname])

  return (
    <QaTool
      {...dataset}
      enableLidarProjection={false}
      contentHeader={
        <Box sx={{ p: "20px 20px 0" }}>
          <Alert severity="info">{instructions}</Alert>
        </Box>
      }
      onBack={onClose}
    />
  )
}

export default Preview
