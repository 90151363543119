"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
Object.defineProperty(exports, "CmsStyles", {
  enumerable: true,
  get: function get() {
    return _CmsStyles.default;
  }
});
Object.defineProperty(exports, "colors", {
  enumerable: true,
  get: function get() {
    return _colors.default;
  }
});
var _colors = _interopRequireDefault(require("./colors"));
var _CmsStyles = _interopRequireDefault(require("./CmsStyles"));