import React, { useState } from "react"
import { Box, Container } from "@mui/material"
import { Dialog } from "@parallel-domain/pd-theme"

// import app components
import { Image } from "../../../submodules/components/image"
import { Video } from "../../../submodules/components/video"

const VideoModal = props => {
  const { video, image } = props

  const [open, setOpen] = useState(false)

  return (
    <>
      <Box sx={{ py: [3, 4, 5] }}>
        <Container maxWidth="lg">
          {image && (
            <Box
              onClick={() => video && setOpen(true)}
              sx={{ cursor: "pointer" }}
            >
              <Image {...image} />
            </Box>
          )}
        </Container>
      </Box>

      <Dialog
        open={open}
        onClose={() => setOpen(false)}
        maxWidth="lg"
        fullWidth
      >
        <Box sx={{ borderRadius: "1rem", overflow: "hidden" }}>
          <Video {...video} />
        </Box>
      </Dialog>
    </>
  )
}

export default VideoModal
