"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const toolbar = {
    MuiToolbar: {
        styleOverrides: {
            root: {},
        },
    },
};
exports.default = toolbar;
