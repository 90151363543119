import { colors } from "@parallel-domain/pd-theme"

export const HEADER_HEIGHT = 80

export const colorMap = {
  textLight: colors.text[100],
  textDark: colors.text[700],
  orangeLight: colors.primary[700],
  orangeDark: colors.primary[1000],
  blueLight: colors.tertiary[600],
  blueDark: colors.tertiary[700],
}
