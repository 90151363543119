import imageConfig from "../../../../features/submodules/components/image/config"

const config = {
  id: "archive",
  label: "Open Datasets",
  query: `{
    allWpOpendataset: allOpendataset {
      nodes {
        id
        title
        uri
        date
        featuredImage {
          node {
            altText
            srcSet
            sourceUrl
            mediaType
            sizes
            mediaDetails {
              width
              height
            }
          }
        }
        acf {
          excerpt
        }
      }
    }
  }`,
  fields: [
    {
      id: "banner",
      type: "group",
      label: "Banner",
      fields: [
        ...imageConfig,
        {
          id: "headline",
          type: "text",
          label: "Headine",
        },
      ],
    },
    {
      id: "newsletter",
      type: "group",
      label: "Newsletter",
      fields: [
        {
          id: "headline",
          type: "text",
          label: "Headine",
        },
        {
          id: "buttontext",
          type: "text",
          label: "Button Text",
        },
      ],
    },
  ],
}

export default config
