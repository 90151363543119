const config = [
  {
    id: "button",
    type: "group",
    label: "Button",
    fields: [
      {
        id: "link",
        type: "link",
        label: "Link",
      },
      {
        id: "variant",
        type: "select",
        label: "Variant",
        defaultValue: "outlined",
        options: [
          {
            name: "Contained",
            value: "contained",
          },
          {
            name: "Outlined",
            value: "outlined",
          },
          {
            name: "Text",
            value: "text",
          },
        ],
      },
    ],
  },
]

export default config
