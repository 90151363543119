const config = [
  {
    id: "image",
    type: "group",
    label: "Image",
    fields: [
      {
        id: "desktop",
        type: "image",
        label: "Desktop",
      },
      {
        id: "mobile",
        type: "image",
        label: "Mobile (Optional)",
      },
    ],
  },
]

export default config
