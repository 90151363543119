"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = formatTaxonomiesForEditor;
function formatTaxonomiesForEditor(post, site) {
  if (!(post !== null && post !== void 0 && post.taxonomies) || !(site !== null && site !== void 0 && site.taxonomies)) {
    return null;
  }
  const taxonomies = {};
  Object.keys(post.taxonomies).map(k => {
    var _site$taxonomies;
    const taxonomy = site === null || site === void 0 ? void 0 : (_site$taxonomies = site.taxonomies) === null || _site$taxonomies === void 0 ? void 0 : _site$taxonomies[k];
    if (!taxonomy) {
      return;
    }
    const {
      graphqlPluralName
    } = taxonomy;
    Object.values(site.taxonomies[k].terms).map(_ref => {
      let {
        id,
        title,
        uri,
        description,
        count,
        parentID
      } = _ref;
      if (post.taxonomies[k].includes(id)) {
        const taxonomy = {
          id,
          databaseId: id,
          name: title,
          uri,
          description,
          count,
          parentId: parentID
        };
        if (taxonomies[graphqlPluralName]) {
          taxonomies[graphqlPluralName].nodes.push(taxonomy);
        } else {
          taxonomies[graphqlPluralName] = {
            nodes: [taxonomy]
          };
        }
      }
    });
  });
  return taxonomies;
}