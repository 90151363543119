import React from "react"
import { Link as GatsbyLink } from "gatsby"
import { Box, Container, Typography } from "@mui/material"
import { colors, Textarea } from "@parallel-domain/pd-theme"

// import app components
import { Headline, HeadlineType } from "../../../submodules/components/headline"
import { Image, ImageType } from "../../../submodules/components/image"
import { Button, ButtonType } from "../../../submodules/components/button"
import Reveal from "../../../../components/reveal"

type Props = {
  title: HeadlineType
  items: {
    cardtitle: string
    text: string
    button: ButtonType
    image: ImageType
  }[]
}

const Cards = (props: Props) => {
  const { title, items } = props

  return (
    <Box sx={{ py: [3, 4, 5] }}>
      <Container maxWidth="lg">
        {title && (
          <Box sx={{ display: "flex", justifyContent: "center", mb: [5, 10] }}>
            <Headline {...title} />
          </Box>
        )}

        {items &&
          items.map((o, i) => {
            const isEven = i % 2 === 0

            return (
              <Reveal key={i} sx={{ width: "100%" }}>
                <Box
                  sx={{
                    display: "flex",
                    flexWrap: "wrap",
                    minHeight: "320px",
                    mb: 4,
                    borderRadius: "10px",
                    overflow: "hidden",
                    width: "100%",
                    // Safari fix for border radius
                    transform: "translateZ(0)",
                  }}
                >
                  <Box
                    sx={{
                      position: "relative",
                      width: ["100%", "100%", "50%"],
                      height: ["180px", "250px", "auto"],
                      order: [1, 1, isEven ? 1 : 3],
                    }}
                  >
                    {o.image && (
                      <Box
                        sx={{
                          position: "absolute",
                          left: 0,
                          top: 0,
                          width: "100%",
                          height: "100%",
                        }}
                      >
                        <Image
                          {...o.image}
                          sx={{
                            height: "100%",
                            ".gatsby-image-wrapper": {
                              height: "100%",
                              width: "100%",
                            },
                          }}
                        />
                      </Box>
                    )}
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      width: ["100%", "100%", "50%"],
                      px: [3, 5, 8],
                      py: [3, 5, 6],
                      order: 2,
                      background: isEven
                        ? colors.secondary[600]
                        : colors.secondary[700],
                    }}
                  >
                    <Box>
                      {o.cardtitle && (
                        <Reveal delay={0.5}>
                          <Typography variant="h3">{o.cardtitle}</Typography>
                        </Reveal>
                      )}

                      {o.text && (
                        <Reveal width="100%">
                          <Textarea p="body2" linkComponent={GatsbyLink}>
                            {o.text}
                          </Textarea>
                        </Reveal>
                      )}

                      {o.button && (
                        <Reveal delay={0.5}>
                          <Button
                            {...o.button.link}
                            variant={o.button.variant}
                          />
                        </Reveal>
                      )}
                    </Box>
                  </Box>
                </Box>
              </Reveal>
            )
          })}
      </Container>
    </Box>
  )
}

export default Cards
