import React from "react"
import { Box, Container, Typography, Grid, Link } from "@mui/material"
import { Link as GatsbyLink } from "gatsby"
import parse from "html-react-parser"
import { colors } from "@parallel-domain/pd-theme"

// import app components
import BackgroundImage from "../../../../components/backgroundImage"

const RelatedPosts = props => {
  const { posts } = props

  return (
    <Box sx={{ py: 10, background: colors.secondary[800] }}>
      <Container maxWidth="xl">
        <Typography variant="h2" sx={{ textAlign: "center", mb: 3 }}>
          Other Articles
        </Typography>

        <Grid container spacing={5}>
          {posts &&
            posts.map(o => {
              return (
                <Grid key={o.id} item xs={12} sm={6} md={4}>
                  <Link component={GatsbyLink} to={o?.uri} underline="none">
                    <Box
                      sx={{
                        position: "relative",
                        height: "250px",
                        borderRadius: "1rem",
                        overflow: "hidden",
                        background: colors.secondary[700],
                        mb: 2,
                      }}
                    >
                      {o?.featuredImage?.node && (
                        <BackgroundImage image={o.featuredImage.node} />
                      )}
                    </Box>
                    <Typography variant="h3">{parse(o.title)}</Typography>
                  </Link>
                </Grid>
              )
            })}
        </Grid>
      </Container>
    </Box>
  )
}

export default RelatedPosts
