import React from "react"
import { Box, Grid, Typography, Container, Stack } from "@mui/material"
import { colors } from "@parallel-domain/pd-theme"
import parse from "html-react-parser"

// import app components
import { Image } from "../../../submodules/components/image"
import Reveal from "../../../../components/reveal"

const Columns = props => {
  const { subline, headline, text, columnsdesktop, columnsmobile, items } =
    props

  return (
    <Box sx={{ py: [3, 4, 5] }}>
      <Container maxWidth="lg">
        {(subline || headline || text) && (
          <Stack spacing={2} sx={{ mb: 6, alignItems: "center" }}>
            {subline && (
              <Reveal background={colors.primary[700]}>
                <Typography variant="subtitle2" color="primary">
                  {parse(subline)}
                </Typography>
              </Reveal>
            )}

            {headline && (
              <Reveal>
                <Typography variant="h2">{parse(headline)}</Typography>
              </Reveal>
            )}

            {text && (
              <Reveal>
                <Typography variant="body2">{parse(text)}</Typography>
              </Reveal>
            )}
          </Stack>
        )}

        <Grid container spacing={6} sx={{ justifyContent: "center" }}>
          {items &&
            items.map((o, index) => {
              return (
                <Grid
                  key={index}
                  item
                  xs={12 / columnsmobile}
                  md={12 / Math.ceil((columnsmobile + columnsdesktop) / 2)}
                  lg={12 / columnsdesktop}
                >
                  <Stack spacing={2} sx={{ px: 1 }}>
                    {o.image && <Image {...o.image} />}

                    {o.headline && (
                      <Reveal delay={0.25 * index}>
                        <Typography variant="h3">
                          {parse(o.headline)}
                        </Typography>
                      </Reveal>
                    )}

                    {o.text && (
                      <Reveal delay={0.4 * index}>
                        <Typography variant="body2">{parse(o.text)}</Typography>
                      </Reveal>
                    )}
                  </Stack>
                </Grid>
              )
            })}
        </Grid>
      </Container>
    </Box>
  )
}

export default Columns
