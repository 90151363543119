"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = recursivelyUpdateTree;
var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));
function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }
function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { (0, _defineProperty2.default)(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }
function recursivelyUpdateTree(node, func, param) {
  if (typeof func !== 'function' || !node.hasOwnProperty('children') || node.children.length < 1) {
    return;
  }
  const newNodeChildren = node.children.map(child => {
    const newChild = func(node, child, param);
    return _objectSpread(_objectSpread(_objectSpread({}, child), newChild), {}, {
      children: recurseChildren(newChild, newChild.children, func, param)
    });
  });
  function recurseChildren(parent, children, func, param) {
    if (!children) {
      return;
    }
    const newChildren = children.map(child => {
      const newChild = func(parent, child, param);
      if (child.hasOwnProperty('children') && child.children.length > 0) {
        return _objectSpread(_objectSpread(_objectSpread({}, child), newChild), {}, {
          children: recurseChildren(newChild, newChild.children, func, param)
        });
      }
      return _objectSpread(_objectSpread({}, child), newChild);
    });
    return newChildren;
  }
  return newNodeChildren;
}