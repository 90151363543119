import flexibleContentConfig from "../../../../features/submodules/components/flexibleContent/config"

const config = {
  id: "default",
  label: "Post Default",
  query: `{
    allWpPost: posts(first: 4) {
      nodes {
        id: databaseId
        title
        excerpt
        uri
        date
        featuredImage {
          node {
            altText
            srcSet
            sourceUrl
            mediaType
            sizes
            mediaDetails {
              width
              height
            }
          }
        }
      }
    }
  }`,
  fields: [
    {
      id: "author",
      type: "text",
      label: "Author",
    },
    {
      id: "excerpt",
      type: "text",
      label: "Excerpt",
    },
    {
      id: "date",
      type: "date_picker",
      label: "Date",
      returnFormat: "Y/m/d",
    },
    ...flexibleContentConfig,
  ],
}

export default config
