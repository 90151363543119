import React from "react"
import { Box } from "@mui/material"

// import app components
import Header from "../header"
import Footer from "../footer"
import { HEADER_HEIGHT } from "../../constants"
import type { ThemeOptions } from "../../types"

type Props = {
  location: unknown
  pageContext: { themeOptions: ThemeOptions }
  children: React.ReactNode | React.ReactNode[]
}

const PageWrapper = (props: Props) => {
  const {
    location,
    children,
    pageContext: { themeOptions },
  } = props

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        minHeight: "100vh",
        pt: themeOptions?.header ? `${HEADER_HEIGHT}px` : 0,
      }}
    >
      {themeOptions?.header && (
        <Header path={location?.pathname} {...themeOptions.header} />
      )}

      {children}

      {themeOptions?.footer && (
        <Footer
          path={location?.pathname}
          {...themeOptions.footer}
          socials={themeOptions.socials}
        />
      )}
    </Box>
  )
}

export default PageWrapper
