import React, { useState } from "react"
import { Link as GatsbyLink } from "gatsby"
import { AppBar, Box, Link, Container, IconButton, Stack } from "@mui/material"
import {
  useScrollPosition,
  useScrollDirection,
  colors,
} from "@parallel-domain/pd-theme"
import { Menu } from "iconoir-react"

// import app components
import DesktopMenu from "./DesktopMenu"
import MobileMenu from "./MobileMenu"
import Logo from "../../assets/icons/ParallelDomain_Logo_White.svg"
import { HEADER_HEIGHT } from "../../constants"
import type { MenuItem } from "./types"
import { Button } from "../../features/submodules/components/button"
import type { ButtonType } from "../../features/submodules/components/button/types"

type Props = {
  path: string
  headermenu: MenuItem[]
  buttons: { button: ButtonType }[]
  headermenubreakpoint: number
}

const Header = (props: Props) => {
  const { path, headermenu, buttons, headermenubreakpoint } = props

  const [open, setOpen] = useState(false)

  const scrollPosition = useScrollPosition()
  const scrollDirection = useScrollDirection()

  // TODO: This should be moved to a utility function
  const offset = typeof window !== "undefined" ? window.innerHeight : 1000

  return (
    <>
      <AppBar
        sx={{
          position:
            scrollPosition > offset || scrollDirection === "up"
              ? "fixed"
              : "absolute",
          transform:
            scrollPosition > HEADER_HEIGHT &&
            scrollPosition < offset &&
            scrollDirection === "down"
              ? `translateY(-${HEADER_HEIGHT}px)`
              : "translateY(0px)",
          transition: "ease transform 0.4s",
          background: "transparent",
          backdropFilter:
            scrollPosition > offset ||
            (scrollPosition > 0 && scrollDirection === "up")
              ? "blur(10px)"
              : "none",
        }}
        color="secondary"
      >
        <Container maxWidth="xl">
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              width: "100%",
              height: `${HEADER_HEIGHT}px`,
            }}
          >
            <Link to="/" component={GatsbyLink}>
              <Box
                component="span"
                sx={{
                  display: "flex",
                  svg: { height: "50px" },
                }}
              >
                <Logo />
              </Box>
            </Link>

            <Box sx={{ display: "flex", alignItems: "center" }}>
              <Box
                sx={{
                  display: "none",
                  [`@media (min-width: ${headermenubreakpoint}px)`]: {
                    display: "flex",
                    alignItems: "center",
                  },
                }}
              >
                <DesktopMenu path={path} items={headermenu} />

                {buttons && (
                  <Stack
                    direction="row"
                    spacing={2}
                    sx={{ justifyContent: "center", ml: 4 }}
                  >
                    {buttons.map((o: { button: ButtonType }, index: number) => (
                      <Button
                        key={index}
                        size="small"
                        {...o.button.link}
                        variant={o.button.variant}
                      />
                    ))}
                  </Stack>
                )}
              </Box>

              <Box
                sx={{
                  display: "block",
                  [`@media (min-width: ${headermenubreakpoint}px)`]: {
                    display: "none",
                  },
                }}
              >
                <IconButton onClick={() => setOpen(!open)}>
                  <Menu
                    style={{ color: colors.icons[100], fontSize: "1rem" }}
                  />
                </IconButton>
              </Box>
            </Box>
          </Box>
        </Container>
      </AppBar>

      <MobileMenu
        path={path}
        items={headermenu}
        open={open}
        buttons={buttons}
        onClose={() => setOpen(false)}
      />
    </>
  )
}

export default Header
