import React from "react"
import moment from "moment"
import { Box, Typography, Container, Stack, Grid } from "@mui/material"
import { colors } from "@parallel-domain/pd-theme"
import parse from "html-react-parser"

// import app components
import BackgroundImage from "../../../../components/backgroundImage"
import Reveal from "../../../../components/reveal"

const PostHeaderHorizontal = props => {
  const { image, date, title, author } = props

  return (
    <Box sx={{ py: 4, background: colors.secondary[800] }}>
      <Container maxWidth="xl">
        <Grid container spacing={{ xs: 4, sm: 8, md: 12 }}>
          <Grid item xs={12} md={6}>
            <Box
              sx={{
                position: "relative",
                height: { xs: "200px", sm: "400px" },
                borderRadius: "1rem",
                overflow: "hidden",
              }}
            >
              {image && <BackgroundImage image={image} />}
            </Box>
          </Grid>

          <Grid
            item
            xs={12}
            md={6}
            sx={{ display: "flex", alignItems: "center" }}
          >
            <Stack spacing={2}>
              {date && (
                <Reveal background={colors.primary[700]}>
                  <Typography variant="subtitle2" color="primary">
                    {moment(date, "YYYY/MM/DD").format("MMMM DD YYYY")}
                  </Typography>
                </Reveal>
              )}

              {title && (
                <Reveal>
                  <Typography variant="h1">{parse(title)}</Typography>
                </Reveal>
              )}

              {author && (
                <Reveal>
                  <Typography sx={{ color: colors.tertiary[700] }}>
                    {author}
                  </Typography>
                </Reveal>
              )}
            </Stack>
          </Grid>
        </Grid>
      </Container>
    </Box>
  )
}

export default PostHeaderHorizontal
