import imageConfig from "../../../submodules/components/image/config"
import flexWrapperConfig from "../../../submodules/components/flexWrapper/config"
import headlineConfig from "../../../submodules/components/headline/config"

const config = {
  id: "logos",
  label: "Logos",
  fields: [
    ...headlineConfig,
    {
      id: "text",
      type: "wysiwyg",
      label: "Text",
    },
    {
      id: "columnsdesktop",
      type: "number",
      label: "Columns Desktop",
      defaultValue: 3,
    },
    {
      id: "columnsmobile",
      type: "number",
      label: "Columns Mobile",
      defaultValue: 1,
    },
    {
      id: "items",
      type: "repeater",
      label: "Items",
      items: [...imageConfig],
    },
    ...flexWrapperConfig,
  ],
}

export default config
