import React from "react"
import { Box, Grid, Typography, Container, Stack } from "@mui/material"
import parse from "html-react-parser"

// import app components
import { Headline } from "../../../submodules/components/headline"
import { Video as VideoComponent } from "../../../submodules/components/video"
import Reveal from "../../../../components/reveal"

const TextVideo = props => {
  const { icon, title, text, video } = props

  return (
    <Box sx={{ py: [5] }}>
      <Container maxWidth="lg">
        {(icon || title || text || video) && (
          <Grid container spacing={6} sx={{ mb: 6 }}>
            <Grid item xs={12} sm={6} md={7} sx={{ mb: [2, 0], order: 2 }}>
              <Box
                sx={{
                  position: "relative",
                  zIndex: 5,
                  borderRadius: "1rem",
                  overflow: "hidden",
                }}
              >
                {video && (
                  <Box sx={{ borderRadius: "1rem", overflow: "hidden" }}>
                    <VideoComponent {...video} />
                  </Box>
                )}
              </Box>
            </Grid>

            <Grid
              item
              xs={12}
              sm={6}
              md={5}
              sx={{ display: "flex", alignItems: "center" }}
            >
              <Stack spacing={2}>
                {icon?.svg && (
                  <Box sx={{ svg: { width: "60px", height: "60px" } }}>
                    {parse(icon.svg)}
                  </Box>
                )}

                {title && <Headline {...title} />}

                {text && (
                  <Reveal delay={0.5}>
                    <Typography variant="h3" sx={{ pr: { sm: 1 } }}>
                      {parse(text)}
                    </Typography>
                  </Reveal>
                )}
              </Stack>
            </Grid>
          </Grid>
        )}
      </Container>
    </Box>
  )
}

export default TextVideo
