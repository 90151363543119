import imageConfig from "../../../submodules/components/image/config"
import flexWrapperConfig from "../../../submodules/components/flexWrapper/config"
import buttonConfig from "../../../submodules/components/button/config"
import headlineConfig from "../../../submodules/components/headline/config"

const config = {
  id: "cards",
  label: "Cards",
  fields: [
    ...headlineConfig,
    {
      id: "items",
      type: "repeater",
      label: "Items",
      items: [
        ...imageConfig,
        {
          id: "cardtitle",
          type: "text",
          label: "Title",
        },
        {
          id: "text",
          type: "wysiwyg",
          label: "Text",
        },
        ...buttonConfig,
      ],
    },
    ...flexWrapperConfig,
  ],
}

export default config
