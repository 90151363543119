import React from "react"
import CssBaseline from "@mui/material/CssBaseline"
import { Theme as ThemeProvider, colors } from "@parallel-domain/pd-theme"
import { Global, css } from "@emotion/react"

import "./slick.css"

type Props = {
  children: React.ReactNode | React.ReactNode[]
}

const Theme = (props: Props) => {
  const { children } = props

  return (
    <ThemeProvider
      themeOverrides={{
        zIndex: {
          mobileStepper: 100,
          fab: 105,
          speedDial: 105,
          appBar: 110,
          drawer: 120,
          modal: 1300,
          snackbar: 140,
          tooltip: 150,
        },
      }}
    >
      <CssBaseline />

      <Global
        styles={css`
          body {
            background: ${colors.secondary[900]};
          }
        `}
      />

      {children}
    </ThemeProvider>
  )
}

export default Theme
