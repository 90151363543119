import flexWrapperConfig from "../../../submodules/components/flexWrapper/config"

const config = {
  id: "wordcloud",
  label: "Word Cloud",
  fields: [
    {
      id: "items",
      type: "repeater",
      label: "Items",
      items: [
        {
          id: "word",
          type: "text",
          label: "Word",
        },
      ],
    },
    ...flexWrapperConfig,
  ],
}

export default config
