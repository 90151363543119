"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _axios = _interopRequireDefault(require("axios"));
var _htmlReactParser = _interopRequireDefault(require("html-react-parser"));
var _lodash = require("lodash");
var _auth = require("../../utils/auth");
var _utils = require("../../utils");
// import app components

const db = async function db(endpoint, args, source) {
  let contentType = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : 'x-www-form-urlencoded';
  if (!(0, _utils.validateAccess)()) {
    (0, _auth.logout)({});
    return;
  }
  const user = (0, _auth.getUser)();
  try {
    const formData = new FormData();
    Object.keys(args).map(key => {
      if (typeof args[key] !== 'undefined') {
        // Transform objects to string
        const value = (0, _lodash.isObject)(args[key]) && contentType === 'x-www-form-urlencoded' ? JSON.stringify(args[key]) : args[key];
        formData.append(key, value);
      }
    });

    // Create a new CancelToken source for this request
    const cancelTokenSource = _axios.default.CancelToken.source();

    // TODO: Move to utils function
    const cleanedUrl = source.replace(/\/+$/, '');

    // TODO: Make variable to allow for different CMS source
    const result = await _axios.default.post("".concat(cleanedUrl, "/wp-json/jamcms/v1/").concat(endpoint), formData, {
      cancelToken: cancelTokenSource.token,
      headers: {
        'Content-Type': contentType,
        Authorization: "Bearer ".concat(user.authToken)
      }
    });

    // Cancel request
    cancelTokenSource.cancel();
    if (result !== null && result !== void 0 && result.data) {
      return result.data;
    }
  } catch (err) {
    var _err$response, _err$response$data;
    if (err !== null && err !== void 0 && (_err$response = err.response) !== null && _err$response !== void 0 && (_err$response$data = _err$response.data) !== null && _err$response$data !== void 0 && _err$response$data.message) {
      console.log((0, _htmlReactParser.default)(err.response.data.message));
    }
  }
  return null;
};
var _default = db;
exports.default = _default;