"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const chip = {
    MuiChip: {
        styleOverrides: {
            root: {
                borderRadius: "8px",
            },
        },
    },
};
exports.default = chip;
