"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const updateGetParameter = (key, value, method = "replace") => {
    if (typeof window !== "undefined") {
        const searchParams = new URLSearchParams(window.location.search);
        if (value !== undefined && value !== null) {
            searchParams.set(key, value);
        }
        else {
            searchParams.delete(key);
        }
        const newUrl = window.location.protocol + "//" + window.location.host + window.location.pathname + "?" + searchParams.toString();
        if (method === "refresh") {
            window.location.href = newUrl;
        }
        else if (method === "push") {
            window.history.pushState({ path: newUrl }, "", newUrl);
        }
        else {
            window.history.replaceState({ path: newUrl }, "", newUrl);
        }
    }
};
exports.default = updateGetParameter;
