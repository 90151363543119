"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const checkbox = {
    MuiCheckbox: {
        styleOverrides: {
            root: {
                "&.Mui-disabled": {
                    "& .MuiSvgIcon-root": {
                        opacity: 0.5,
                    },
                },
            },
        },
    },
};
exports.default = checkbox;
