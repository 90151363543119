import React from "react"
import { Link as GatsbyLink } from "gatsby"
import { Typography, Link, Box, Stack } from "@mui/material"
import { colors } from "@parallel-domain/pd-theme"
import { NavArrowDown } from "iconoir-react"

// import app components
import { HEADER_HEIGHT } from "../../constants"

type MenuItem = {
  key: number
  postID: number
  title: string
  url: string
  children: MenuItem[]
}

type Props = {
  items: MenuItem[]
  path: string
}

const DesktopMenu = (props: Props) => {
  const { path, items } = props

  return (
    <Stack direction="row" spacing={4}>
      {items &&
        items.map(({ key, url, title, children }) => {
          const urlWithoutTrailingSlash = url.replace(/\/$/, "")

          return (
            <Box
              key={key}
              sx={{
                position: "relative",
                display: "flex",
                alignItems: "center",
                height: `${HEADER_HEIGHT}px`,
                textTransform: "uppercase",
                cursor: "pointer",
                "&:hover": {
                  ".sub-menu": {
                    transform: "translateX(-50%) translateY(-12px) scale(1)",
                    opacity: 1,
                    pointerEvents: "all",
                  },
                },
                ".sub-menu": {
                  position: "absolute",
                  zIndex: 1200,
                  top: "100%",
                  left: "50%",
                  transform: "translateX(-50%) translateY(-12px) scale(0.8)",
                  transformOrigin: "50% 0%",
                  transition: "ease all 0.4s",
                  opacity: 0,
                  pointerEvents: "none",
                  borderRadius: "0 0 8px 8px",
                  overflow: "hidden",
                },
              }}
            >
              {children.length ? (
                <>
                  <Box sx={{ display: "flex", alignItems: "center" }}>
                    <Typography
                      component="span"
                      variant="caption"
                      sx={{ mr: 0.5 }}
                    >
                      {title}
                    </Typography>

                    <NavArrowDown style={{ fontSize: "12px" }} />
                  </Box>

                  <Box className="sub-menu">
                    <Box
                      sx={{
                        margin: "0 auto",
                        width: 0,
                        height: 0,
                        borderStyle: "solid",
                        borderWidth: "0 12px 12px 12px",
                        borderColor: `transparent transparent ${colors.secondary[700]} transparent`,
                      }}
                    />

                    <Box
                      sx={{
                        width: "220px",
                        background: colors.secondary[700],
                      }}
                    >
                      {children.map((o, i) => {
                        const urlWithoutTrailingSlash = o.url.replace(/\/$/, "")

                        return (
                          <Link
                            key={i}
                            component={GatsbyLink}
                            to={urlWithoutTrailingSlash}
                            underline="none"
                            sx={{
                              display: "flex",
                              p: 3,
                              color:
                                path === urlWithoutTrailingSlash
                                  ? colors.primary[900]
                                  : "inherit",
                              "&:hover": {
                                color: colors.primary[900],
                              },
                            }}
                          >
                            <Typography
                              component="span"
                              variant="caption"
                              color="inherit"
                            >
                              {o.title}
                            </Typography>
                          </Link>
                        )
                      })}
                    </Box>
                  </Box>
                </>
              ) : (
                <Link
                  component={GatsbyLink}
                  to={urlWithoutTrailingSlash}
                  underline="none"
                  sx={{
                    display: "flex",
                    py: 3,
                    color:
                      path === urlWithoutTrailingSlash
                        ? colors.primary[900]
                        : "inherit",
                    "&:hover": {
                      color: colors.primary[900],
                    },
                  }}
                >
                  <Typography
                    component="span"
                    variant="caption"
                    color="inherit"
                  >
                    {title}
                  </Typography>
                </Link>
              )}
            </Box>
          )
        })}
    </Stack>
  )
}

export default DesktopMenu
