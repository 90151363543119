import React, { useState } from "react"
import {
  Container,
  Grid,
  Box,
  Stack,
  Typography,
  IconButton,
} from "@mui/material"
import { colors, Textarea } from "@parallel-domain/pd-theme"
import { ArrowRightCircled, ArrowLeftCircled } from "iconoir-react"

// import app components
import { Image, ImageType } from "../../../submodules/components/image"

type Props = {
  items: {
    subline: string
    text: string
    author: string
    image: ImageType
  }[]
}

const Testimonials = (props: Props) => {
  const { items } = props

  const [activeValue, setActiveValue] = useState<number>(0)

  const renderPagination = () => {
    const renderButton = (delta: number, icon: React.ReactNode) => {
      let newValue: number

      if (activeValue + delta < 0) {
        newValue = items.length - 1
      } else if (activeValue + delta > items.length - 1) {
        newValue = 0
      } else {
        newValue = activeValue + delta
      }

      return (
        <IconButton
          onClick={() => setActiveValue(newValue)}
          sx={{
            p: 0,
            mr: 2,
            transform: "translateX(-8px)",
            svg: {
              width: "50px",
              height: "50px",
              borderRadius: "50%",
              transition: "ease all 0.2s",
              color: colors.primary[900],
              "&:hover": {
                background: `${colors.primary[900]}20`,
              },
            },
          }}
        >
          {icon}
        </IconButton>
      )
    }

    return (
      <Box sx={{ display: "flex", mt: 4 }}>
        {renderButton(-1, <ArrowLeftCircled strokeWidth={1} />)}
        {renderButton(1, <ArrowRightCircled strokeWidth={1} />)}
      </Box>
    )
  }

  return (
    <Box sx={{ py: [3, 4, 5] }}>
      <Container maxWidth="lg">
        <Grid container spacing={6}>
          <Grid item xs={12} md={5} sx={{ order: [2, 2, 1] }}>
            <Box sx={{ pt: [0, 0, 4], maxWidth: ["auto", "auto", "450px"] }}>
              {items[activeValue] && (
                <Box sx={{ minHeight: ["none", "none", "240px"] }}>
                  <Stack spacing={2}>
                    {items[activeValue].subline && (
                      <Typography variant="subtitle2">
                        {items[activeValue].subline}
                      </Typography>
                    )}

                    {items[activeValue].text && (
                      <Textarea>{items[activeValue].text}</Textarea>
                    )}

                    {items[activeValue].author && (
                      <Typography color="text.secondary">
                        – {items[activeValue].author}
                      </Typography>
                    )}
                  </Stack>
                </Box>
              )}

              {renderPagination()}
            </Box>
          </Grid>
          <Grid item xs={12} md={7} sx={{ order: [1, 1, 2] }}>
            <Grid container spacing={[1, 3]}>
              {items &&
                items.map((o, i: number) => {
                  return (
                    <Grid key={i} item xs={4}>
                      <Box
                        sx={{
                          background: "#26323D",
                          borderRadius: "10px",
                          overflow: "hidden",
                          // Safari fix for border radius
                          transform: "translateZ(0)",
                        }}
                      >
                        <Box
                          onClick={() => setActiveValue(i)}
                          sx={{
                            position: "relative",
                            height: "30vw",
                            maxHeight: "220px",
                            width: "100%",
                            opacity: activeValue === i ? 1 : 0.5,
                            cursor: "pointer",
                            transition: "ease all 0.2s",
                            "&:hover": {
                              opacity: 1,
                            },
                          }}
                        >
                          {o.image && (
                            <Image
                              {...o.image}
                              sx={{
                                height: "100%",
                                ".gatsby-image-wrapper": {
                                  height: "100%",
                                  width: "100%",
                                },
                              }}
                            />
                          )}
                        </Box>
                      </Box>
                    </Grid>
                  )
                })}

              <Grid item xs={4}>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    height: "30vw",
                    maxHeight: "220px",
                    width: "100%",
                    borderRadius: "10px",
                    overflow: "hidden",
                    background: "#26323D",
                    svg: {
                      width: ["15vw", "30vw"],
                      maxWidth: "100px",
                    },
                  }}
                >
                  <svg
                    width="100"
                    height="94"
                    viewBox="0 0 100 94"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M2.775 41.2V0.799976H40.375V32.4C40.375 48.4 38.5083 60.4 34.775 68.4C29.975 78.8 21.175 87.0667 8.375 93.2L0.375 79.6C13.7083 74.5333 20.6417 61.7333 21.175 41.2H2.775ZM62.775 41.2V0.799976H99.975V32.4C99.975 48.1333 98.1083 60.1333 94.375 68.4C89.575 78.8 80.9083 87.0667 68.375 93.2L59.575 79.6C73.975 74.2667 81.175 61.4667 81.175 41.2H62.775Z"
                      fill="#111A20"
                    />
                  </svg>
                </Box>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Container>
    </Box>
  )
}

export default Testimonials
