import React from "react"
import { Box, Grid, Typography, Container, Stack } from "@mui/material"
import { colors } from "@parallel-domain/pd-theme"
import parse from "html-react-parser"

// import app components
import { Image, ImageType } from "../../../submodules/components/image"
import Reveal from "../../../../components/reveal"

type Props = {
  items: {
    subline: string
    headline: string
    image: ImageType
  }[]
}

const CardsHorizontal = (props: Props) => {
  const { items } = props

  return (
    <Box sx={{ py: [3, 4, 5] }}>
      <Container maxWidth="lg">
        {items && (
          <Grid container spacing={2}>
            {items.map((o, index: number) => (
              <Grid key={index} item xs={12} md={6}>
                <Box
                  sx={{
                    borderRadius: "1rem",
                    overflow: "hidden",
                    // Safari fix for border radius
                    transform: "translateZ(0)",
                    background: colors.secondary[800],
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "space-between",
                    height: "100%",
                  }}
                >
                  <Box sx={{ p: 5 }}>
                    {o.subline && (
                      <Box sx={{ mb: 2 }}>
                        <Reveal background={colors.primary[900]}>
                          <Typography variant="subtitle2">
                            {parse(o.subline)}
                          </Typography>
                        </Reveal>
                      </Box>
                    )}

                    {o.headline && (
                      <Typography variant="h2">{parse(o.headline)}</Typography>
                    )}
                  </Box>

                  <Box
                    sx={{
                      position: "relative",
                      height: ["200px", "400px"],
                      width: "100%",
                    }}
                  >
                    {o.image && (
                      <Box
                        sx={{
                          position: "absolute",
                          left: 0,
                          top: 0,
                          width: "100%",
                          height: "100%",
                          "> span, .gatsby-image-wrapper": {
                            width: "100%",
                            height: "100%",
                          },
                        }}
                      >
                        <Image {...o.image} />
                      </Box>
                    )}
                  </Box>
                </Box>
              </Grid>
            ))}
          </Grid>
        )}
      </Container>
    </Box>
  )
}

export default CardsHorizontal
