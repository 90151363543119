import imageConfig from "../../../submodules/components/image/config"
import flexWrapperConfig from "../../../submodules/components/flexWrapper/config"
import headlineConfig from "../../../submodules/components/headline/config"

const config = {
  id: "teammembers",
  label: "Team Members",
  fields: [
    ...headlineConfig,
    {
      id: "items",
      type: "repeater",
      label: "Items",
      items: [
        ...imageConfig,
        {
          id: "text",
          type: "wysiwyg",
          label: "Text",
        },
      ],
    },
    ...flexWrapperConfig,
  ],
}

export default config
