"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = _default;
var _immer = _interopRequireDefault(require("immer"));
function _default(array, param) {
  let direction = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : 'DESC';
  let x;
  let y;
  if (direction.toUpperCase() === 'DESC') {
    x = 1;
    y = -1;
  } else {
    x = -1;
    y = 1;
  }
  const nextArray = (0, _immer.default)(array, draft => {
    return draft.sort((a, b) => {
      if (a[param] < b[param]) {
        return x;
      } else if (b[param] < a[param]) {
        return y;
      } else {
        return 0;
      }
    });
  });
  return nextArray;
}