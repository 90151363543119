"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.updateTerm = exports.translateTerm = exports.deleteTerm = exports.addTerm = void 0;
var _toolkit = require("@reduxjs/toolkit");
var _rest = _interopRequireDefault(require("../api/rest"));
const translateTerm = (0, _toolkit.createAsyncThunk)('term/translate', async (args, thunkAPI) => {
  const {
    cms: {
      config: {
        source
      }
    }
  } = thunkAPI.getState();
  const response = await (0, _rest.default)('translateTerm', args, source);
  return response;
});
exports.translateTerm = translateTerm;
const addTerm = (0, _toolkit.createAsyncThunk)('term/add', async (args, thunkAPI) => {
  const {
    cms: {
      config: {
        source
      }
    }
  } = thunkAPI.getState();
  const response = await (0, _rest.default)('createTerm', args, source);
  return response;
});
exports.addTerm = addTerm;
const updateTerm = (0, _toolkit.createAsyncThunk)('term/update', async (args, thunkAPI) => {
  const {
    cms: {
      config: {
        source
      }
    }
  } = thunkAPI.getState();
  const response = await (0, _rest.default)('updateTerm', args, source);
  return response;
});
exports.updateTerm = updateTerm;
const deleteTerm = (0, _toolkit.createAsyncThunk)('term/delete', async (args, thunkAPI) => {
  const {
    cms: {
      config: {
        source
      }
    }
  } = thunkAPI.getState();
  const response = await (0, _rest.default)('deleteTerm', args, source);

  // The response will be true on success, so we wanna pass down the args instead
  if (response) {
    return args;
  }
  return response;
});
exports.deleteTerm = deleteTerm;