"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const react_1 = require("react");
function useKeyPress(targetKey, active = true) {
    // State for keeping track of whether key is pressed
    const [keyPressed, setKeyPressed] = (0, react_1.useState)(false);
    // If pressed key is our target key then set to true
    const downHandler = (e) => {
        if (!active)
            return;
        if (e.code === "Space") {
            e.preventDefault();
        }
        if (e.code === targetKey) {
            setKeyPressed(true);
        }
    };
    // If released key is our target key then set to false
    const upHandler = (e) => {
        if (!active)
            return;
        if (e.code === "Space") {
            e.preventDefault();
        }
        if (e.code === targetKey) {
            setKeyPressed(false);
        }
    };
    // Add event listeners
    (0, react_1.useEffect)(() => {
        window.addEventListener("keydown", downHandler);
        window.addEventListener("keyup", upHandler);
        // Remove event listeners on cleanup
        return () => {
            window.removeEventListener("keydown", downHandler);
            window.removeEventListener("keyup", upHandler);
        };
    }, [active]); // Empty array ensures that effect is only run on mount and unmount
    return keyPressed;
}
exports.default = useKeyPress;
