"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = generateRandomString;
function generateRandomString() {
  let length = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : 12;
  var result = '';
  var characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  var charactersLength = characters.length;
  for (var i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
}