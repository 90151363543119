"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = exports.authSlice = void 0;
var _toolkit = require("@reduxjs/toolkit");
var _auth = require("../../utils/auth");
var _authActions = require("../actions/authActions");
// Get 'capabilities' information from local storage to avoid unnecessary waiting to fetch user.
// Technically, someone could manipulate those values manually, but the API call for site or post afterwards would fail anyway.
const storageUser = (0, _auth.getUser)();
const initialState = {
  user: {
    id: null,
    email: '',
    capabilities: storageUser.capabilities || [],
    roles: [],
    jwtAuthExpiration: ''
  },
  userLoaded: false
};
const authSlice = (0, _toolkit.createSlice)({
  name: 'ui',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder.addCase(_authActions.getAuthUser.fulfilled, (state, action) => {
      if (action.payload) {
        state.user = action.payload;
        state.userLoaded = true;
      }
    });
  }
});
exports.authSlice = authSlice;
var _default = authSlice.reducer;
exports.default = _default;