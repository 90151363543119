import imageConfig from "../../../submodules/components/image/config"
import flexWrapperConfig from "../../../submodules/components/flexWrapper/config"
import buttonConfig from "../../../submodules/components/button/config"

const config = {
  id: "textimage",
  label: "Text & Image",
  fields: [
    {
      id: "subline",
      type: "text",
      label: "Subline",
    },
    {
      id: "headline",
      type: "text",
      label: "Headline",
    },
    {
      id: "text",
      type: "wysiwyg",
      label: "Text",
    },
    {
      id: "buttons",
      type: "repeater",
      label: "Button",
      items: buttonConfig,
    },
    ...imageConfig,
    {
      id: "imagealignment",
      type: "select",
      label: "Image Alignment",
      defaultValue: "left",
      options: [
        {
          name: "Left",
          value: "left",
        },
        {
          name: "Right",
          value: "right",
        },
      ],
    },
    ...flexWrapperConfig,
  ],
}

export default config
