import buttonConfig from "../../../submodules/components/button/config"

const config = {
  id: "teaser",
  label: "Teaser",
  fields: [
    {
      id: "text",
      type: "text",
      label: "Text",
    },
    {
      id: "buttons",
      type: "repeater",
      label: "Button",
      items: buttonConfig[0].fields,
    },
  ],
}

export default config
