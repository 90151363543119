"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
const defaults = {
  editorOptions: {
    sidebar: {
      width: 320,
      position: 'left',
      defaultOpen: 'true',
      style: 'inline'
    }
  }
};
var _default = defaults;
exports.default = _default;