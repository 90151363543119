import React from "react"
import { Box, Grid, Typography, Container, Stack } from "@mui/material"
import { colors, Textarea } from "@parallel-domain/pd-theme"
import parse from "html-react-parser"

// import app components
import { Image } from "../../../submodules/components/image"
import { Button } from "../../../submodules/components/button"
import Reveal from "../../../../components/reveal"

const TextImage = props => {
  const { headline, subline, image, imagealignment, text, buttons } = props

  return (
    <Box sx={{ py: [3, 4, 5] }}>
      <Container maxWidth="lg">
        <Grid container spacing={6}>
          <Grid
            item
            xs={12}
            md={6}
            sx={{ order: { xs: 0, md: imagealignment === "right" ? 2 : 0 } }}
          >
            <Box>{image && <Image {...image} />}</Box>
          </Grid>

          <Grid
            item
            xs={12}
            md={6}
            sx={{ display: "flex", alignItems: "center", order: 1 }}
          >
            <Stack spacing={2}>
              {subline && (
                <Reveal background={colors.primary[700]}>
                  <Typography variant="subtitle2" color="primary">
                    {parse(subline)}
                  </Typography>
                </Reveal>
              )}

              {headline && (
                <Reveal>
                  <Typography variant="h2">{parse(headline)}</Typography>
                </Reveal>
              )}

              {text && (
                <Reveal>
                  <Textarea>{text}</Textarea>
                </Reveal>
              )}

              {buttons && (
                <Stack direction="row" spacing={2}>
                  {buttons.map((o, index: number) => (
                    <Reveal key={index} delay={0.25 + 0.25 * index}>
                      <Button {...o.button.link} variant={o.button.variant} />
                    </Reveal>
                  ))}
                </Stack>
              )}
            </Stack>
          </Grid>
        </Grid>
      </Container>
    </Box>
  )
}

export default TextImage
