"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const copyToClipboard = async (text, callback) => {
    try {
        await navigator.clipboard.writeText(text);
        if (callback) {
            callback();
        }
    }
    catch (e) {
        console.error("Copying failed");
    }
};
exports.default = copyToClipboard;
