import imageConfig from "../../../submodules/components/image/config"
import flexWrapperConfig from "../../../submodules/components/flexWrapper/config"

const config = {
  id: "testimonialswithimages",
  label: "Testimonials with Images",
  fields: [
    {
      id: "items",
      type: "repeater",
      label: "Items",
      items: [
        {
          id: "subline",
          type: "text",
          label: "Subline",
        },
        {
          id: "text",
          type: "wysiwyg",
          label: "Text",
        },
        {
          id: "author",
          type: "text",
          label: "Author",
        },
        ...imageConfig,
      ],
    },
    ...flexWrapperConfig,
  ],
}

export default config
