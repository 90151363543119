"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = getParentSlug;
function getParentSlug(posts, parentID) {
  let slug = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : '';
  let newSlug = "/".concat(slug);
  if (parentID && posts[parentID]) {
    const parentSlug = "".concat(posts[parentID].slug).concat(newSlug);
    newSlug = getParentSlug(posts, posts[parentID].parentID, parentSlug);
  }
  return newSlug;
}