import React from "react"
import { graphql } from "gatsby"

// import app components
import PageWrapper from "../../../../components/pageWrapper"
import { Archive } from "../../../../features/jobs"

const Template = props => {
  const {
    data: {
      wpPage: {
        template: { acf },
      },
    },
  } = props

  return (
    <PageWrapper {...props}>
      <Archive {...acf} />
    </PageWrapper>
  )
}

export const Query = graphql`
  query PageJobs($id: String!) {
    wpPage(id: { eq: $id }) {
      id
      databaseId
      title
      template {
        ... on WpTemplate_Jobs {
          templateName
          acf {
            banner {
              fieldGroupName
              headline
              image {
                desktop {
                  altText
                  gatsbyImage(width: 1920)
                  svg
                }
                mobile {
                  altText
                  gatsbyImage(width: 600)
                  svg
                }
              }
            }
            fieldGroupName
            headline
            text
          }
        }
      }
    }
  }
`

export default Template
