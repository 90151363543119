"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.updateLanguageSettings = exports.updateLanguage = exports.translateMass = exports.getLanguages = exports.deleteLanguage = exports.addLanguage = void 0;
var _toolkit = require("@reduxjs/toolkit");
var _rest = _interopRequireDefault(require("../api/rest"));
const getLanguages = (0, _toolkit.createAsyncThunk)('language/list', async (args, thunkAPI) => {
  const {
    cms: {
      config: {
        source
      }
    }
  } = thunkAPI.getState();
  const response = await (0, _rest.default)('getLanguages', {}, source);
  return response;
});
exports.getLanguages = getLanguages;
const addLanguage = (0, _toolkit.createAsyncThunk)('language/add', async (args, thunkAPI) => {
  const {
    cms: {
      config: {
        source
      }
    }
  } = thunkAPI.getState();
  const response = await (0, _rest.default)('addLanguage', args, source);
  return response;
});
exports.addLanguage = addLanguage;
const updateLanguage = (0, _toolkit.createAsyncThunk)('language/update', async (args, thunkAPI) => {
  const {
    cms: {
      config: {
        source
      }
    }
  } = thunkAPI.getState();
  const response = await (0, _rest.default)('updateLanguage', args, source);
  return response;
});
exports.updateLanguage = updateLanguage;
const deleteLanguage = (0, _toolkit.createAsyncThunk)('language/delete', async (args, thunkAPI) => {
  const {
    cms: {
      config: {
        source
      }
    }
  } = thunkAPI.getState();
  const response = await (0, _rest.default)('deleteLanguage', args, source);
  return response;
});
exports.deleteLanguage = deleteLanguage;
const updateLanguageSettings = (0, _toolkit.createAsyncThunk)('language/updateSettings', async (args, thunkAPI) => {
  const {
    cms: {
      config: {
        source
      }
    }
  } = thunkAPI.getState();
  const response = await (0, _rest.default)('updateLanguageSettings', args, source);
  if (response) {
    return args;
  }
});
exports.updateLanguageSettings = updateLanguageSettings;
const translateMass = (0, _toolkit.createAsyncThunk)('language/translateMass', async (args, thunkAPI) => {
  const {
    cms: {
      config: {
        source
      }
    }
  } = thunkAPI.getState();
  const response = await (0, _rest.default)('setLanguageInMass', args, source);
  if (response) {
    return args;
  }
});
exports.translateMass = translateMass;