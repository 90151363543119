import React from "react"
import { AppBar, Container } from "@mui/material"
import { colors, HEADER_HEIGHT } from "@parallel-domain/pd-theme"

// import app components
import FooterMenu from "./FooterMenu"
import Socials from "../socials"
import type { MenuItem } from "./types"
import type { SocialType } from "../socials/types"

type Props = {
  path: string
  footermenu: MenuItem[]
  socials: { items: SocialType[] }
}

const Footer = (props: Props) => {
  const { path, footermenu, socials } = props

  return (
    <AppBar
      position="static"
      color="secondary"
      sx={{
        height: `${HEADER_HEIGHT}px`,
        borderTop: `1px solid ${colors.secondary[600]}`,
        borderBottom: "none",
      }}
    >
      <Container
        maxWidth="xl"
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          flexWrap: "wrap",
          py: { xs: 2, md: 0 },
        }}
      >
        <FooterMenu path={path} items={footermenu} />
        <Socials {...socials} />
      </Container>
    </AppBar>
  )
}

export default Footer
