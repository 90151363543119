import React from "react"

// import app components
import Banner from "../../../modules/components/banner/Banner"
import PostGrid from "../../../../components/postGrid"

const Archive = props => {
  const { banner, posts, pagination } = props

  return (
    <>
      <Banner position="relative" {...banner} />
      <PostGrid posts={posts} {...pagination} />
    </>
  )
}

export default Archive
