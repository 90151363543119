import React from "react"
import { Box, Container, Stack } from "@mui/material"
import { Textarea } from "@parallel-domain/pd-theme"
import parse from "html-react-parser"

// import app components
import { Headline } from "../../../submodules/components/headline"
import Reveal from "../../../../components/reveal"

const IconTextCentered = props => {
  const { title, icon, text } = props

  return (
    <Box sx={{ py: [3, 4, 5] }}>
      <Container maxWidth="md">
        <Stack spacing={4} sx={{ alignItems: "center", textAlign: "center" }}>
          {icon?.svg && (
            <Box sx={{ svg: { width: "100px", height: "100px" } }}>
              {parse(icon.svg)}
            </Box>
          )}

          {title && <Headline {...title} />}

          {text && (
            <Reveal>
              <Textarea>{text}</Textarea>
            </Reveal>
          )}
        </Stack>
      </Container>
    </Box>
  )
}

export default IconTextCentered
