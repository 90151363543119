"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = formatFieldForEditor;
var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));
var _lodash = require("lodash");
var _immer = _interopRequireDefault(require("immer"));
function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }
function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { (0, _defineProperty2.default)(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }
// This function formats the value for the editor.

function formatFieldForEditor(_ref) {
  let {
    field
  } = _ref;
  if ((field === null || field === void 0 ? void 0 : field.type) === 'group') {
    const nextGroupField = (0, _immer.default)(field, draft => {
      // On initial load the field won't have a value (assigned in database)
      // If this is the case we use the defaultValue or null instead
      if (draft.value) {
        // If it has a value, we want to loop through each subfield and check recursively (group items can be another group or repeater or fc)
        Object.keys(draft.value).map(key => {
          const subField = draft.fields.find(p => p.id === key);
          const formattedSubField = formatFieldForEditor({
            field: _objectSpread(_objectSpread({}, subField), {}, {
              value: draft.value[key]
            })
          });
          return (0, _lodash.set)(draft, "value.".concat(key), formattedSubField.value);
        });
      } else {
        draft.fields.map(o => (0, _lodash.set)(draft, "value.".concat(o.id), o.defaultValue || null));
      }
    });
    return nextGroupField;
  }
  if ((field === null || field === void 0 ? void 0 : field.type) === 'repeater') {
    if (!field.value) {
      return _objectSpread(_objectSpread({}, field), {}, {
        value: []
      });
    }
    const nextRepeaterField = (0, _immer.default)(field, draft => {
      draft.value.map((o, i) => {
        Object.keys(o).map(key => {
          const subField = draft.items.find(p => p.id === key);
          const formattedSubField = formatFieldForEditor({
            field: _objectSpread(_objectSpread({}, subField), {}, {
              value: draft.value[i][key]
            })
          });
          return (0, _lodash.set)(draft, "value.".concat(i, ".").concat(key), formattedSubField.value);
        });
      });
    });
    return nextRepeaterField;
  }
  if ((field === null || field === void 0 ? void 0 : field.type) === 'flexible_content') {
    if (!field.value) {
      return _objectSpread(_objectSpread({}, field), {}, {
        value: []
      });
    }
    const nextFlexibleContentField = (0, _immer.default)(field, draft => {
      draft.value.map((o, i) => {
        Object.keys(o).map(key => {
          if (key !== 'id') {
            var _draft$items$find, _draft$items$find$fie;
            const subField = (_draft$items$find = draft.items.find(p => p.id === o.id)) === null || _draft$items$find === void 0 ? void 0 : (_draft$items$find$fie = _draft$items$find.fields) === null || _draft$items$find$fie === void 0 ? void 0 : _draft$items$find$fie.find(q => q.id === key);
            if (!subField) {
              return;
            }
            const formattedSubField = formatFieldForEditor({
              field: _objectSpread(_objectSpread({}, subField), {}, {
                value: draft.value[i][key]
              })
            });
            return (0, _lodash.set)(draft, "value.".concat(i, ".").concat(key), formattedSubField.value);
          }
        });
      });
    });
    return nextFlexibleContentField;
  }
  return field;
}