"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.updateMediaItem = exports.getMediaItems = exports.deleteMediaItem = exports.addMediaItem = void 0;
var _toolkit = require("@reduxjs/toolkit");
var _rest = _interopRequireDefault(require("../api/rest"));
const addMediaItem = (0, _toolkit.createAsyncThunk)('media/add', async (args, thunkAPI) => {
  const {
    cms: {
      config: {
        source
      }
    }
  } = thunkAPI.getState();
  const response = await (0, _rest.default)('createMediaItem', args, source, 'multipart/form-data');
  return response;
});
exports.addMediaItem = addMediaItem;
const updateMediaItem = (0, _toolkit.createAsyncThunk)('media/update', async (args, thunkAPI) => {
  const {
    cms: {
      config: {
        source
      }
    }
  } = thunkAPI.getState();
  const response = await (0, _rest.default)('updateMediaItem', args, source);
  return response;
});
exports.updateMediaItem = updateMediaItem;
const deleteMediaItem = (0, _toolkit.createAsyncThunk)('media/delete', async (args, thunkAPI) => {
  const {
    cms: {
      config: {
        source
      }
    }
  } = thunkAPI.getState();
  const response = await (0, _rest.default)('deleteMediaItem', args, source);
  return response;
});
exports.deleteMediaItem = deleteMediaItem;
const getMediaItems = (0, _toolkit.createAsyncThunk)('media/getAll', async (args, thunkAPI) => {
  const {
    cms: {
      config: {
        source
      }
    }
  } = thunkAPI.getState();
  const response = await (0, _rest.default)('getMediaItems', args, source);
  return response;
});
exports.getMediaItems = getMediaItems;