"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _taggedTemplateLiteral2 = _interopRequireDefault(require("@babel/runtime/helpers/taggedTemplateLiteral"));
var _styledComponents = _interopRequireDefault(require("styled-components"));
var _templateObject;
const ImageContainer = _styledComponents.default.div(_templateObject || (_templateObject = (0, _taggedTemplateLiteral2.default)(["\n  background-color: #ffffff;\n  opacity: 1;\n  background-image: repeating-linear-gradient(\n      45deg,\n      #efefef 25%,\n      transparent 25%,\n      transparent 75%,\n      #efefef 75%,\n      #efefef\n    ),\n    repeating-linear-gradient(45deg, #efefef 25%, #ffffff 25%, #ffffff 75%, #efefef 75%, #efefef);\n  background-position: 0 0, 10px 10px;\n  background-size: 20px 20px;\n  border: 1px solid #d9d9d9;\n"])));
var _default = ImageContainer;
exports.default = _default;