import React, { useState } from "react"
import axios from "axios"
import { Alert, Box, Stack, Grid } from "@mui/material"
import {
  Button,
  Input,
  Checkbox,
  Textarea,
  Paper,
  colors,
  Typography,
} from "@parallel-domain/pd-theme"
import { useForm, Controller } from "react-hook-form"

const DownloadForm = props => {
  const { pathname, terms } = props

  const [status, setStatus] = useState<"success" | "error" | "">("")
  const [loading, setLoading] = useState(false)

  const { handleSubmit, control, watch } = useForm({
    defaultValues: {
      firstName: "",
      lastName: "",
      email: "",
      company: "",
      accept: false,
    },
  })

  const handleSubmitForm = async formData => {
    try {
      setLoading(true)

      await axios.post("/.netlify/functions/requestDownloadLink", {
        formData,
        pathname,
      })

      setStatus("success")
    } catch (err) {
      setStatus("error")
    } finally {
      setLoading(false)
    }
  }

  return (
    <Box sx={{ display: "flex", alignItems: "center" }}>
      {status === "success" ? (
        <Alert severity="success" sx={{ width: "100%" }}>
          An email has been sent to {watch("email")} with further instructions.{" "}
          <br /> Check your spam folder if you haven&apos;t gotten it yet!
        </Alert>
      ) : (
        <form
          method="post"
          noValidate
          onSubmit={e => {
            handleSubmit(handleSubmitForm)(e)
          }}
          style={{ width: "100%", marginBottom: 0 }}
        >
          <Stack spacing={3}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                <Controller
                  name="firstName"
                  control={control}
                  rules={{
                    required: "Please enter your first name",
                  }}
                  render={({ field: { onChange }, fieldState: { error } }) => (
                    <Input
                      placeholder="First Name*"
                      onChange={onChange}
                      error={!!error}
                      helperText={error ? error.message : null}
                      fullWidth
                    />
                  )}
                />
              </Grid>

              <Grid item xs={12} sm={6}>
                <Controller
                  name="lastName"
                  control={control}
                  rules={{
                    required: "Please enter your last name",
                  }}
                  render={({ field: { onChange }, fieldState: { error } }) => (
                    <Input
                      placeholder="Last Name*"
                      onChange={onChange}
                      error={!!error}
                      helperText={error ? error.message : null}
                      fullWidth
                    />
                  )}
                />
              </Grid>

              <Grid item xs={12}>
                <Controller
                  name="email"
                  control={control}
                  rules={{
                    required: "Please enter your email address",
                    pattern: {
                      value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                      message: "Please enter a valid email",
                    },
                  }}
                  render={({ field: { onChange }, fieldState: { error } }) => (
                    <Input
                      placeholder="Email address*"
                      onChange={onChange}
                      error={!!error}
                      helperText={error ? error.message : null}
                      type="email"
                      fullWidth
                    />
                  )}
                />
              </Grid>

              <Grid item xs={12}>
                <Controller
                  name="company"
                  control={control}
                  rules={{
                    required: "Please enter a company name",
                  }}
                  render={({ field: { onChange }, fieldState: { error } }) => (
                    <Input
                      placeholder="Company*"
                      onChange={onChange}
                      error={!!error}
                      helperText={error ? error.message : null}
                      fullWidth
                    />
                  )}
                />
              </Grid>

              <Grid item xs={12}>
                <Controller
                  name="accept"
                  control={control}
                  rules={{
                    required: "Please accept the terms & conditions",
                  }}
                  render={({
                    field: { value, onChange },
                    fieldState: { error },
                  }) => (
                    <>
                      <Paper
                        backgroundDark={false}
                        sx={{
                          p: 3,
                          mb: 1,
                          border: 1,
                          borderColor: error
                            ? colors.error
                            : colors.border[700],
                        }}
                      >
                        <Box sx={{ height: "200px", overflow: "auto" }}>
                          <Textarea p="body2">{terms}</Textarea>
                        </Box>
                      </Paper>

                      <Checkbox
                        checked={value}
                        color="primary"
                        label="I agree to the license terms above"
                        onChange={onChange}
                      />

                      {error && (
                        <Box>
                          <Typography color="error" variant="caption">
                            Please accept the terms & conditions
                          </Typography>
                        </Box>
                      )}
                    </>
                  )}
                />
              </Grid>
            </Grid>

            {status === "error" && (
              <Alert severity="error" sx={{ background: "#d32f2f" }}>
                An error occurred while sending the information. Please try
                again later.
              </Alert>
            )}

            <Button
              type="submit"
              variant="outlined"
              color="primary"
              loading={loading}
              fullWidth
            >
              Download Dataset
            </Button>
          </Stack>
        </form>
      )}
    </Box>
  )
}

export default DownloadForm
