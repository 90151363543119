"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _react = require("react");
const useForceUpdate = () => {
  const [value, setValue] = (0, _react.useState)(0); // integer state
  return () => setValue(value => value + 1); // update the state to force render
};
var _default = useForceUpdate;
exports.default = _default;