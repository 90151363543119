"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
function createTree(data, element) {
    const nodes = { item: null, child: null };
    for (const { id, children, slug } of data) {
        if (slug == element) {
            nodes.item = { id, slug };
            break;
        }
        if (children) {
            const result = createTree(children, element);
            if (result.item) {
                nodes.item = { id, slug };
                nodes.child = result;
                break;
            }
        }
    }
    return nodes;
}
/**
 * Helper function to loop through a tree and not just give the target object back but all parent items as well
 * Based on https://javascript.tutorialink.com/how-to-find-path-from-tree-of-nodes/
 */
function searchTree(data, element) {
    const result = [];
    let tree = createTree(data, element);
    while (tree) {
        result.push(tree.item);
        tree = tree.child;
    }
    if (result.length > 1) {
        result.unshift(data[0]);
    }
    return result.filter((o) => !!o);
}
exports.default = searchTree;
