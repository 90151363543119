// import templates
import pageDefault from "./templates/postTypes/page/default/config"
import pageJobs from "./templates/postTypes/page/jobs/config"
import postDefault from "./templates/postTypes/post/default/config"
import postArchive from "./templates/postTypes/post/archive/config"
import openDatasetDefault from "./templates/postTypes/opendataset/default/config"
import openDatasetArchive from "./templates/postTypes/opendataset/archive/config"
import downloadDefault from "./templates/postTypes/download/default/config"

// import theme options
import header from "./components/header/config"
import footer from "./components/footer/config"
import socials from "./components/socials/config"

const fields = {
  postTypes: [
    {
      id: "page",
      title: "Page",
      templates: [pageDefault, pageJobs],
    },
    {
      id: "post",
      title: "Post",
      templates: [postDefault, postArchive],
    },
    {
      id: "opendataset",
      title: "Open Dataset",
      templates: [openDatasetDefault, openDatasetArchive],
      options: {
        rewrite_slug: "open-datasets",
      },
    },
    {
      id: "download",
      title: "Downloads",
      templates: [downloadDefault],
      options: {
        rewrite_slug: "downloads",
        show_in_graphql: false,
      },
    },
  ],
  taxonomies: [
    // {
    //   id: "category",
    //   title: "Category",
    //   postTypes: ["post"],
    // },
  ],
  themeOptions: [header, footer, socials],
}

export default fields
