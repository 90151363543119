import React from "react"
import { Box, Grid, Container, Stack } from "@mui/material"
import { Textarea } from "@parallel-domain/pd-theme"

// import app components
import { Image } from "../../../submodules/components/image"
import { Headline } from "../../../submodules/components/headline"
import Reveal from "../../../../components/reveal"

const Logos = props => {
  const { title, text, columnsdesktop, columnsmobile, items } = props

  return (
    <Box sx={{ py: [3, 4, 5] }}>
      <Container maxWidth="lg">
        <Stack spacing={4}>
          {title && (
            <Box sx={{ display: "flex", justifyContent: "center" }}>
              <Headline {...title} />
            </Box>
          )}

          {text && (
            <Reveal>
              <Textarea>{text}</Textarea>
            </Reveal>
          )}

          {items && (
            <Grid container sx={{ justifyContent: "center" }}>
              {items.map((item, index: number) => (
                <Grid
                  key={index}
                  item
                  xs={12 / columnsmobile}
                  md={12 / Math.ceil((columnsmobile + columnsdesktop) / 2)}
                  lg={12 / columnsdesktop}
                >
                  <Image
                    key={index}
                    {...item.image}
                    sx={{
                      p: 3,
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      ".gatsby-image-wrapper": {
                        maxWidth: "200px",
                        width: "100%",
                      },
                    }}
                  />
                </Grid>
              ))}
            </Grid>
          )}
        </Stack>
      </Container>
    </Box>
  )
}

export default Logos
