import imageConfig from "../../../submodules/components/image/config"
import videoConfig from "../../../submodules/components/video/config"
import flexWrapperConfig from "../../../submodules/components/flexWrapper/config"

const config = {
  id: "videomodal",
  label: "Video Modal",
  fields: [...imageConfig, ...videoConfig, ...flexWrapperConfig],
}

export default config
