import buttonConfig from "../../../submodules/components/button/config"
import flexWrapperConfig from "../../../submodules/components/flexWrapper/config"
import videoConfig from "../../../submodules/components/video/config"

const config = {
  id: "hero",
  label: "Hero",
  fields: [
    ...videoConfig,
    {
      id: "headline",
      type: "text",
      label: "Headline",
    },
    {
      id: "subline",
      type: "text",
      label: "Subline",
    },
    {
      id: "buttons",
      type: "repeater",
      label: "Button",
      items: buttonConfig[0].fields,
    },
    ...flexWrapperConfig,
  ],
}

export default config
