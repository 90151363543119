"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = searchTree;
function searchTree(tree, value) {
  let key = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : 'key';
  let reverse = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : false;
  const stack = [...tree];
  while (stack.length) {
    const node = stack[reverse ? 'pop' : 'shift']();
    if (node[key] === value) {
      return node;
    }
    node.children && stack.push(...node.children);
  }
  return null;
}