"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = validateAccess;
var _auth = require("./auth");
function validateAccess() {
  let checkIfTokenIsExpired = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : false;
  if (typeof window !== 'undefined') {
    const authUser = (0, _auth.getUser)();
    if (checkIfTokenIsExpired && authUser !== null && authUser !== void 0 && authUser.authToken && authUser !== null && authUser !== void 0 && authUser.jwtAuthExpiration) {
      const now = Date.now().valueOf() / 1000;
      if (authUser.jwtAuthExpiration > now) {
        return true;
      }
    } else if (authUser !== null && authUser !== void 0 && authUser.authToken) {
      return true;
    } else if ((0, _auth.getPreviewID)()) {
      return true;
    }
    (0, _auth.deleteUser)();
  }
  return false;
}