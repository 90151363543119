"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = formatSite;
var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));
var _immer = _interopRequireDefault(require("immer"));
var _lodash = require("lodash");
var _defaults = _interopRequireDefault(require("../defaults"));
function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }
function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { (0, _defineProperty2.default)(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; } // import app components
function formatSite(site) {
  if (!site) {
    return null;
  }
  const nextSite = (0, _immer.default)(site, draft => {
    var _draft$editorOptions;
    // Convert posts and then post types to object structure
    if ((0, _lodash.get)(draft, "postTypes.items")) {
      draft.postTypes.items.map((o, i) => {
        if ((0, _lodash.get)(o, "posts.items")) {
          return draft.postTypes.items[i].posts = draft.postTypes.items[i].posts.items.reduce((ac, a) => _objectSpread(_objectSpread({}, ac), {}, {
            [a.id]: a
          }), {});
        }
        return null;
      });
      draft.postTypes = draft.postTypes.items.reduce((ac, a) => _objectSpread(_objectSpread({}, ac), {}, {
        [a.id]: a
      }), {});
    }

    // Convert taxonomies to object structure
    if ((0, _lodash.get)(draft, "taxonomies.items")) {
      draft.taxonomies.items.map((o, i) => {
        if ((0, _lodash.get)(o, "terms")) {
          return draft.taxonomies.items[i].terms = draft.taxonomies.items[i].terms.reduce((ac, a) => _objectSpread(_objectSpread({}, ac), {}, {
            [a.id]: a
          }), {});
        }
        return null;
      });
      draft.taxonomies = draft.taxonomies.items.reduce((ac, a) => _objectSpread(_objectSpread({}, ac), {}, {
        [a.id]: a
      }), {});
    }

    // Merge options stored in CMS with editor options to allow for future compatibility
    // TODO: Use deep merge function
    draft.editorOptions = {
      sidebar: _objectSpread(_objectSpread({}, _defaults.default.editorOptions.sidebar), draft === null || draft === void 0 ? void 0 : (_draft$editorOptions = draft.editorOptions) === null || _draft$editorOptions === void 0 ? void 0 : _draft$editorOptions.sidebar)
    };
  });
  return nextSite;
}