import React from "react"
import JamCms from "jam-cms"
import produce from "immer"

import casing from "./casing"
import Theme from "../src/components/theme"

const preferDefault = m => (m && m.default) || m

let fields = null
let privateTemplateExists = false

try {
  fields = preferDefault(require(process.env.GATSBY_FIELDS_PATH))
} catch (e) {
  process.env.NODE_ENV === "development" && console.log(`Couldn't find fields`)
}

const nextFields = produce(fields, draft => {
  // Loop through post types and templates and add React component to fields object
  if (draft && draft.postTypes) {
    for (const postTypeIndex in draft.postTypes) {
      const postType = casing(draft.postTypes[postTypeIndex].id)

      for (const templateIndex in draft.postTypes[postTypeIndex].templates) {
        const template =
          draft.postTypes[postTypeIndex].templates[templateIndex].id

        let component = null

        // Option 1: Templates can be stored in i.e. 'src/templates/postTypes/page/default.tsx'
        try {
          component = preferDefault(
            require(`${process.env.GATSBY_TEMPLATES_PATH}/postTypes/${postType}/${template}`)
          )
        } catch (e) {
          // Don't display error message
        }

        // Option 2: Templates can be stored in i.e. 'src/templates/postTypes/page/default/default.tsx'
        if (!component) {
          try {
            component = preferDefault(
              require(`${process.env.GATSBY_TEMPLATES_PATH}/postTypes/${postType}/${template}/${template}`)
            )
          } catch (e) {
            // Now we can display an error message because both possible locations have been checked
            if (process.env.NODE_ENV === "development") {
              if (e.toString().indexOf(`Error: Cannot find module`) !== -1) {
                console.warn(
                  `Couldn't find template ${template} for post type ${postType}`
                )
              } else {
                console.warn(e)
              }
            }
          }
        }

        if (component) {
          draft.postTypes[postTypeIndex].templates[templateIndex].component =
            component
        }
      }
    }
  }

  // Loop through taxonomies and add React component to draft object
  if (draft && draft.taxonomies) {
    for (const taxonomyIndex in draft.taxonomies) {
      const taxonomy = casing(draft.taxonomies[taxonomyIndex].id)

      let component = null

      // Option 1: Taxonomies can be stored in i.e. 'src/templates/taxonomies/category/single.tsx'
      try {
        component = preferDefault(
          require(`${process.env.GATSBY_TEMPLATES_PATH}/taxonomies/${taxonomy}/single`)
        )
      } catch (e) {
        // Don't display error message
      }

      // Option 2: Taxonomies can be stored in i.e. 'src/templates/taxonomies/category/single/single.tsx'
      if (!component) {
        try {
          component = preferDefault(
            require(`${process.env.GATSBY_TEMPLATES_PATH}/taxonomies/${taxonomy}/single/single`)
          )
        } catch (e) {
          // Now we can display an error message because both possible locations have been checked
          if (process.env.NODE_ENV === "development") {
            if (e.toString().indexOf(`Error: Cannot find module`) !== -1) {
              console.warn(
                `Couldn't find single template for taxonomy ${taxonomy}`
              )
            } else {
              console.warn(e)
            }
          }
        }
      }

      if (component) {
        draft.taxonomies[taxonomyIndex].component = component
      }
    }
  }

  try {
    const privateTemplate = preferDefault(
      require(`${process.env.GATSBY_TEMPLATES_PATH}/protected/private`)
    )

    if (privateTemplate) {
      privateTemplateExists = true
    }
  } catch (e) {
    if (process.env.NODE_ENV === "development") {
      if (e.toString().indexOf(`Error: Cannot find module`) !== -1) {
        console.warn(`Couldn't find private template`)
      } else {
        console.warn(e)
      }
    }
  }
})

export default function WrapPage({ element, props }) {
  return (
    <Theme {...props}>
      <JamCms
        {...props}
        fields={nextFields}
        source={process.env.GATSBY_JAM_CMS_URL}
        settings={{ decouple: false }}
        privateTemplateExists={privateTemplateExists}
      >
        {element}
      </JamCms>
    </Theme>
  )
}
