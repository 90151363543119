import React from "react"
import { Box, Grid, Container, Stack } from "@mui/material"
import { Textarea } from "@parallel-domain/pd-theme"

// import app components
import { Image, ImageType } from "../../../submodules/components/image"
import { Headline, HeadlineType } from "../../../submodules/components/headline"
import Reveal from "../../../../components/reveal"

type Props = {
  title: HeadlineType
  items: {
    name: string
    text: string
    image: ImageType
  }[]
}

const TextImageWithOffset = (props: Props) => {
  const { title, items } = props

  return (
    <Box sx={{ py: [3, 4, 5] }}>
      <Container maxWidth="md">
        {title && (
          <Box sx={{ display: "flex", justifyContent: "center", mb: [5, 10] }}>
            <Headline {...title} />
          </Box>
        )}

        {items &&
          items.map((item, index) => {
            const { text, image } = item

            const isEven = index % 2 === 0

            return (
              <Grid
                key={index}
                container
                alignItems="center"
                justifyContent="space-between"
                spacing={[2, 4]}
                sx={{
                  transform: {
                    lg: isEven ? "translateX(-50px)" : "translateX(50px)",
                  },
                  my: [2, 4],
                }}
              >
                <Grid item xs={12} sm={6} sx={{ order: [2, 1] }}>
                  {text && (
                    <Reveal>
                      <Textarea p="body2">{text}</Textarea>
                    </Reveal>
                  )}
                </Grid>

                <Grid item xs={12} sm={5} sx={{ order: [1, 2] }}>
                  <Box sx={{ borderRadius: "1rem", overflow: "hidden" }}>
                    {image && <Image {...image} />}
                  </Box>
                </Grid>
              </Grid>
            )
          })}
      </Container>
    </Box>
  )
}

export default TextImageWithOffset
