"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.updatePost = exports.translatePost = exports.takeOverPost = exports.reorderPosts = exports.removePostLock = exports.refreshPostLock = exports.getPost = exports.emptyTrash = exports.duplicatePost = exports.deletePost = exports.addPost = void 0;
var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));
var _toolkit = require("@reduxjs/toolkit");
var _rest = _interopRequireDefault(require("../api/rest"));
function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }
function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { (0, _defineProperty2.default)(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }
const addPost = (0, _toolkit.createAsyncThunk)('post/add', async (args, thunkAPI) => {
  const {
    cms: {
      config: {
        source
      }
    }
  } = thunkAPI.getState();
  const response = await (0, _rest.default)('createPost', args, source);
  return response;
});
exports.addPost = addPost;
const getPost = (0, _toolkit.createAsyncThunk)('post/get', async (args, thunkAPI) => {
  const {
    cms: {
      config: {
        source
      }
    }
  } = thunkAPI.getState();
  const response = await (0, _rest.default)('getPost', args, source);
  return response;
});
exports.getPost = getPost;
const updatePost = (0, _toolkit.createAsyncThunk)('post/update', async (args, thunkAPI) => {
  const {
    cms: {
      config: {
        source
      }
    }
  } = thunkAPI.getState();
  const response = await (0, _rest.default)('updatePost', args, source);
  return response;
});
exports.updatePost = updatePost;
const deletePost = (0, _toolkit.createAsyncThunk)('post/delete', async (args, thunkAPI) => {
  const {
    cms: {
      config: {
        source
      }
    }
  } = thunkAPI.getState();
  const response = await (0, _rest.default)('deletePost', args, source);

  // The response will be true on success, so we wanna pass down the args instead
  if (response) {
    return args;
  }
  return response;
});
exports.deletePost = deletePost;
const emptyTrash = (0, _toolkit.createAsyncThunk)('post/emptyTrash', async (args, thunkAPI) => {
  const {
    cms: {
      config: {
        source
      }
    }
  } = thunkAPI.getState();
  const response = await (0, _rest.default)('emptyTrash', args, source);
  return _objectSpread({
    postIDs: response
  }, args);
});
exports.emptyTrash = emptyTrash;
const duplicatePost = (0, _toolkit.createAsyncThunk)('post/duplicate', async (args, thunkAPI) => {
  const {
    cms: {
      config: {
        source
      }
    }
  } = thunkAPI.getState();
  const response = await (0, _rest.default)('duplicatePost', args, source);
  return response;
});
exports.duplicatePost = duplicatePost;
const reorderPosts = (0, _toolkit.createAsyncThunk)('post/reorderPosts', async (args, thunkAPI) => {
  const {
    cms: {
      config: {
        source
      }
    }
  } = thunkAPI.getState();
  const postIDs = args.posts.reduce((ac, a) => _objectSpread(_objectSpread({}, ac), {}, {
    [a.id]: a.order
  }), {});

  // We're not gonna wait until the function is completed to give the user an instant feedback
  (0, _rest.default)('reorderPosts', {
    postIDs
  }, source);
  return args.posts;
});
exports.reorderPosts = reorderPosts;
const refreshPostLock = (0, _toolkit.createAsyncThunk)('post/refreshLock', async (args, thunkAPI) => {
  const {
    cms: {
      config: {
        source
      }
    }
  } = thunkAPI.getState();
  const response = await (0, _rest.default)('refreshPostLock', args, source);
  return response;
});
exports.refreshPostLock = refreshPostLock;
const removePostLock = (0, _toolkit.createAsyncThunk)('post/removeLock', async (args, thunkAPI) => {
  const {
    cms: {
      config: {
        source
      }
    }
  } = thunkAPI.getState();
  const response = await (0, _rest.default)('removePostLock', args, source);
  return response;
});
exports.removePostLock = removePostLock;
const takeOverPost = (0, _toolkit.createAsyncThunk)('post/takeOverPost', async (args, thunkAPI) => {
  const {
    cms: {
      config: {
        source
      }
    }
  } = thunkAPI.getState();
  const response = await (0, _rest.default)('takeOverPost', args, source);
  return response;
});
exports.takeOverPost = takeOverPost;
const translatePost = (0, _toolkit.createAsyncThunk)('post/translate', async (args, thunkAPI) => {
  const {
    cms: {
      config: {
        source
      }
    }
  } = thunkAPI.getState();
  const response = await (0, _rest.default)('translatePost', args, source);
  return response;
});
exports.translatePost = translatePost;