import React from "react"
import { Link as GatsbyLink } from "gatsby"
import { Typography, Link, Stack } from "@mui/material"
import { colors } from "@parallel-domain/pd-theme"

// import app components
import type { MenuItem } from "./types"

type Props = {
  items: MenuItem[]
  path: string
}

const FooterMenu = (props: Props) => {
  const { path, items } = props

  return (
    <Stack
      direction={["column", "column", "row"]}
      spacing={4}
      sx={{ mb: { xs: 3, md: 0 } }}
    >
      {items &&
        items.map(({ key, url, title }) => {
          return (
            <Link
              key={key}
              component={GatsbyLink}
              to={url}
              underline="none"
              sx={{
                display: "flex",
                textTransform: "uppercase",
                py: { xs: 2, md: 3 },
                px: { xs: 1, md: 2 },
                color: path === url ? colors.primary[900] : "inherit",
                "&:hover": {
                  color: colors.primary[900],
                },
              }}
            >
              <Typography component="span" variant="caption" color="inherit">
                {title}
              </Typography>
            </Link>
          )
        })}
    </Stack>
  )
}

export default FooterMenu
