"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _react = require("react");
var _useInterval = _interopRequireDefault(require("./useInterval"));
var _auth = require("../utils/auth");
var _redux = require("../redux");
// import components

const useSite = () => {
  const {
    auth: {
      userLoaded
    },
    cms: {
      siteLastFetch
    }
  } = (0, _redux.useAppSelector)(state => state);
  const dispatch = (0, _redux.useAppDispatch)();
  const previewID = (0, _auth.getPreviewID)();
  (0, _useInterval.default)(() => {
    userLoaded && Date.now() - siteLastFetch > 60000 && dispatch(_redux.siteActions.getSite());
  }, 10000);
  (0, _react.useEffect)(() => {
    !previewID && dispatch(_redux.siteActions.getSite());
  }, []);
  (0, _react.useEffect)(() => {
    previewID && dispatch(_redux.previewActions.getSitePreview({
      previewID
    }));
  }, [previewID]);
};
var _default = useSite;
exports.default = _default;