"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = deepCopyTree;
function deepCopyTree(obj) {
  if (obj) {
    return JSON.parse(JSON.stringify(obj));
  } else {
    return [];
  }
}