"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const popover = {
    MuiPopover: {
        styleOverrides: {
            paper: {
                background: "transparent",
                padding: 0,
                borderRadius: "1rem",
            },
        },
    },
};
exports.default = popover;
