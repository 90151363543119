import React from "react"
import { Link } from "@mui/material"
import { Button as ThemeButton } from "@parallel-domain/pd-theme"
import { Link as GatsbyLink } from "gatsby"
import { SxProps, Theme } from "@mui/material/styles"

export type Props = {
  url: string
  title: string
  variant: "contained" | "outlined" | "text"
  target?: string
  children: string
  size?: "small" | "medium" | "large"
  sx?: SxProps<Theme>
}

const Button = (props: Props) => {
  const { url, title, variant, target, size = "medium", sx = {} } = props

  if (!url || !title) {
    return null
  }

  const component = (
    <ThemeButton variant={variant} size={size} sx={sx}>
      {title}
    </ThemeButton>
  )

  return url.includes("http") || target === "_blank" ? (
    <a
      href={url}
      target={"_blank"}
      rel="noopener noreferrer"
      style={{ textDecoration: "none" }}
    >
      {component}
    </a>
  ) : (
    <Link component={GatsbyLink} to={url} underline="none">
      {component}
    </Link>
  )
}

export default Button
