"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = getPostID;
var _generateSlug = _interopRequireDefault(require("./generateSlug"));
function getPostID(site) {
  const pathname = window.location.pathname;
  const sanitizedPathname = pathname === '/' ? pathname : pathname.replace(/\/$/, '');
  let id = 0;
  if (site) {
    Object.values(site.postTypes).map(o => Object.values(o.posts).map(p => {
      const slug = (0, _generateSlug.default)({
        site,
        postTypeID: o.id,
        postID: p.id,
        leadingSlash: true
      });
      const isNumber = n => {
        return !isNaN(parseFloat(n)) && !isNaN(n - 0);
      };
      if (slug === sanitizedPathname) {
        id = p.id;
      } else if (
      // We're assuming here that there is not conflicting page which has the format '/[blog]/page/2'
      sanitizedPathname.startsWith("".concat(slug, "/page/")) && isNumber(sanitizedPathname.substring(sanitizedPathname.lastIndexOf('/') + 1))) {
        id = p.id;
      }
    }));
  }
  return id;
}