import React from "react"
import { Box, Container } from "@mui/material"
import { Textarea } from "@parallel-domain/pd-theme"

// import app components
import { Headline } from "../../../submodules/components/headline"
import Reveal from "../../../../components/reveal"

const Text = props => {
  const { headline, text } = props

  return (
    <Box sx={{ py: [3, 4, 5] }}>
      <Container maxWidth="md">
        {headline && <Headline {...headline} />}

        {text && (
          <Reveal>
            <Textarea>{text}</Textarea>
          </Reveal>
        )}
      </Container>
    </Box>
  )
}

export default Text
