import React from "react"
import { Container } from "@mui/material"

// import app components
import FlexibleContent from "../../../submodules/components/flexibleContent"
import FlexWrapper from "../../../submodules/components/flexWrapper/FlexWrapper"
import { Form } from "../../../modules/components/form"
import PostHeaderHorizontal from "../postHeaderHorizontal"
import RelatedPosts from "../relatedPosts"

const Single = props => {
  const { title, author, date, featuredImage, flex, relatedPosts } = props

  return (
    <>
      <PostHeaderHorizontal
        title={title}
        author={author}
        date={date}
        image={featuredImage}
      />

      <Container maxWidth="md" sx={{ py: { xs: 4, sm: 8, md: 10 } }}>
        <FlexibleContent flex={flex} />
      </Container>

      <RelatedPosts posts={relatedPosts} />

      <FlexWrapper
        backgroundpattern={3}
        backgroundcolor={4}
        paddingtop={2}
        paddingbottom={2}
      >
        <Form headline="Sign up for our newsletter" form="newsletter" />
      </FlexWrapper>
    </>
  )
}

export default Single
