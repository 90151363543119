const config = [
  {
    id: "video",
    type: "group",
    label: "Video",
    fields: [
      {
        id: "host",
        type: "select",
        label: "Video Host",
        defaultValue: "vimeo",
        options: [
          {
            name: "Vimeo",
            value: "vimeo",
          },
          {
            name: "YouTube",
            value: "youtube",
          },
        ],
      },
      {
        id: "id",
        type: "text",
        label: "Video ID",
      },
    ],
  },
]

export default config
