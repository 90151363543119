"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const getParameter = (parameter, fallback = "") => {
    let tmp = [];
    let result = fallback;
    if (typeof window !== "undefined") {
        window.location.search
            .replace("?", "")
            .split("&")
            .forEach((item) => {
            tmp = item.split("=");
            if (tmp[0] === parameter) {
                result = decodeURIComponent(tmp[1]);
            }
        });
    }
    return result;
};
exports.default = getParameter;
