"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _antd = require("antd");
var _axios = _interopRequireDefault(require("axios"));
var _htmlReactParser = _interopRequireDefault(require("html-react-parser"));
var _auth = require("../../utils/auth");
// import app components

const graphql = async (query, source) => {
  try {
    // Create a new CancelToken source for this request
    const cancelTokenSource = _axios.default.CancelToken.source();

    // TODO: Move to utils function
    const cleanedUrl = source.replace(/\/+$/, '');
    const headers = {};
    const user = (0, _auth.getUser)();
    if (user !== null && user !== void 0 && user.authToken) {
      headers.Authorization = "Bearer ".concat(user.authToken);
    }
    const result = await _axios.default.post("".concat(cleanedUrl, "/graphql"), {
      query
    }, {
      headers
    });

    // Cancel request
    cancelTokenSource.cancel();
    if (result !== null && result !== void 0 && result.data) {
      return result.data;
    }
  } catch (err) {
    var _err$response, _err$response$data;
    if (err !== null && err !== void 0 && (_err$response = err.response) !== null && _err$response !== void 0 && (_err$response$data = _err$response.data) !== null && _err$response$data !== void 0 && _err$response$data.message) {
      _antd.message.error((0, _htmlReactParser.default)(err.response.data.message));
    }
  }
  return null;
};
var _default = graphql;
exports.default = _default;