import React from "react"
import { Stack, Typography, Box, Link } from "@mui/material"
import parse from "html-react-parser"
import { Link as GatsbyLink } from "gatsby"
import { Button, Paper } from "@parallel-domain/pd-theme"

const JobCard = props => {
  const { id, text, categories } = props

  return (
    <Paper
      sx={{
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        flexWrap: "wrap",
      }}
    >
      <Box sx={{ width: { xs: "100%", md: "auto" }, mb: { xs: 2, md: 0 } }}>
        <Link to={`/jobs/${id}`} component={GatsbyLink} underline="none">
          <Typography variant="h3" gutterBottom>
            {parse(text)}
          </Typography>
        </Link>

        <Stack direction={{ xs: "column", md: "row" }} spacing={1}>
          <Typography variant="subtitle1">
            {parse(categories.location)}
          </Typography>

          <Typography
            variant="subtitle1"
            sx={{ display: { xs: "none", md: "block" } }}
          >
            |
          </Typography>

          <Typography variant="subtitle1">{parse(categories.team)}</Typography>

          {categories.commitment && (
            <>
              <Typography
                variant="subtitle1"
                sx={{ display: { xs: "none", md: "block" } }}
              >
                |
              </Typography>

              <Typography variant="subtitle1">
                {parse(categories.commitment)}
              </Typography>
            </>
          )}
        </Stack>
      </Box>

      <Link to={`/jobs/${id}`} component={GatsbyLink} underline="none">
        <Button variant="outlined" color="primary">
          View Details
        </Button>
      </Link>
    </Paper>
  )
}

export default JobCard
