"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.updateSite = exports.syncFields = exports.getUnpublishedChanges = exports.getSite = exports.deploySite = void 0;
var _toolkit = require("@reduxjs/toolkit");
var _rest = _interopRequireDefault(require("../api/rest"));
var _utils = require("../../utils");
const getSite = (0, _toolkit.createAsyncThunk)('site/get', async (args, thunkAPI) => {
  const {
    cms: {
      config: {
        source
      }
    }
  } = thunkAPI.getState();
  const response = await (0, _rest.default)('getSite', {}, source);
  const formattedSite = (0, _utils.formatSite)(response);
  return formattedSite;
});
exports.getSite = getSite;
const updateSite = (0, _toolkit.createAsyncThunk)('site/update', async (args, thunkAPI) => {
  const {
    cms: {
      config: {
        source
      }
    }
  } = thunkAPI.getState();
  const response = await (0, _rest.default)('updateSite', args, source);
  const formattedSite = (0, _utils.formatSite)(response);
  return formattedSite;
});
exports.updateSite = updateSite;
const deploySite = (0, _toolkit.createAsyncThunk)('site/deploy', async (args, thunkAPI) => {
  const {
    cms: {
      config: {
        source
      }
    }
  } = thunkAPI.getState();
  const response = await (0, _rest.default)('deploySite', {}, source);
  const formattedSite = (0, _utils.formatSite)(response);
  return formattedSite;
});
exports.deploySite = deploySite;
const syncFields = (0, _toolkit.createAsyncThunk)('site/syncFields', async (args, thunkAPI) => {
  const {
    cms: {
      config: {
        source
      }
    }
  } = thunkAPI.getState();
  const response = await (0, _rest.default)('syncFields', args, source);
  const formattedSite = (0, _utils.formatSite)(response);
  return formattedSite;
});
exports.syncFields = syncFields;
const getUnpublishedChanges = (0, _toolkit.createAsyncThunk)('site/getUnpublishedChanges', async (args, thunkAPI) => {
  const {
    cms: {
      config: {
        source
      }
    }
  } = thunkAPI.getState();
  const response = await (0, _rest.default)('getUnpublishedChanges', {}, source);
  return response;
});
exports.getUnpublishedChanges = getUnpublishedChanges;