"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.signIn = exports.resetPassword = exports.refreshToken = exports.getAuthUser = exports.forgetPassword = void 0;
var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));
var _toolkit = require("@reduxjs/toolkit");
var _graphql = _interopRequireDefault(require("../api/graphql"));
var _auth = require("../../utils/auth");
function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }
function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { (0, _defineProperty2.default)(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }
const signIn = async (args, source) => {
  var _response$data;
  const query = "\n    mutation  {\n      login(\n        input: {\n          username: \"".concat(args.email, "\"\n          password: \"").concat(args.password, "\"\n          clientMutationId: \"login\"\n        }\n      ) {\n        authToken\n        refreshToken\n        user {\n          jwtAuthExpiration\n          capabilities\n        }\n      }\n    }\n  ");
  const response = await (0, _graphql.default)(query, source);
  if (response !== null && response !== void 0 && (_response$data = response.data) !== null && _response$data !== void 0 && _response$data.login) {
    const {
      data: {
        login: {
          authToken,
          refreshToken,
          user: {
            jwtAuthExpiration,
            capabilities
          }
        }
      }
    } = response;
    const formattedCapabilities = {};
    capabilities.map(s => {
      formattedCapabilities[s] = true;
    });
    return {
      authToken,
      refreshToken,
      jwtAuthExpiration,
      capabilities: formattedCapabilities
    };
  }
};
exports.signIn = signIn;
const forgetPassword = async (args, source) => {
  const query = "\n    mutation {\n      sendPasswordResetEmail(\n        input: {\n          username: \"".concat(args.email, "\"\n          clientMutationId: \"forgotPassword\"\n        }\n      ) {\n        user {\n          email\n        }\n      }\n    }\n  ");
  const response = await (0, _graphql.default)(query, source);
  return response === null || response === void 0 ? void 0 : response.data;
};
exports.forgetPassword = forgetPassword;
const resetPassword = async (args, source) => {
  const query = "\n    mutation {\n      resetUserPassword(\n        input: {\n          key: \"".concat(args.key, "\"\n          login: \"").concat(args.login, "\"\n          password: \"").concat(args.password, "\"\n          clientMutationId: \"changePassword\"\n        }\n      ) {\n        user {\n          email\n        }\n      }\n    }\n  ");
  const response = await (0, _graphql.default)(query, source);
  return response === null || response === void 0 ? void 0 : response.data;
};
exports.resetPassword = resetPassword;
const getAuthUser = (0, _toolkit.createAsyncThunk)('auth/getUser', async (args, thunkAPI) => {
  var _response$data2;
  const {
    cms: {
      config: {
        source
      }
    }
  } = thunkAPI.getState();
  const query = "\n    query {\n      viewer {\n        id\n        email\n        capabilities\n        jwtAuthExpiration\n        roles {\n          nodes {\n            name\n          }\n        }\n      }\n    }\n  ";
  const response = await (0, _graphql.default)(query, source);
  if (!(response !== null && response !== void 0 && (_response$data2 = response.data) !== null && _response$data2 !== void 0 && _response$data2.viewer)) {
    (0, _auth.logout)({});
    return null;
  }
  const {
    data: {
      viewer: {
        id,
        email,
        capabilities,
        roles,
        jwtAuthExpiration
      }
    }
  } = response;
  const storageUser = (0, _auth.getUser)();
  (0, _auth.setUser)(_objectSpread(_objectSpread({}, storageUser), {}, {
    jwtAuthExpiration
  }));
  const formattedCapabilities = {};
  capabilities.map(s => {
    formattedCapabilities[s] = true;
  });
  return {
    id,
    email,
    capabilities: formattedCapabilities,
    roles: (roles === null || roles === void 0 ? void 0 : roles.nodes.map(o => o.name)) || [],
    jwtAuthExpiration
  };
});
exports.getAuthUser = getAuthUser;
const refreshToken = (0, _toolkit.createAsyncThunk)('auth/refreshToken', async (args, thunkAPI) => {
  var _response$data3;
  const {
    cms: {
      config: {
        source
      }
    }
  } = thunkAPI.getState();
  const storageUser = (0, _auth.getUser)();
  const query = "\n    mutation {\n      refreshJwtAuthToken(\n        input: {\n          jwtRefreshToken: \"".concat(storageUser.refreshToken, "\"\n          clientMutationId: \"refreshToken\"\n        }\n      ) {\n        authToken\n      }\n    }\n  ");
  const response = await (0, _graphql.default)(query, source);
  if (response !== null && response !== void 0 && (_response$data3 = response.data) !== null && _response$data3 !== void 0 && _response$data3.refreshJwtAuthToken) {
    const {
      data: {
        refreshJwtAuthToken: {
          authToken
        }
      }
    } = response;

    // Overwrite auth token
    (0, _auth.setUser)(_objectSpread(_objectSpread({}, storageUser), {}, {
      authToken
    }));
  } else {
    (0, _auth.logout)({});
  }
});
exports.refreshToken = refreshToken;