import React from "react"
import { graphql } from "gatsby"

// import app components
import PageWrapper from "../../../../components/pageWrapper"
import { Single } from "../../../../features/openDatasets"

const Template = props => {
  const {
    data: {
      wpOpendataset: { title, acf },
    },
    location: { pathname },
  } = props

  return (
    <PageWrapper {...props}>
      <Single title={title} pathname={pathname} {...acf} />
    </PageWrapper>
  )
}

export const Query = graphql`
  query OpenDatasetDefault($id: String!) {
    wpOpendataset(id: { eq: $id }) {
      id
      uri
      databaseId
      title
      acf {
        flex {
          ... on WpOpendataset_Acf_Flex_Headline {
            color
            fieldGroupName
            headline
            variant
          }
          ... on WpOpendataset_Acf_Flex_Code {
            fieldGroupName
            text
          }
          ... on WpOpendataset_Acf_Flex_Content {
            fieldGroupName
            text
          }
          ... on WpOpendataset_Acf_Flex_Images {
            caption
            columnsdesktop
            columnsmobile
            fieldGroupName
            images {
              desktop {
                altText
                gatsbyImage(width: 800)
                svg
              }
              mobile {
                altText
                gatsbyImage(width: 400)
                svg
              }
            }
          }
        }
        dataset {
          artifact
          downloads {
            fieldGroupName
            region
            title
            url
          }
          instructions
          fieldGroupName
          scenes
          terms
        }
      }
    }
  }
`

export default Template
