import React, { Fragment } from "react"
import { Box, Typography, Container } from "@mui/material"
import parse from "html-react-parser"

type Props = {
  items: { word: string }[]
}

const Template = (props: Props) => {
  const { items } = props

  return (
    <Box sx={{ py: [3, 4, 5] }}>
      <Container maxWidth="lg">
        {items.length > 0 && (
          <Box
            sx={{
              display: "flex",
              justify: "center",
              flexWrap: "wrap",
              justifyContent: "center",
            }}
          >
            {items
              .filter(o => !!o.word)
              .map((o, i) => {
                return (
                  <Fragment key={i}>
                    <Typography
                      variant="h3"
                      component="span"
                      sx={{
                        display: ["block", "inline-block"],
                        mx: 2,
                        my: [3, 1],
                        textAlign: "center",
                      }}
                    >
                      {parse(o.word)}
                    </Typography>
                    {i !== items.length - 1 && (
                      <Typography
                        variant="h3"
                        component="span"
                        color="text.secondary"
                        sx={{
                          display: ["none", "inline-block"],
                          mx: 1,
                          my: 1,
                        }}
                      >
                        /
                      </Typography>
                    )}
                  </Fragment>
                )
              })}
          </Box>
        )}
      </Container>
    </Box>
  )
}

export default Template
