"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = formatFieldsToProps;
var _formatFieldForEditor = _interopRequireDefault(require("./formatFieldForEditor"));
function formatFieldsToProps(_ref) {
  let {
    global,
    themeOptions,
    content,
    site,
    template
  } = _ref;
  const obj = {};

  // We'll loop through the template fields because this is the source of truth.
  // The content could be empty(initially) or the field schema has changed in the meantime.
  // However, in case there is no template (i.e. taxonomy single page), we need to loop through the themeOptions instead.
  template !== null && template !== void 0 && template.fields && !global ? template.fields.filter(o => !!o.global === false).map(o => {
    let field;

    // Then we'll grab the field information from the content and alternativly from the themeOptions array or the template itself.
    // This is necessary for initial content loading.

    if (o.global) {
      field = content[o.id] || themeOptions.find(p => p.id === o.id);
    } else {
      field = content[o.id] || o;
    }
    const formattedField = (0, _formatFieldForEditor.default)({
      field
    });
    return obj[o.id] = formattedField === null || formattedField === void 0 ? void 0 : formattedField.value;
  }) : themeOptions && themeOptions.map(o => {
    const formattedField = (0, _formatFieldForEditor.default)({
      field: (content === null || content === void 0 ? void 0 : content[o.id]) || o
    });
    return obj[o.id] = formattedField === null || formattedField === void 0 ? void 0 : formattedField.value;
  });
  return obj;
}