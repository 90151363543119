import headlineConfig from "../../../submodules/components/headline/config"
import imageConfig from "../../../submodules/components/image/config"
import flexWrapperConfig from "../../../submodules/components/flexWrapper/config"

const config = {
  id: "textimagecolumns",
  label: "Text, Image & Columns",
  fields: [
    {
      id: "subline",
      type: "text",
      label: "Subline",
    },
    {
      id: "headline",
      type: "text",
      label: "Headline",
    },
    {
      id: "textstyle",
      type: "select",
      label: "Text Style",
      defaultValue: "option1",
      options: [
        {
          name: "Option 1",
          value: "option1",
        },
        {
          name: "Option 2",
          value: "option2",
        },
      ],
    },
    ...imageConfig,
    {
      id: "columnsdesktop",
      type: "number",
      label: "Columns Desktop",
      defaultValue: 3,
    },
    {
      id: "columnsmobile",
      type: "number",
      label: "Columns Mobile",
      defaultValue: 1,
    },
    {
      id: "items",
      type: "repeater",
      label: "Items",
      items: [
        {
          id: "icon",
          type: "image",
          label: "Icon",
        },
        ...headlineConfig,
        {
          id: "text",
          type: "text",
          label: "Text",
          rows: 4,
        },
      ],
    },
    ...flexWrapperConfig,
  ],
}

export default config
