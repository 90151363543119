"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const linearProgress = {
    MuiLinearProgress: {
        styleOverrides: {
            root: {
                backgroundColor: "transparent",
            },
        },
    },
};
exports.default = linearProgress;
