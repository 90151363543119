"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = formatSlug;
var _slugify = _interopRequireDefault(require("slugify"));
function formatSlug(slug) {
  let leadingSlash = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : false;
  let trailingSlash = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : false;
  let formattedSlug = (0, _slugify.default)(slug, {
    lower: true,
    remove: /[*+~.()'"!:@]/g
  });
  formattedSlug = formattedSlug.split('/').filter(s => !!s).join('/');
  if (formattedSlug.substr(1) !== '/' && leadingSlash) {
    formattedSlug = "/".concat(formattedSlug);
  }
  if (formattedSlug.substr(-1) !== '/' && trailingSlash) {
    formattedSlug = "".concat(formattedSlug, "/");
  }
  return formattedSlug;
}