import flexWrapperConfig from "../../../submodules/components/flexWrapper/config"

const config = {
  id: "hexagon",
  label: "Hexagon",
  fields: [
    {
      id: "items",
      type: "repeater",
      label: "Items",
      min: 6,
      max: 6,
      items: [
        {
          id: "headline",
          type: "text",
          label: "Headline",
        },
        {
          id: "subline",
          type: "text",
          label: "Subline",
        },
        {
          id: "text",
          type: "wysiwyg",
          label: "Text",
        },
      ],
    },
    ...flexWrapperConfig,
  ],
}

export default config
