import React from "react"
import { Box, Container, Typography, Stack } from "@mui/material"
import parse from "html-react-parser"
import { colors } from "@parallel-domain/pd-theme"

// import app components
import Reveal from "../../../../components/reveal"
import { Button } from "../../../submodules/components/button"
import { HEADER_HEIGHT } from "../../../../constants"

const Teaser = props => {
  const { text, buttons } = props

  return (
    <Box
      sx={{
        background: colors.gradients.primary,
      }}
    >
      <Container maxWidth="lg">
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            height: `${HEADER_HEIGHT}px`,
          }}
        >
          {text && (
            <Reveal delay={0.5}>
              <Typography
                variant="h4"
                sx={{ color: colors.primaryContrast[900] }}
              >
                {parse(text)}
              </Typography>
            </Reveal>
          )}

          {buttons && (
            <Stack direction="row" spacing={2}>
              {buttons.map((o, index: number) => (
                <Button
                  key={index}
                  {...o.link}
                  variant={o.variant}
                  sx={{
                    borderColor: colors.primaryContrast[900],
                    color: colors.primaryContrast[900],
                  }}
                />
              ))}
            </Stack>
          )}
        </Box>
      </Container>
    </Box>
  )
}

export default Teaser
