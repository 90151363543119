import React from "react"
import { SxProps, Theme } from "@mui/material/styles"
import { Typography } from "@parallel-domain/pd-theme"
import { Typography as TypographyProps } from "@parallel-domain/pd-theme/types"
import parse from "html-react-parser"

// import app components
import Reveal from "../../../../components/reveal"
import { colorMap } from "../../../../constants"

export type Props = {
  variant: TypographyProps["variant"]
  color: string
  headline: string
  delay?: number
  sx?: SxProps<Theme>
}

const Headline = (props: Props) => {
  const { variant, color, delay, headline, sx = {} } = props

  if (!headline) {
    return null
  }

  return (
    <Reveal delay={delay} background={colorMap[color]}>
      <Typography
        variant={variant}
        sx={{
          color: colorMap[color] || "initial",
          ...sx,
        }}
      >
        {parse(headline)}
      </Typography>
    </Reveal>
  )
}

export default Headline
