"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
const colors = {
  primary: '#2b88fb',
  secondary: '#001529',
  secondaryContrast: '#f0f2f5',
  tertiary: '#d9e1ef',
  success: '#3BB143',
  danger: '#ff4d4f',
  warning: '#f1c40f'
};
var _default = colors;
exports.default = _default;