"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.updateUser = exports.getUsers = exports.deleteUser = exports.addUser = void 0;
var _toolkit = require("@reduxjs/toolkit");
var _rest = _interopRequireDefault(require("../api/rest"));
const addUser = (0, _toolkit.createAsyncThunk)('user/add', async (args, thunkAPI) => {
  const {
    cms: {
      config: {
        source
      }
    }
  } = thunkAPI.getState();
  const response = await (0, _rest.default)('createUser', args, source);
  return response;
});
exports.addUser = addUser;
const getUsers = (0, _toolkit.createAsyncThunk)('user/get', async (args, thunkAPI) => {
  const {
    cms: {
      config: {
        source
      }
    }
  } = thunkAPI.getState();
  const response = await (0, _rest.default)('getUsers', args, source);
  return response;
});
exports.getUsers = getUsers;
const updateUser = (0, _toolkit.createAsyncThunk)('user/update', async (args, thunkAPI) => {
  const {
    cms: {
      config: {
        source
      }
    }
  } = thunkAPI.getState();
  const response = await (0, _rest.default)('updateUser', args, source);
  return response;
});
exports.updateUser = updateUser;
const deleteUser = (0, _toolkit.createAsyncThunk)('user/delete', async (args, thunkAPI) => {
  const {
    cms: {
      config: {
        source
      }
    }
  } = thunkAPI.getState();
  const response = await (0, _rest.default)('deleteUser', args, source);
  return response;
});
exports.deleteUser = deleteUser;