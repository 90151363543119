import React, { useState, useMemo } from "react"
import {
  Container,
  Typography,
  Box,
  MenuItem,
  Stack,
  Grid,
} from "@mui/material"
import { colors, Select, Paper, Textarea } from "@parallel-domain/pd-theme"
import { useStaticQuery, graphql } from "gatsby"

// import app components
import { Banner } from "../../../../features/modules/components/banner"
import JobCard from "../jobCard"
import { HEADER_HEIGHT } from "../../../../constants"

const Archive = props => {
  const { headline, text, banner } = props

  const {
    allLever: { nodes: jobs },
  } = useStaticQuery(graphql`
    {
      allLever {
        nodes {
          id
          lever_id
          createdAt
          text
          hostedUrl
          applyUrl
          categories {
            commitment
            location
            team
          }
          description
          descriptionPlain
          lists {
            text
            content
          }
          additional
          additionalPlain
        }
      }
    }
  `)

  const { locations, teams, commitments } = useMemo(() => {
    return {
      locations: [
        ...new Set(
          jobs
            .filter(o => !!o?.categories?.location)
            .map(o => o?.categories?.location)
        ),
      ],
      teams: [
        ...new Set(
          jobs.filter(o => !!o?.categories?.team).map(o => o?.categories?.team)
        ),
      ],
      commitments: [
        ...new Set(
          jobs
            .filter(o => !!o?.categories?.commitment)
            .map(o => o?.categories?.commitment)
        ),
      ],
    }
  }, [])

  const [filter, setFilter] = useState({
    location: "all",
    team: "all",
    commitment: "all",
  })

  const handleFilter = e =>
    setFilter({ ...filter, [e.target.name]: e.target.value })

  const renderJobPostings = () => {
    const content = []

    const visibleJobs = jobs.filter(job => {
      if (
        (filter.location !== "all" &&
          filter.location !== "" &&
          job.categories.location !== filter.location) ||
        (filter.team !== "all" &&
          filter.team !== "" &&
          job.categories.team !== filter.team) ||
        (filter.commitment !== "all" &&
          filter.commitment !== "" &&
          job.categories.commitment !== filter.commitment)
      ) {
        return false
      }

      return true
    })

    teams.forEach(teamName => {
      if (visibleJobs.findIndex(o => o.categories.team === teamName) > -1) {
        content.push(
          <Box key={teamName}>
            <Typography variant="subtitle2" sx={{ mb: 2 }}>
              {teamName}
            </Typography>

            <Stack spacing={3}>
              {visibleJobs
                .filter(job => job.categories.team === teamName)
                .map(job => {
                  return <JobCard key={job.id} {...job} />
                })}
            </Stack>
          </Box>
        )
      }
    })

    if (content.length === 0) {
      return (
        <Paper
          sx={{
            height: "250px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Typography>No matches found</Typography>
        </Paper>
      )
    }

    return <Stack spacing={4}>{content}</Stack>
  }

  return (
    <Box
      sx={{
        minHeight: `calc(100vh - ${2 * HEADER_HEIGHT}px)`,
        background: colors.secondary[800],
      }}
    >
      <Banner {...banner} position="relative" />

      <Box sx={{ py: 10 }}>
        <Container maxWidth="md" sx={{ textAlign: "center", mb: 10 }}>
          {headline && (
            <Typography variant="h2" gutterBottom>
              {headline}
            </Typography>
          )}
          {text && <Textarea>{text}</Textarea>}
        </Container>

        <Container maxWidth="lg">
          <Grid container spacing={3} sx={{ mb: 5 }}>
            <Grid item xs={12} md={4}>
              <Select
                value={filter.location}
                onChange={handleFilter}
                inputProps={{
                  name: "location",
                }}
              >
                <MenuItem value="all">All Locations</MenuItem>

                {locations.map(s => (
                  <MenuItem key={s} value={s}>
                    {s}
                  </MenuItem>
                ))}
              </Select>
            </Grid>

            <Grid item xs={12} md={4}>
              <Select
                value={filter.team}
                onChange={handleFilter}
                inputProps={{
                  name: "team",
                }}
              >
                <MenuItem value="all">All Teams</MenuItem>
                {teams.map(s => (
                  <MenuItem key={s} value={s}>
                    {s}
                  </MenuItem>
                ))}
              </Select>
            </Grid>

            <Grid item xs={12} md={4}>
              <Select
                value={filter.commitment}
                onChange={handleFilter}
                inputProps={{
                  name: "commitment",
                }}
              >
                <MenuItem value="all">All Work Types</MenuItem>
                {commitments.map(s => (
                  <MenuItem key={s} value={s}>
                    {s}
                  </MenuItem>
                ))}
              </Select>
            </Grid>
          </Grid>

          {renderJobPostings()}
        </Container>
      </Box>
    </Box>
  )
}

export default Archive
