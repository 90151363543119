import imageConfig from "../../../../features/submodules/components/image/config"

const config = {
  id: "jobs",
  label: "Jobs",
  fields: [
    {
      id: "banner",
      type: "group",
      label: "Banner",
      fields: [
        ...imageConfig,
        {
          id: "headline",
          type: "text",
          label: "Headine",
        },
      ],
    },
    {
      id: "headline",
      type: "text",
      label: "Headline",
    },
    {
      id: "text",
      type: "text",
      label: "Text",
      rows: 6,
    },
  ],
}

export default config
