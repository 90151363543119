"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = useOnScreen;
var _react = require("react");
function useOnScreen(ref) {
  const [isIntersecting, setIntersecting] = (0, _react.useState)(false);
  const observer = new IntersectionObserver(_ref => {
    let [entry] = _ref;
    return setIntersecting(entry.isIntersecting);
  });
  (0, _react.useEffect)(() => {
    observer.observe(ref.current);
    // Remove the observer as soon as the component is unmounted
    return () => {
      observer.disconnect();
    };
  }, []);
  return isIntersecting;
}