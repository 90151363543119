"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = exports.ROUTE_USERS = exports.ROUTE_TAXONOMY = exports.ROUTE_SETTINGS_GENERAL = exports.ROUTE_PROFILE = exports.ROUTE_POST_TYPE = exports.ROUTE_MEDIA = exports.ROUTE_APP = void 0;
const ROUTE_APP = '/jam-cms';
exports.ROUTE_APP = ROUTE_APP;
const ROUTE_MEDIA = '/media';
exports.ROUTE_MEDIA = ROUTE_MEDIA;
const ROUTE_POST_TYPE = '/collections';
exports.ROUTE_POST_TYPE = ROUTE_POST_TYPE;
const ROUTE_SETTINGS_GENERAL = '/settings';
exports.ROUTE_SETTINGS_GENERAL = ROUTE_SETTINGS_GENERAL;
const ROUTE_TAXONOMY = '/taxonomy';
exports.ROUTE_TAXONOMY = ROUTE_TAXONOMY;
const ROUTE_PROFILE = '/profile';
exports.ROUTE_PROFILE = ROUTE_PROFILE;
const ROUTE_USERS = '/users';
exports.ROUTE_USERS = ROUTE_USERS;
const getRoute = function getRoute(route) {
  let args = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
  switch (route) {
    case 'app':
      return ROUTE_APP;
    case 'dashboard':
      return "".concat(ROUTE_APP);
    case 'profile':
      return "".concat(ROUTE_APP).concat(ROUTE_PROFILE);
    case 'users':
      return "".concat(ROUTE_APP).concat(ROUTE_USERS);
    case 'media':
      return "".concat(ROUTE_APP).concat(ROUTE_MEDIA);
    case 'collection':
      return "".concat(ROUTE_APP).concat(ROUTE_POST_TYPE, "/").concat(args === null || args === void 0 ? void 0 : args.postTypeID);
    case 'taxonomy':
      return "".concat(ROUTE_APP).concat(ROUTE_TAXONOMY, "/").concat(args === null || args === void 0 ? void 0 : args.taxonomyID);
    case 'settings-general':
      return "".concat(ROUTE_APP).concat(ROUTE_SETTINGS_GENERAL);
    default:
      return "/";
  }
};
var _default = getRoute;
exports.default = _default;