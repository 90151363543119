"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = getTemplateByPost;
function getTemplateByPost(post, fields) {
  var _post$template;
  let template;
  if (post !== null && post !== void 0 && (_post$template = post.template) !== null && _post$template !== void 0 && _post$template.includes('archive-')) {
    var _fields$postTypes, _fields$postTypes$pos, _fields$postTypes$pos2;
    // The archive id is stored in the format 'archive-[post-type]'
    const postTypeID = post.template.split('-')[1];
    template = fields === null || fields === void 0 ? void 0 : (_fields$postTypes = fields.postTypes) === null || _fields$postTypes === void 0 ? void 0 : (_fields$postTypes$pos = _fields$postTypes[postTypeID]) === null || _fields$postTypes$pos === void 0 ? void 0 : (_fields$postTypes$pos2 = _fields$postTypes$pos.templates) === null || _fields$postTypes$pos2 === void 0 ? void 0 : _fields$postTypes$pos2.archive;
  } else {
    var _fields$postTypes2, _fields$postTypes2$po, _fields$postTypes2$po2;
    template = fields === null || fields === void 0 ? void 0 : (_fields$postTypes2 = fields.postTypes) === null || _fields$postTypes2 === void 0 ? void 0 : (_fields$postTypes2$po = _fields$postTypes2[post === null || post === void 0 ? void 0 : post.postTypeID]) === null || _fields$postTypes2$po === void 0 ? void 0 : (_fields$postTypes2$po2 = _fields$postTypes2$po.templates) === null || _fields$postTypes2$po2 === void 0 ? void 0 : _fields$postTypes2$po2[post === null || post === void 0 ? void 0 : post.template];
  }
  return template;
}