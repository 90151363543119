import React, { useMemo, useState } from "react"
import { Grid, Box, Container, Typography, IconButton } from "@mui/material"
import {
  mui,
  Textarea,
  colors,
  useWindowResize,
} from "@parallel-domain/pd-theme"
import { Link as GatsbyLink } from "gatsby"
import { ArrowRightCircled, ArrowLeftCircled } from "iconoir-react"

// import app components
import Reveal from "../../../../components/reveal"
import Logo from "../../../../assets/icons/paralleldomain_icon_color.svg"

type Props = {
  items: {
    headline: string
    subline: string
    text: string
  }[]
}

const Hexagon = (props: Props) => {
  const { items } = props

  const [activeValue, setActiveValue] = useState(0)

  /**
   * Hook to detect window size changes
   */
  const { width: windowWidth } = useWindowResize()

  const { width, height, gap, triangleOverlap } = useMemo(() => {
    let width = 60

    if (windowWidth > mui.breakpoints.values.xl) {
      width = 100
    } else if (windowWidth > mui.breakpoints.values.lg) {
      width = 100
    } else if (windowWidth > mui.breakpoints.values.md) {
      width = 80
    } else if (windowWidth > 480) {
      width = 80
    }

    const height = width * 1.73333
    const gap = 10
    // The triangles need to overlap a tiny little bit to avoid visible borders
    const triangleOverlap = 0.5

    return { width, height, gap, triangleOverlap }
  }, [windowWidth])

  const renderPagination = () => {
    const renderButton = (delta: number, icon: React.ReactNode) => {
      let newValue: number

      if (activeValue + delta < 0) {
        newValue = items.length - 1
      } else if (activeValue + delta > items.length - 1) {
        newValue = 0
      } else {
        newValue = activeValue + delta
      }

      return (
        <IconButton
          onClick={() => setActiveValue(newValue)}
          sx={{
            p: 0,
            mr: 2,
            transform: "translateX(-8px)",
            svg: {
              width: "50px",
              height: "50px",
              borderRadius: "50%",
              transition: "ease all 0.2s",
              color: colors.primary[900],
              "&:hover": {
                background: `${colors.primary[900]}20`,
              },
            },
          }}
        >
          {icon}
        </IconButton>
      )
    }

    return (
      <Box sx={{ display: "flex", mt: 4 }}>
        {renderButton(
          -1,
          <ArrowLeftCircled style={{ fontSize: "1.5rem" }} strokeWidth={1} />
        )}
        {renderButton(
          1,
          <ArrowRightCircled style={{ fontSize: "1.5rem" }} strokeWidth={1} />
        )}
      </Box>
    )
  }

  return (
    <Box sx={{ py: [3, 4, 5] }}>
      <Container maxWidth="lg">
        <Grid container spacing={6}>
          <Grid item xs={12} md={6} sx={{ order: [2, 2, 1] }}>
            <Box sx={{ pt: [1, 1, 8], maxWidth: ["auto", "auto", "450px"] }}>
              {items[activeValue] && (
                <>
                  {items[activeValue].subline && (
                    <Reveal background={colors.primary[700]} sx={{ mb: 2 }}>
                      <Typography variant="subtitle2">
                        {items[activeValue].subline}
                      </Typography>
                    </Reveal>
                  )}

                  {items[activeValue].headline && (
                    <Reveal delay={0.5}>
                      <Typography variant="h3">
                        {items[activeValue].headline}
                      </Typography>
                    </Reveal>
                  )}

                  {items[activeValue].text && (
                    <Box sx={{ minHeight: ["none", "none", "160px"] }}>
                      <Textarea linkComponent={GatsbyLink}>
                        {items[activeValue].text}
                      </Textarea>
                    </Box>
                  )}
                </>
              )}

              {renderPagination()}
            </Box>
          </Grid>
          <Grid item xs={12} md={6} sx={{ order: [1, 1, 2] }}>
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                ".hex": {
                  position: "relative",
                  width,
                  height,
                  backgroundColor: "#384857",
                  "&:before, &:after": {
                    content: "''",
                    borderTop: `${height / 2}px solid transparent`,
                    borderBottom: `${height / 2}px solid transparent`,
                    position: "absolute",
                    top: 0,
                  },
                  "&:before": {
                    left: `-${width / 2 - triangleOverlap}px`,
                    borderRight: `${width / 2}px solid #384857`,
                  },
                  "&:after": {
                    right: `-${width / 2 - triangleOverlap}px`,
                    borderLeft: `${width / 2}px solid #384857`,
                  },
                  "&.active, &.outer:hover": {
                    background: "#ffac3c",
                    "&:before": {
                      borderRightColor: "#ffac3c",
                    },
                    "&:after": {
                      borderLeftColor: "#ffac3c",
                    },
                  },
                },
              }}
            >
              <Box
                sx={{
                  display: "grid",
                  gridTemplateColumns: `repeat(3, ${width}px)`,
                  gridTemplateRows: `repeat(3, ${height}px)`,
                  columnGap: `${height / 2}px`,
                }}
              >
                {[
                  {
                    gridArea: "1 / 2 / 2 / 3",
                    transform: `translateY(-${gap}px)`,
                  },
                  {
                    gridArea: "1 / 3 / 2 / 4",
                    transform: `translateY(${
                      height / 2 - gap / 2
                    }px) translateX(-${width / 4}px)`,
                  },
                  {
                    gridArea: "2 / 3 / 3 / 4",
                    transform: `translateY(${
                      height / 2 + gap / 2
                    }px) translateX(-${width / 4}px)`,
                  },
                  {
                    gridArea: "3 / 2 / 4 / 3",
                    transform: `translateY(${gap}px)`,
                  },
                  {
                    gridArea: "2 / 1 / 3 / 2",
                    transform: `translateY(${
                      height / 2 + gap / 2
                    }px) translateX(${width / 4}px)`,
                  },
                  {
                    gridArea: "1 / 1 / 2 / 2",
                    transform: `translateY(${
                      height / 2 - gap / 2
                    }px) translateX(${width / 4}px)`,
                  },
                  {
                    // Center hexagon
                    gridArea: "2 / 2 / 3 / 3",
                    backgroundColor: "transparent !important",
                    "&:before, &:after": {
                      opacity: 0,
                    },
                    ".hex": {
                      backgroundColor: "transparent !important",
                      "&:before, &:after": {
                        opacity: 0,
                      },
                    },
                  },
                ].map((style, i) => {
                  return (
                    <Box
                      key={i}
                      component="article"
                      className={`hex outer ${
                        activeValue === i ? "active" : ""
                      }`}
                      onClick={() => i !== 6 && setActiveValue(i)}
                      sx={{ cursor: "pointer", ...style }}
                    >
                      <Box
                        className="hex inner"
                        sx={{
                          // Make inner hex a little bit smaller so we can see the outline on active/hover state
                          transform: "scale(.98, .98)",
                          zIndex: 1,
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        {i === 6 ? (
                          <Logo />
                        ) : (
                          <Typography
                            variant="h5"
                            sx={{
                              position: "absolute",
                              left: `-${width / 2}px`,
                              width: `calc(100% + ${width}px)`,
                              textAlign: "center",
                              letterSpacing: 0,
                              zIndex: 2,
                              px: 3,
                              userSelect: "none",
                            }}
                          >
                            {items[i] && items[i].headline}
                          </Typography>
                        )}
                      </Box>
                    </Box>
                  )
                })}
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Container>
    </Box>
  )
}

export default Hexagon
