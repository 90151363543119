import React from "react"
import { colors } from "@parallel-domain/pd-theme"
import { Box } from "@mui/material"

// import app components
import Banner from "../../../modules/components/banner/Banner"
import Form from "../../../modules/components/form/Form"
import FlexWrapper from "../../../submodules/components/flexWrapper/FlexWrapper"
import PostGrid from "../../../../components/postGrid"

const Archive = props => {
  const { banner, newsletter, posts, pagination } = props

  return (
    <Box sx={{ background: colors.secondary[800] }}>
      <Banner {...banner} position="relative" />

      <PostGrid posts={posts} {...pagination} />

      <FlexWrapper
        backgroundpattern={3}
        backgroundcolor={4}
        paddingtop={2}
        paddingbottom={2}
      >
        <Form {...newsletter} form="newsletter" />
      </FlexWrapper>
    </Box>
  )
}

export default Archive
