"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.updateEditorSettings = exports.uiSlice = exports.showDialog = exports.hideDialog = exports.default = void 0;
var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));
var _toolkit = require("@reduxjs/toolkit");
function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }
function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { (0, _defineProperty2.default)(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }
const initialState = {
  dialog: {
    open: false,
    width: 500,
    title: '',
    component: null
  },
  editorSettings: {
    fullscreen: false
  }
};
const uiSlice = (0, _toolkit.createSlice)({
  name: 'ui',
  initialState,
  reducers: {
    showDialog: (state, action) => {
      state.dialog = action.payload;
    },
    hideDialog: state => {
      state.dialog = _objectSpread({}, initialState.dialog);
    },
    updateEditorSettings: (state, action) => {
      state.editorSettings = _objectSpread(_objectSpread({}, state.editorSettings), action.payload);
    }
  }
});
exports.uiSlice = uiSlice;
const {
  showDialog,
  hideDialog,
  updateEditorSettings
} = uiSlice.actions;
exports.updateEditorSettings = updateEditorSettings;
exports.hideDialog = hideDialog;
exports.showDialog = showDialog;
var _default = uiSlice.reducer;
exports.default = _default;