import React, { useState } from "react"
import { Box, Container, Typography, Grid, IconButton } from "@mui/material"
import { colors, Textarea } from "@parallel-domain/pd-theme"
import parse from "html-react-parser"
import { Link as GatsbyLink } from "gatsby"
import { ArrowRightCircled, ArrowLeftCircled } from "iconoir-react"

type Props = {
  headline: string
  items: { steptitle: string; text: string }[]
}

const Steps = (props: Props) => {
  const { headline, items } = props

  const [activeStep, setActiveStep] = useState<number>(0)

  const renderPagination = () => {
    const renderButton = (delta: number, icon: React.ReactNode) => {
      const disabled =
        activeStep + delta < 0 || activeStep + delta > items.length - 1

      return (
        <IconButton
          onClick={() => !disabled && setActiveStep(activeStep + delta)}
          disabled={disabled}
          sx={{
            p: 0,
            ml: [0, 0, 2],
            mr: [2, 2, 0],
            cursor: "pointer",
            svg: {
              width: "50px",
              height: "50px",
              borderRadius: "50%",
              transition: "ease all 0.2s",
              color: disabled ? colors.inputDisabled[100] : colors.primary[900],
              "&:hover": {
                background: `${colors.primary[900]}20`,
              },
            },
          }}
        >
          {icon}
        </IconButton>
      )
    }

    return (
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          position: ["relative", "relative", "absolute"],
          right: ["unset", "unset", 0],
          top: 0,
          mt: "0 !important",
        }}
      >
        {renderButton(-1, <ArrowLeftCircled strokeWidth={1} />)}
        {renderButton(1, <ArrowRightCircled strokeWidth={1} />)}
      </Box>
    )
  }

  return (
    <Box sx={{ py: [6, 8] }}>
      <Container maxWidth="lg">
        <Box sx={{ position: "relative" }}>
          {headline && (
            <Typography
              variant="h2"
              sx={{ mb: 6, span: { color: colors.text[700] } }}
            >
              {parse(headline)}
            </Typography>
          )}

          {items.length > 0 && (
            <Box
              sx={{
                position: "relative",
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                mb: 6,
                "&:before": {
                  content: "''",
                  position: "absolute",
                  left: 0,
                  top: "50%",
                  width: "100%",
                  height: "1px",
                  background: colors.text[600],
                },
              }}
            >
              {items.map((o, i) => {
                const active = i === activeStep

                return (
                  <Box
                    key={i}
                    onClick={() => setActiveStep(i)}
                    sx={{
                      position: "relative",
                      display: "flex",
                      justifyContent:
                        i === 0
                          ? "flex-start"
                          : i === items.length - 1
                          ? "flex-end"
                          : "center",
                      alignItems: "center",
                      zIndex: 1,
                      width: "32px",
                      height: "32px",
                      cursor: "pointer",
                    }}
                  >
                    {active ? (
                      <Box
                        sx={{
                          width: "100%",
                          height: "100%",
                          border: `1px solid ${colors.primary[900]}`,
                          borderRadius: "50%",
                          background: colors.secondary[800],
                        }}
                      />
                    ) : (
                      <Box
                        sx={{
                          width: "10px",
                          height: "10px",
                          borderRadius: "50%",
                          background: colors.text[600],
                          transition: "ease all 0.2s",
                          "&:hover": {
                            transform: "scale(2)",
                          },
                        }}
                      />
                    )}
                  </Box>
                )
              })}
            </Box>
          )}

          {items[activeStep] && (
            <Box sx={{ minHeight: ["400px", "300px", "250px"], mb: 1 }}>
              <Grid container spacing={6}>
                <Grid item xs={12} md={4}>
                  {items[activeStep].steptitle && (
                    <Typography variant="h3" color="primary">
                      {items[activeStep].steptitle}
                    </Typography>
                  )}
                </Grid>

                <Grid item xs={12} md={8}>
                  {items[activeStep].text && (
                    <Textarea linkComponent={GatsbyLink}>
                      {items[activeStep].text}
                    </Textarea>
                  )}
                </Grid>
              </Grid>
            </Box>
          )}

          {renderPagination()}
        </Box>
      </Container>
    </Box>
  )
}

export default Steps
