"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.useAppSelector = exports.useAppDispatch = exports.store = void 0;
var _toolkit = require("@reduxjs/toolkit");
var _reactRedux = require("react-redux");
var _uiSlice = _interopRequireDefault(require("./slices/uiSlice"));
var _authSlice = _interopRequireDefault(require("./slices/authSlice"));
var _cmsSlice = _interopRequireDefault(require("./slices/cmsSlice"));
// import reducers

const customizedMiddleware = (0, _toolkit.getDefaultMiddleware)({
  serializableCheck: false
});
const store = (0, _toolkit.configureStore)({
  reducer: {
    ui: _uiSlice.default,
    auth: _authSlice.default,
    cms: _cmsSlice.default
  },
  middleware: customizedMiddleware
});
exports.store = store;
// Use throughout your app instead of plain `useDispatch` and `useSelector`
const useAppDispatch = () => (0, _reactRedux.useDispatch)();
exports.useAppDispatch = useAppDispatch;
const useAppSelector = _reactRedux.useSelector;
exports.useAppSelector = useAppSelector;