import React, { useState } from "react"
import { Link as GatsbyLink } from "gatsby"
import { Box, Container, Typography, Link, Stack } from "@mui/material"
import {
  Button,
  colors,
  Dialog,
  Layout,
  useScrollPosition,
  useScrollDirection,
  getParameter,
} from "@parallel-domain/pd-theme"
import slugify from "slugify"

// import app components
import FlexibleContent from "../../../submodules/components/flexibleContent"
import DownloadForm from "../downloadForm"
import DownloadVerification from "../downloadVerification"
import Preview from "../preview"
import { HEADER_HEIGHT } from "../../../../constants"

const initialDialog = getParameter("key") ? "download" : null

const Single = props => {
  const { title, pathname, dataset, flex } = props

  const [dialog, setDialog] = useState<
    "downloadRequest" | "download" | "preview" | null
  >(initialDialog)

  const scrollPosition = useScrollPosition()
  const scrollDirection = useScrollDirection()

  // TODO: This should be moved to a utility function
  const offset = typeof window !== "undefined" ? window.innerHeight : 1000

  const navigation =
    flex &&
    flex
      .filter(
        ({ id, fieldGroupName }) =>
          id === "headline" ||
          (fieldGroupName &&
            fieldGroupName?.split("_").pop().toLowerCase() === "headline")
      )
      .map(({ __typename, ...row }, index) => {
        return {
          title: row.headline,
          anchor: slugify(row.headline).toLowerCase(),
        }
      })

  const handleClose = () => {
    setDialog(null)

    // Remove all Get parameters
    const newUrl = window.location.href.replace(window.location.search, "")
    window.history.replaceState({ path: newUrl }, "", newUrl)
  }

  return (
    <>
      <Container
        maxWidth="xl"
        sx={{
          px: 0,
          position: "relative",
          "&:before": {
            content: "''",
            position: "absolute",
            right: "calc(100% - 24px)",
            top: 0,
            height: "100%",
            width: "50vw",
            background: colors.secondary[850],
            opacity: { xs: 0, lg: 1 },
          },
        }}
      >
        <Layout
          sidebarLeft={
            <Box
              sx={{
                py: { xs: 6, lg: 3 },
                pr: 3,
                pl: { xs: 3, lg: 0 },
              }}
            >
              <Stack spacing={2} sx={{ mb: 6 }}>
                {navigation &&
                  navigation.map((item, i) => (
                    <Link
                      key={i}
                      to={`#${item.anchor}`}
                      underline="none"
                      component={GatsbyLink}
                    >
                      <Typography variant="h5">{item.title}</Typography>
                    </Link>
                  ))}
              </Stack>

              <Stack direction="row" spacing={2}>
                {dataset?.downloads && dataset?.downloads.length > 0 && (
                  <Box>
                    <Button
                      variant="contained"
                      onClick={() => setDialog("downloadRequest")}
                    >
                      Download
                    </Button>
                  </Box>
                )}

                {dataset?.artifact && (
                  <Box>
                    <Button
                      variant="outlined"
                      onClick={() => setDialog("preview")}
                    >
                      Preview
                    </Button>
                  </Box>
                )}
              </Stack>
            </Box>
          }
          sidebarLeftSx={{
            zIndex: { xs: 1000, lg: 10 },
            transition: "ease all 0.4s",
            paddingTop:
              scrollPosition > offset ||
              (scrollPosition > 0 && scrollDirection === "up")
                ? `${HEADER_HEIGHT}px`
                : "initial",
          }}
        >
          <Container
            maxWidth="md"
            sx={{ px: 0, py: { xs: 0.5, sm: 2, md: 5 } }}
          >
            <FlexibleContent flex={flex} />
          </Container>
        </Layout>
      </Container>

      <Dialog
        open={dialog === "downloadRequest"}
        onClose={() => setDialog(null)}
        fullWidth
        maxWidth="md"
        title="Request download link"
      >
        <DownloadForm pathname={pathname} terms={dataset?.terms} />
      </Dialog>

      <Dialog
        open={dialog === "download"}
        onClose={handleClose}
        fullWidth
        maxWidth="md"
        title="Download"
      >
        <DownloadVerification title={title} />
      </Dialog>

      <Dialog
        open={dialog === "preview"}
        onClose={handleClose}
        fullScreen
        sx={{
          ".MuiDialog-paper > .MuiPaper-root, .MuiDialogContent-root": {
            background: colors.secondary[900],
            p: 0,
            overflow: "hidden",
          },
        }}
      >
        <Preview {...dataset} pathname={pathname} onClose={handleClose} />
      </Dialog>
    </>
  )
}

export default Single
