"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getSitePreview = exports.getPreviewLink = exports.getPostPreview = void 0;
var _toolkit = require("@reduxjs/toolkit");
var _rest = _interopRequireDefault(require("../api/rest"));
const getSitePreview = (0, _toolkit.createAsyncThunk)('preview/getSite', async (args, thunkAPI) => {
  const {
    cms: {
      config: {
        source
      }
    }
  } = thunkAPI.getState();
  const response = await (0, _rest.default)('getSitePreview', args, source);
  return response;
});
exports.getSitePreview = getSitePreview;
const getPostPreview = (0, _toolkit.createAsyncThunk)('preview/getPost', async (args, thunkAPI) => {
  const {
    cms: {
      config: {
        source
      }
    }
  } = thunkAPI.getState();
  const response = await (0, _rest.default)('getPostPreview', args, source);
  return response;
});
exports.getPostPreview = getPostPreview;
const getPreviewLink = (0, _toolkit.createAsyncThunk)('preview/getLink', async (args, thunkAPI) => {
  const {
    cms: {
      config: {
        source
      }
    }
  } = thunkAPI.getState();
  const response = await (0, _rest.default)('getPreviewLink', args, source);
  return response;
});
exports.getPreviewLink = getPreviewLink;