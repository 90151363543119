import React, { useEffect, useState } from "react"
import axios, { AxiosResponse } from "axios"
import { Alert, Chip, Typography, Box, CircularProgress } from "@mui/material"
import { getParameter, updateGetParameter } from "@parallel-domain/pd-theme"

const DownloadVerification = props => {
  const { title } = props

  const [status, setStatus] = useState<
    "verify" | "downloading" | "selectDownloadLink" | "error"
  >("verify")

  const [downloadLinks, setDownloadLinks] = useState<
    { title: string; url: string }[] | null
  >(null)

  const key = getParameter("key")

  const downloadFile = (uri: string, name: string) => {
    const link = document.createElement("a")
    link.download = name
    link.href = uri
    document.body.appendChild(link)
    link.click()
    document.body.removeChild(link)
  }

  useEffect(() => {
    const handleDownloadDataset = async () => {
      if (!key) return

      try {
        setStatus("verify")

        const response: AxiosResponse<{
          statusCode: number
          status: string
          data: { title: string; url: string }[]
        }> = await axios.post("/.netlify/functions/verifyDownloadKey", {
          key,
        })

        // Remove key from URL
        updateGetParameter("key", null)

        if (response?.data?.status === "success") {
          if (response.data.data.length === 1) {
            setStatus("downloading")
            downloadFile(response.data.data[0].url, title)
          } else {
            setStatus("selectDownloadLink")
            setDownloadLinks(response.data.data)
          }
        } else {
          setStatus("error")
        }
      } catch (err) {
        setStatus("error")
      }
    }

    handleDownloadDataset()
  }, [key])

  const renderMessage = () => {
    switch (status) {
      case "verify":
        return (
          <Alert severity="warning">
            <Box component="span" sx={{ display: "inline-block", mr: 2 }}>
              <CircularProgress size={20} />
            </Box>
            Verifying download...
          </Alert>
        )

      case "downloading":
        return (
          <Alert severity="success">
            Success! The dataset is downloading...
          </Alert>
        )

      case "selectDownloadLink":
        return (
          <Alert
            severity="success"
            sx={{
              justifyContent: ["center", "center", "space-between"],
              alignItems: "center",
              flexWrap: "wrap",
              ".MuiAlert-message": {
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                flex: 1,
              },
            }}
          >
            <Typography color="inherit">
              Success! Select a download link:
            </Typography>
            <Box component="span">
              {downloadLinks &&
                downloadLinks.map((o, i) => {
                  return (
                    <Chip
                      key={i}
                      onClick={() => downloadFile(o.url, title)}
                      sx={{ m: "6px !important" }}
                      label={o.title}
                      variant="outlined"
                      clickable
                      component="span"
                    />
                  )
                })}
            </Box>
          </Alert>
        )

      case "error":
        return (
          <Alert severity="error">
            Oops, something went wrong. Please try again or contact support.
          </Alert>
        )
    }
  }

  return renderMessage()
}

export default DownloadVerification
