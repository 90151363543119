import buttonConfig from "../../../submodules/components/button/config"
import imageConfig from "../../../submodules/components/image/config"
import flexWrapperConfig from "../../../submodules/components/flexWrapper/config"

const config = {
  id: "banner",
  label: "Banner",
  fields: [
    {
      id: "subline",
      type: "text",
      label: "Subline",
    },
    {
      id: "headline",
      type: "text",
      label: "Headline",
    },
    {
      id: "text",
      type: "wysiwyg",
      label: "Text",
    },
    {
      id: "buttons",
      type: "repeater",
      label: "Button",
      items: buttonConfig,
    },
    ...imageConfig,
    ...flexWrapperConfig,
  ],
}

export default config
