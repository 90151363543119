"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _taggedTemplateLiteral2 = _interopRequireDefault(require("@babel/runtime/helpers/taggedTemplateLiteral"));
var _styledComponents = _interopRequireDefault(require("styled-components"));
var _antd = require("antd");
var _templateObject;
const Caption = (0, _styledComponents.default)(_antd.Typography)(_templateObject || (_templateObject = (0, _taggedTemplateLiteral2.default)(["\n  font-size: 10px;\n  text-transform: uppercase;\n  font-weight: bold;\n  letter-spacing: 0.5px;\n"])));
var _default = Caption;
exports.default = _default;